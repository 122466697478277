import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import HtmlRenderer from '../HtmlRenderer'; // Import the HtmlRenderer component
import '.././../assets/css/componentFooter.css'


const ComputationalAndNumericalMethodsWithoutRelaxingtheConstraintsOnTheNumberofNodesInTheGraph = () => {
  // Example HTML content (can be dynamic)
  const htmlContent = `

    <!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>c2baee10-e992-4b1f-abad-3784aa8ddd29</title>
    <style type="text/css">
        * {
            margin: 0;
            padding: 0;
            text-indent: 0;
        }

        h1 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 14pt;
        }

        .p,
        p {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            margin: 0pt;
        }

        .a,
        a {
            color: #00F;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        h2 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 12pt;
        }

        .s1 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -4pt;
        }

        .s2 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
        }

        .s3 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
        }

        .s4 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        .s5 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
            vertical-align: 4pt;
        }

        .s6 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: italic;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        .s7 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -1pt;
        }

        .s8 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -6pt;
        }

        .s9 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -8pt;
        }

        .s10 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -7pt;
        }

        .s11 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -2pt;
        }

        .s12 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: 1pt;
        }

        .s13 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 7pt;
            vertical-align: 3pt;
        }

        .s14 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 7pt;
            vertical-align: -1pt;
        }

        .s15 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
            vertical-align: 1pt;
        }

        .s16 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 7pt;
        }

        .s17 {
            color: black;
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        .s18 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
            vertical-align: -1pt;
        }

        .s19 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
        }

        .s20 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
        }

        .s21 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 7pt;
            vertical-align: 3pt;
        }

        .s22 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 6.5pt;
            vertical-align: 2pt;
        }

        .s23 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 6.5pt;
            vertical-align: -1pt;
        }

        .s24 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
            vertical-align: 1pt;
        }

        .s25 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 6.5pt;
        }

        .s26 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
            vertical-align: -4pt;
        }

        .s27 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: 8pt;
        }

        .s28 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
            vertical-align: 4pt;
        }

        .s29 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
            vertical-align: 5pt;
        }

        .s30 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
        }

        .s31 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
        }

        .s32 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: 9pt;
        }

        .s33 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
        }

        .s34 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 1pt;
        }

        .s35 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -9pt;
        }

        .s36 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
            vertical-align: -5pt;
        }

        .s37 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        .s38 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 7pt;
            vertical-align: 3pt;
        }

        .s39 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 7pt;
            vertical-align: -1pt;
        }

        .s40 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 7pt;
            vertical-align: -1pt;
        }

        .s41 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: 3pt;
        }

        .s42 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
        }

        .s43 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        .s44 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
            vertical-align: -1pt;
        }

        .s45 {
            color: black;
            font-family: Cambria, serif;
            font-style: italic;
            font-weight: normal;
            text-decoration: none;
            font-size: 12.5pt;
        }

        .s46 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
            vertical-align: -3pt;
        }

        .s47 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: 6pt;
        }

        .s48 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
            vertical-align: -1pt;
        }

        .s49 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
            vertical-align: -7pt;
        }

        .s50 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
            vertical-align: 7pt;
        }

        .s51 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
        }

        .s52 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: 7pt;
        }

        .s53 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -3pt;
        }

        .s54 {
            color: black;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
            vertical-align: 4pt;
        }

        .s55 {
            color: #212121;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        .s56 {
            color: #212121;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        .s57 {
            color: #212121;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
            vertical-align: 4pt;
        }

        .s58 {
            color: #212121;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -8pt;
        }

        .s59 {
            color: #212121;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -9pt;
        }

        .s60 {
            color: #212121;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
            vertical-align: -4pt;
        }

        .s61 {
            color: #212121;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
            vertical-align: -4pt;
        }

        .s62 {
            color: #212121;
            font-family: "Cambria Math", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8.5pt;
        }

        .s63 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
            vertical-align: 4pt;
        }

        .s64 {
            color: #00F;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        .s66 {
            color: #212121;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        .s67 {
            color: #212121;
            font-family: "Times New Roman", serif;
            font-style: italic;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        li {
            display: block;
        }

        #l1 {
            padding-left: 0pt;
            counter-reset: c1 1;
        }

        #l1>li>*:first-child:before {
            counter-increment: c1;
            content: counter(c1, decimal)". ";
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 12pt;
        }

        #l1>li:first-child>*:first-child:before {
            counter-increment: c1 0;
        }

        #l2 {
            padding-left: 0pt;
            counter-reset: c2 1;
        }

        #l2>li>*:first-child:before {
            counter-increment: c2;
            content: counter(c1, decimal)"." counter(c2, decimal)" ";
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 12pt;
        }

        #l2>li:first-child>*:first-child:before {
            counter-increment: c2 0;
        }

        #l3 {
            padding-left: 0pt;
            counter-reset: c2 1;
        }

        #l3>li>*:first-child:before {
            counter-increment: c2;
            content: counter(c1, decimal)"." counter(c2, decimal)" ";
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 12pt;
        }

        #l3>li:first-child>*:first-child:before {
            counter-increment: c2 0;
        }

        li {
            display: block;
        }

        #l4 {
            padding-left: 0pt;
            counter-reset: d1 1;
        }

        #l4>li>*:first-child:before {
            counter-increment: d1;
            content: "[" counter(d1, decimal)"] ";
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        #l4>li:first-child>*:first-child:before {
            counter-increment: d1 0;
        }

        table,
        tbody {
            vertical-align: top;
            overflow: visible;
        }
    </style>
</head>

<body>
    <h1 style="padding-top: 3pt;padding-left: 10pt;text-indent: 0pt;text-align: center;">Computational and Numerical
        Methods for Combinatorial Geometric Series and its Applications</h1>
    <p style="padding-top: 15pt;padding-left: 2pt;text-indent: 0pt;text-align: center;">Chinnaraji Annamalai</p>
    <p style="padding-left: 73pt;text-indent: 0pt;text-align: center;"><a href="mailto:anna@iitkgp.ac.in"
            style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
            target="_blank">School of Management, Indian Institute of Technology, Kharagpur, India Email: </a><a
            href="mailto:anna@iitkgp.ac.in" target="_blank">anna@iitkgp.ac.in</a></p>
    <p style="padding-left: 2pt;text-indent: 0pt;text-align: center;"><a
            href="https://orcid.org/0000-0002-0992-2584">https://orcid.org/0000-0002-0992-2584</a></p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Abstract: <span class="p">The growing complexity
            of mathematical and computational modelling demands the simplicity of mathematical and computational
            equations for solving today’s scientific problems and challenges. This paper presents combinatorial
            geometric series, innovative binomial coefficients, combinatorial equations, binomial expansions, calculus
            with combinatorial geometric series, and innovative binomial theorems. Combinatorics involves integers,
            factorials, binomial coefficients, discrete mathematics, and theoretical computer science for finding
            solutions to the problems in computing and engineering science. The combinatorial geometric series with
            binomial expansions and its theorems refer to the methodological advances which are useful for researchers
            who are working in computational science. Computational science is a rapidly growing multi-and
            inter-disciplinary area where science, engineering, computation, mathematics, and collaboration use advance
            computing capabilities to understand and solve the most complex real life problems.</span></h2>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">MSC Classification codes: <span class="p">05A10,
            40A05 (65B10), 34A05, 45A05</span></h2>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Keywords: <span class="p">computation</span>,
        <span class="p">combinatorics, binomial theorem</span></h2>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <ol id="l1">
        <li data-list-text="1.">
            <h2 style="padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: justify;">Introduction</h2>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">In the earlier days, geometric series
                served as a vital role in the development of differential and integral calculus and as an introduction
                to Taylor series and Fourier series. In this article, combinatorial geometric series with binomial
                expansion and its relationship and theorems are introduced in an innovative way. Combinatorial geometric
                series is derived from the multiple summations of a geometric series with annamalai’s binomial
                coefficients. Nowadays, the combinatorial geometric series and its binomial identities and binomial
                theorems [1-17] have significant applications in science, engineering, economics, queuing theory,
                computation, combinatorics, management, and medicine [4].</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <ol id="l2">
                <li data-list-text="1.1">
                    <h2 style="padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: justify;">Geometric
                        Series with Powers of Two</h2>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: justify;">Let us develop
                        the sum of geometric series [7-11] with exponents of 2 independently as follows:</p>
                    <p class="s1" style="padding-top: 6pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">
                        2<span class="s2">𝑛 </span>= 2<span class="s2">𝑛−1 </span>+ 2<span class="s2">𝑛−1 </span>=
                        2<span class="s2">𝑛−1 </span>+ 2<span class="s2">𝑛−2 </span>+ 2<span class="s2">𝑛−2 </span>=
                        ⋯ = 2<span class="s2">𝑛−1 </span>+ 2<span class="s2">𝑛−2 </span>+ 2<span class="s2">𝑛−3
                        </span>+ ⋯ 2<span class="s2">𝑘 </span>+ 2<span class="s2">𝑘</span></p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 111pt;text-indent: 0pt;text-align: center;">𝑛
                    </p>
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">⟹
                        2<span class="s5">𝑘</span> + 2<span class="s5">𝑘+2</span> + 2<span class="s5">𝑘+3</span> + ⋯
                        + 2<span class="s5">𝑛−2</span> + 2<span class="s5">𝑛−1</span> = 2<span class="s5">𝑛</span> −
                        2<span class="s5">𝑘</span> ⟹ ∑ 2<span class="s5">𝑖</span> = 2<span class="s5">𝑛+1</span> −
                        2<span class="s5">𝑘</span>.</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 111pt;text-indent: 0pt;line-height: 7pt;text-align: center;">
                        𝑖=𝑘</p>
                    <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑛
                    </p>
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">In the
                        geometric series 𝑖𝑓 𝑘 = 0, then ∑ 2<span class="s5">𝑖</span> = 2<span class="s5">𝑛+1</span>
                        − 1, (𝑘, 𝑛 ∈ 𝑁),</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: center;">
                        𝑖=0</p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">where
                        𝑁 = {0, 1, 2, 3, ⋯ } is set of natural numbers including zero element.</p>
                </li>
                <li data-list-text="1.2">
                    <h2
                        style="padding-top: 3pt;padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: left;">
                        Traditional Binomial Coefficient</h2>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">The factorial function or factorial
                        [34, 35] of a nonnegative integer <i>n</i>, denoted by <i>n</i>!, is the product of all positive
                        integers less than or equal to <i>n</i>. For examples, 3! = 1×2× 3 = 6 and 0! = 1.</p>
                    <p class="s7" style="padding-left: 297pt;text-indent: 0pt;line-height: 10pt;text-align: left;">𝑛
                        <span class="s4">𝑛!</span></p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s9" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;"><span
                            class="s4">A binomial coefficient is always an integer that denotes (</span><span
                            class="s8">𝑟</span><span class="s4">) = </span>𝑟! <span class="s10">(</span>𝑛 − 𝑟<span
                            class="s10">)</span>! <span class="s4">, where 𝑛, 𝑟 ∈ 𝑁.</span></p>
                    <p class="s11" style="padding-left: 40pt;text-indent: 0pt;line-height: 12pt;text-align: left;">𝑛 +
                        𝑟 <span class="s4">(𝑛 + 𝑟)</span></p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 6pt;text-align: left;">Here, (
                        ) =</p>
                    <p class="s4"
                        style="padding-top: 7pt;padding-left: 3pt;text-indent: 0pt;line-height: 10pt;text-align: left;">
                        ⟹ (𝑛 + 𝑟) = 𝑙 × 𝑟! 𝑛!, where 𝑙 is an integer.</p>
                    <p class="s12" style="padding-left: 50pt;text-indent: 0pt;line-height: 13pt;text-align: left;">𝑟
                        <span class="s4">𝑟! 𝑛!</span></p>
                </li>
            </ol>
        </li>
        <li data-list-text="2.">
            <h2 style="padding-top: 11pt;padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: justify;">
                Binomial Expansions and Combinatorial Geometric Series</h2>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">When the author of this article was
                trying to develop the multiple summations of geometric series, a new idea was stimulated his mind for
                establishing a novel binomial series along with an innovative binomial coefficient [18-22].</p>
            <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑛 𝑛 𝑛 𝑛 𝑛
            </p>
            <p class="s4" style="padding-left: 237pt;text-indent: 0pt;line-height: 10pt;text-align: left;">(𝑟 + 1)(𝑟 +
                2)(𝑟 + 3) ⋯ ⋯ (𝑟 + 𝑛 − 1)(𝑟 + 𝑛)</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-left: 6pt;text-indent: 0pt;line-height: 5pt;text-align: left;">∑ ∑ ∑ ⋯ ∑
                𝑥<span class="s5">𝑖</span><span class="s13">𝑟 </span>= ∑ 𝑉<span class="s5">𝑟</span>𝑥<span
                    class="s5">𝑖</span> &amp; 𝑉<span class="s5">𝑛</span> = ,</p>
            <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span class="s14">1</span>=0
                𝑖<span class="s14">2</span>=𝑖<span class="s14">1 </span>𝑖<span class="s14">3</span>=𝑖<span
                    class="s14">2</span></p>
            <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s15" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                    class="s16">𝑟</span>=𝑖<span class="s16">𝑟−1</span></p>
            <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖 𝑟</p>
            <p class="s3" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑛!</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">where 𝑛 ≥ 1,
                𝑟 ≥ 0 𝑎𝑛𝑑 𝑛, 𝑟 ∈ 𝑁.</p>
            <p class="s3" style="padding-left: 39pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛</p>
            <p class="s4"
                style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">Here, ∑
                𝑉<span class="s5">𝑟</span>𝑥<span class="s5">𝑖</span> and 𝑉<span class="s5">𝑛</span> refer to the
                binomial sereis and binomial coefficient respectively.</p>
            <p class="s3" style="padding-left: 58pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖 𝑟</p>
            <p class="s3"
                style="padding-top: 2pt;padding-left: 35pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑥</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"><span
                    class="s17">Let us compare the binomial coefficient </span>𝑉<span class="s5">𝑦</span> <span
                    class="s17">with the traditional binomial coefficient as follows:</span></p>
            <p class="s4" style="text-indent: 0pt;line-height: 11pt;text-align: right;">𝑧</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 4pt;text-align: left;">Let 𝑧 = 𝑥 +
                𝑦. Then, ( ) = 𝑧𝐶</p>
            <p class="s4" style="text-indent: 0pt;line-height: 10pt;text-align: right;">𝑧!</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 5pt;text-align: left;">=</p>
            <p class="s4"
                style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">. Here,
                𝑉<span class="s5">𝑦</span> = 𝑉<span class="s5">𝑥</span> ⟹ 𝑧𝐶</p>
            <p class="s4"
                style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">= 𝑧𝐶
            </p>
            <p class="s4"
                style="padding-top: 6pt;padding-left: 3pt;text-indent: 0pt;line-height: 8pt;text-align: left;">, (𝑥,
                𝑦, 𝑧 ∈ 𝑁).</p>
            <p class="s1" style="padding-top: 1pt;text-indent: 0pt;line-height: 58%;text-align: right;">𝑥 <span
                    class="s2">𝑥</span></p>
            <p style="padding-left: 14pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
            <p class="s4" style="padding-left: 14pt;text-indent: 0pt;text-align: left;">𝑥! 𝑦!</p>
            <p class="s3" style="padding-left: 44pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑥 𝑦</p>
            <p class="s3" style="padding-left: 37pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑥 𝑦</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">For example,
                𝑉<span class="s5">5</span> = 𝑉<span class="s5">3</span> = (5 + 3)𝐶<span class="s18">3</span> = (5 +
                3)𝐶<span class="s18">5</span> = 56.</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: right;">3</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">Also,
                𝑉<span class="s5">0</span> = 𝑉<span class="s5">𝑛</span> = 𝑛𝐶</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: right;">5</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;line-height: 7pt;text-align: left;">= 𝑛𝐶
            </p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 23pt;text-indent: 0pt;line-height: 12pt;text-align: left;">𝑛!</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 5pt;text-align: left;">=</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">= 1 𝑎𝑛𝑑
                𝑉<span class="s5">0</span> = 0𝐶</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 17pt;text-indent: 0pt;line-height: 11pt;text-align: left;">0!</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 5pt;text-align: left;">= = 1(∵ 0! = 1).
            </p>
            <p class="s3" style="padding-left: 36pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 0</p>
            <p class="s3" style="padding-left: 30pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 𝑛</p>
            <p style="padding-left: 14pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
            <p class="s4" style="padding-left: 14pt;text-indent: 0pt;text-align: left;">𝑛! 0!</p>
            <p style="padding-left: 93pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
            <p class="s5" style="padding-left: 36pt;text-indent: 0pt;text-align: left;">0<span class="s4"> </span>0<span
                    class="s4"> 0!</span></p>
            <ol id="l3">
                <li data-list-text="2.1">
                    <h2
                        style="padding-top: 11pt;padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: left;">
                        Computation of Combinatorial Geometric Series</h2>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">The combinatorial Geometric Series
                        [18-21] is constituted by double summations of a geometric series as follows:</p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                        𝑛 𝑛 𝑛 𝑛</p>
                    <p class="s4" style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">∑ ∑
                        𝑥<span class="s5">𝑖</span><span class="s13">2 </span>= ∑ 𝑥<span class="s5">𝑖</span><span
                            class="s13">2 </span>+ ∑ 𝑥<span class="s5">𝑖</span><span class="s13">2 </span>+ ∑ 𝑥<span
                            class="s5">𝑖</span><span class="s13">2 </span>+ ⋯ + ∑ 𝑥<span class="s5">𝑖</span><span
                            class="s13">2 </span>= 1 + 2𝑥 + 3𝑥<span class="s5">2</span> + ⋯ + (𝑛 + 1)𝑥<span
                            class="s5">𝑛</span>,</p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                            class="s14">1</span>=0 𝑖<span class="s14">2</span>=𝑖<span class="s14">1</span></p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                            class="s14">2</span>=0</p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                            class="s14">2</span>=1</p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                            class="s14">2</span>=2</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 15pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖<span class="s14">2</span>=𝑛</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">that is,
                        1 + 2𝑥 + 3𝑥<span class="s5">2</span> + ⋯ + (𝑛 + 1)𝑥<span class="s5">𝑛</span> = ∑(𝑖 +
                        1)𝑥<span class="s5">𝑖</span> = ∑ 𝑉<span class="s5">1</span>𝑥<span class="s5">𝑖</span>.</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 236pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0 𝑖=0</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">The triple summations of a geometric
                        series compute the following combinatorial geometric series:</p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                        𝑛</p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                        𝑛</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">∑ ∑ ∑
                        𝑥<span class="s5">𝑖</span><span class="s13">3 </span>= ∑ ∑ 𝑥<span class="s5">𝑖</span><span
                            class="s13">3 </span>+ ∑ ∑ 𝑥<span class="s5">𝑖</span><span class="s13">3 </span>+ ∑ ∑
                        𝑥<span class="s5">𝑖</span><span class="s13">3 </span>+ ⋯ + ∑ ∑ 𝑥<span
                            class="s5">𝑖</span><span class="s13">3 </span>= ∑ 𝑉<span class="s5">2</span>𝑥<span
                            class="s5">𝑖</span>.</p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                            class="s14">1</span>=0 𝑖<span class="s14">2</span>=𝑖<span class="s14">1 </span>𝑖<span
                            class="s14">3</span>=𝑖<span class="s14">2</span></p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                            class="s14">2</span>=0 𝑖<span class="s14">3</span>=𝑖<span class="s14">2</span></p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                            class="s14">2</span>=1 𝑖<span class="s14">3</span>=𝑖<span class="s14">2</span></p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                            class="s14">2</span>=2 𝑖<span class="s14">3</span>=𝑖<span class="s14">2</span></p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖<span
                            class="s14">2</span>=𝑛 𝑖<span class="s14">3</span>=𝑖<span class="s14">2</span></p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
                    <p style="padding-top: 11pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Similarly, we can
                        obtain the combinatorial geometric series which is computed by multiple summations of a series.
                    </p>
                    <p class="s19" style="padding-left: 147pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                        𝑛 𝑛 𝑛</p>
                    <p class="s19" style="text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s20" style="padding-top: 4pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">∑
                        𝑉<span class="s21">𝑟</span>𝑥<span class="s21">𝑖</span> = ∑ ∑ ∑ ⋯ ⋯ ⋯ ∑ 𝑥<span
                            class="s21">𝑖</span><span class="s22">𝑟</span>.</p>
                    <p class="s19" style="padding-top: 4pt;text-indent: 0pt;text-align: right;">𝑖=0</p>
                    <p class="s19" style="padding-top: 4pt;padding-left: 37pt;text-indent: 0pt;text-align: left;">
                        𝑖<span class="s23">1</span>=0 𝑖<span class="s23">2</span>=𝑖<span class="s23">1 </span>𝑖<span
                            class="s23">3</span>=𝑖<span class="s23">2</span></p>
                    <p class="s24" style="padding-top: 4pt;padding-left: 33pt;text-indent: 0pt;text-align: left;">
                        𝑖<span class="s25">𝑟</span>=𝑖<span class="s25">𝑟−1</span></p>
                    <p class="s19" style="padding-top: 2pt;padding-left: 155pt;text-indent: 0pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p class="s19" style="text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s20" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Note
                        that the geometirc series ∑ 𝑥<span class="s21">𝑖</span> = ∑ 𝑉<span
                            class="s21">0</span>𝑥<span class="s21">𝑖</span> is also a combinatorial geometric sereis.
                    </p>
                    <p class="s19" style="padding-top: 4pt;padding-left: 151pt;text-indent: 0pt;text-align: left;">𝑖=0
                        𝑖=0</p>
                </li>
                <li data-list-text="2.2">
                    <h2
                        style="padding-top: 11pt;padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: left;">
                        First Derivative of Geometric Series</h2>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Differentiation is the derivative
                        [25] of a function with respect to an independent variable. In this section, a geometric series
                        is considered as the function of independent variable <i>x.</i></p>
                    <p class="s3" style="padding-left: 52pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑟
                    </p>
                    <p class="s4" style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">The
                        function of geometric sereis is 𝑓(𝑥) = ∑ 𝑥<span class="s5">𝑖</span> = 1 + 𝑥 + 𝑥<span
                            class="s5">2</span> + 𝑥<span class="s5">3</span> + ⋯ + 𝑥<span class="s5">𝑟</span> =</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 53pt;text-indent: 0pt;line-height: 9pt;text-align: center;">
                        𝑖=0</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">The first
                        derivative of geometric series is built as follows:</p>
                    <p class="s1" style="padding-top: 4pt;text-indent: 0pt;line-height: 64%;text-align: right;">𝑥<span
                            class="s2">𝑟+1 </span>− 1</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: right;">.</p>
                    <p class="s4" style="padding-left: 9pt;text-indent: 0pt;line-height: 11pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s4" style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑓<span
                            class="s5">1</span>(𝑥) = 1 + 2𝑥 + 3𝑥<span class="s5">2</span> + 4𝑥<span
                            class="s5">3</span> ⋯ + 𝑟𝑥<span class="s5">𝑟−1</span> = 𝑓<span class="s5">1</span> (</p>
                    <p class="s1" style="padding-top: 1pt;text-indent: 0pt;line-height: 64%;text-align: left;">𝑥<span
                            class="s2">𝑟+1 </span>− 1</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 44pt;text-indent: 0pt;line-height: 9pt;text-align: left;">) =</p>
                    <p class="s4" style="padding-left: 8pt;text-indent: 0pt;line-height: 11pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s4" style="padding-left: 28pt;text-indent: -26pt;line-height: 118%;text-align: left;">
                        (𝑟𝑥 − 𝑟 − 1)𝑥<span class="s5">𝑟</span> + 1 (𝑥 − 1)<span class="s5">2</span></p>
                    <p class="s4" style="padding-left: 237pt;text-indent: 0pt;line-height: 9pt;text-align: left;">(𝑟𝑥
                        − 𝑟 − 1)𝑥<span class="s5">𝑟</span> + 1</p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 5pt;text-align: left;">⟹
                        𝑉<span class="s5">1</span> + 𝑉<span class="s5">1</span>𝑥 + 𝑉<span class="s5">1</span>𝑥<span
                            class="s5">2</span> + 𝑉<span class="s5">1</span>𝑥<span class="s5">3</span> ⋯ + 𝑉<span
                            class="s5">1</span> 𝑥<span class="s5">𝑟−1</span> = , (𝑥 ≠ 1).</p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 1 2 3
                    </p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟−1
                    </p>
                    <p style="padding-left: 1pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="padding-left: 28pt;text-indent: 0pt;line-height: 14pt;text-align: left;">(𝑥 −
                        1)<span class="s5">2</span></p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;"><span
                            class="p">By substituting </span>𝑥 = 2 <span class="p">in </span>𝑓<span
                            class="s5">1</span>(𝑥)<span class="p">, we get the mathematical equation as follows:</span>
                    </p>
                    <p class="s4" style="padding-left: 26pt;text-indent: 0pt;line-height: 9pt;text-align: center;">(𝑟 −
                        1)2<span class="s5">𝑟</span> + 1</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 231pt;text-indent: -226pt;line-height: 55%;text-align: left;">
                        1 + 2(2) + 3(2)<span class="s5">2</span> + 4(2)<span class="s5">3</span> + ⋯ + 𝑟2<span
                            class="s5">𝑟−1</span> = = (𝑟 − 1)2<span class="s5">𝑟</span> + 1. (2 − 1)<span
                            class="s5">2</span></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Similarly, we get
                        the following equations by substituting the values of x:</p>
                    <p class="s4" style="padding-left: 296pt;text-indent: 0pt;line-height: 11pt;text-align: left;">(2𝑟
                        − 1)3<span class="s5">𝑟</span> + 1</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4"
                        style="padding-top: 2pt;padding-left: 313pt;text-indent: -308pt;line-height: 55%;text-align: left;">
                        For 𝑥 = 3, 1 + 2(3) + 3(3)<span class="s5">2</span> + 4(3)<span class="s5">3</span> + ⋯ +
                        𝑟3<span class="s5">𝑟−1</span> = = (3 − 1)<span class="s5">2</span></p>
                    <p class="s4"
                        style="padding-top: 12pt;padding-left: 1pt;text-indent: 0pt;line-height: 12pt;text-align: left;">
                        (2𝑟 − 1)3<span class="s5">𝑟</span> + 1</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s9" style="padding-left: 33pt;text-indent: 0pt;line-height: 78%;text-align: left;">2<span
                            class="s26">2 </span><span class="s4">.</span></p>
                    <p class="s4" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">For 𝑥 =
                        4, 1 + 2(4) + 3(4)<span class="s5">2</span> + 4(4)<span class="s5">3</span> ⋯ + 𝑟4<span
                            class="s5">𝑟−1</span> =</p>
                    <p class="s4"
                        style="padding-bottom: 2pt;padding-left: 1pt;text-indent: 0pt;line-height: 14pt;text-align: center;">
                        (3𝑟 − 1)4<span class="s5">𝑟</span> + 1</p>
                    <p style="padding-left: 1pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="padding-left: 10pt;text-indent: 0pt;line-height: 14pt;text-align: center;">(4 −
                        1)<span class="s5">2</span></p>
                    <p class="s4" style="padding-left: 13pt;text-indent: 0pt;line-height: 11pt;text-align: left;">(3𝑟 −
                        1)4<span class="s5">𝑟</span> + 1</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 1pt;text-indent: 0pt;line-height: 78%;text-align: left;">= <span
                            class="s9">3</span><span class="s26">2 </span>.</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: right;">𝑟−1</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">For any
                        number k that is equal to x, we get the equation ∑ 𝑉<span class="s5">1</span>𝑘<span
                            class="s5">𝑖</span> =</p>
                    <p class="s4"
                        style="padding-top: 3pt;padding-left: 3pt;text-indent: 0pt;line-height: 12pt;text-align: left;">
                        (𝑘𝑟 − 𝑟 − 1)𝑘<span class="s5">𝑟</span> + 1</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 30pt;text-indent: 0pt;line-height: 18pt;text-align: left;">(𝑘 −
                        1)<span class="s5">2</span> <span class="s27">.</span></p>
                    <p class="s3" style="padding-left: 128pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑖=0
                    </p>
                    <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
                </li>
                <li data-list-text="2.3">
                    <h2 style="padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: left;">Derivative of
                        Geometric Series without Differentiation</h2>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Differentiation
                        [25] is a method of finding the derivative of a function.</p>
                    <p class="s3" style="padding-left: 189pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛</p>
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">The sum
                        of the geometric series is ∑ 𝑥<span class="s5">𝑖</span> =</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 184pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s1"
                        style="padding-top: 2pt;padding-bottom: 2pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">
                        𝑥<span class="s2">𝑛+1 </span>− 1</p>
                    <p style="padding-left: 1pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="padding-left: 1pt;text-indent: 0pt;text-align: center;">𝑥 − 1</p>
                    <p class="s4" style="padding-top: 13pt;text-indent: 0pt;text-align: left;">, which is a function of
                        𝑥.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">The first
                        derivative of geometric series is computed without using differentiation as follows:</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">∑
                        𝑥<span class="s5">𝑖</span> + ∑ 𝑥<span class="s5">𝑖</span> + ∑ 𝑥<span class="s5">𝑖</span> +
                        ⋯ + ∑ 𝑥<span class="s5">𝑖</span> + ∑ 𝑥<span class="s5">𝑖</span></p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=1</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=2</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=𝑛−2</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=𝑛−1</p>
                    <p class="s4" style="padding-left: 77pt;text-indent: 0pt;line-height: 11pt;text-align: center;">
                        𝑥<span class="s5">𝑛</span> − 1</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 77pt;text-indent: 0pt;line-height: 9pt;text-align: center;">= +
                    </p>
                    <p class="s4" style="padding-left: 77pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑥 −
                        1</p>
                    <p class="s4" style="padding-bottom: 2pt;text-indent: 0pt;line-height: 14pt;text-align: left;">
                        𝑥<span class="s5">𝑛</span> − 𝑥</p>
                    <p style="text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="padding-left: 3pt;text-indent: 0pt;line-height: 11pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s4" style="padding-left: 12pt;text-indent: 0pt;line-height: 11pt;text-align: left;">
                        𝑥<span class="s5">𝑛</span> − 𝑥<span class="s5">2</span></p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">+</p>
                    <p class="s4" style="padding-left: 18pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s4" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">+ ⋯ +
                    </p>
                    <p class="s1"
                        style="padding-top: 1pt;padding-left: 10pt;text-indent: 0pt;line-height: 58%;text-align: center;">
                        𝑥<span class="s2">𝑛 </span>− 𝑥<span class="s2">𝑛−2</span></p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="padding-left: 1pt;text-indent: 0pt;line-height: 11pt;text-align: center;">𝑥 −
                        1</p>
                    <p class="s1" style="padding-top: 1pt;text-indent: 0pt;line-height: 58%;text-align: right;">𝑥<span
                            class="s2">𝑛 </span>− 𝑥<span class="s2">𝑛−1</span></p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: right;">+ .</p>
                    <p class="s4" style="padding-left: 24pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: right;">𝑛−1</p>
                    <p class="s3" style="padding-left: 24pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1
                    </p>
                    <p class="s3" style="padding-left: 24pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1
                    </p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: right;">𝑛−1</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: right;">𝑛−1</p>
                    <p class="s3" style="padding-left: 31pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1
                    </p>
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Here, ∑
                        𝑥<span class="s5">𝑖</span> + ∑ 𝑥<span class="s5">𝑖</span> + ∑ 𝑥<span class="s5">𝑖</span> +
                        ⋯ + ∑ 𝑥<span class="s5">𝑖</span> + ∑ 𝑥<span class="s5">𝑖</span> = ∑(𝑖 + 1)𝑥<span
                            class="s5">𝑖</span> and</p>
                    <p class="s3" style="padding-top: 4pt;text-indent: 0pt;line-height: 9pt;text-align: right;">𝑖=0</p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑥<span
                            class="s5">𝑛</span> − 1</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 27pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=1</p>
                    <p class="s4" style="padding-left: 1pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑥<span
                            class="s5">𝑛</span> − 𝑥</p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 26pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=2</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑥<span
                            class="s5">𝑛</span> − 𝑥<span class="s5">2</span></p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 14pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=𝑛−2</p>
                    <p class="s1" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑥<span
                            class="s2">𝑛 </span>− 𝑥<span class="s2">𝑛−2</span></p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=𝑛−1</p>
                    <p class="s1" style="padding-left: 8pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑥<span
                            class="s2">𝑛 </span>− 𝑥<span class="s2">𝑛−1</span></p>
                    <p class="s3" style="padding-top: 4pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s4" style="text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛𝑥<span class="s28">𝑛
                        </span>− ∑<span class="s29">𝑛−1 </span>𝑥<span class="s28">𝑖</span></p>
                    <p class="s4" style="padding-top: 10pt;padding-left: 8pt;text-indent: 0pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s4"
                        style="padding-top: 2pt;padding-left: 3pt;text-indent: 0pt;line-height: 11pt;text-align: center;">
                        + +</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 3pt;text-indent: 0pt;line-height: 11pt;text-align: center;">𝑥 −
                        1</p>
                    <p class="s4" style="padding-top: 10pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s4" style="padding-top: 2pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">+ ⋯ +
                    </p>
                    <p class="s4" style="padding-top: 10pt;padding-left: 8pt;text-indent: 0pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s4"
                        style="padding-top: 2pt;padding-left: 8pt;text-indent: 0pt;line-height: 11pt;text-align: left;">
                        +</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 31pt;text-indent: 0pt;line-height: 11pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s8"
                        style="padding-top: 1pt;padding-left: 8pt;text-indent: 0pt;line-height: 66%;text-align: left;">=
                        <span class="s30">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                            class="s31">𝑖=0 &nbsp;&nbsp;&nbsp;</span></p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 43pt;text-indent: 0pt;line-height: 11pt;text-align: left;">𝑥 − 1
                    </p>
                    <p class="s4"
                        style="padding-top: 10pt;padding-left: 89pt;text-indent: 0pt;line-height: 14pt;text-align: left;">
                        𝑛𝑥<span class="s5">𝑛</span></p>
                    <p class="s4" style="padding-left: 77pt;text-indent: 0pt;line-height: 14pt;text-align: left;">=</p>
                    <p class="s4"
                        style="padding-top: 3pt;padding-left: 18pt;text-indent: 0pt;line-height: 11pt;text-align: left;">
                        𝑥<span class="s5">𝑛</span> − 1</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 1pt;text-indent: 0pt;line-height: 63%;text-align: left;">− (
                        <span class="s8">𝑥 − 1 </span>)</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 62pt;text-indent: 0pt;line-height: 8pt;text-align: left;">=</p>
                    <p class="s4" style="padding-left: 4pt;text-indent: 0pt;line-height: 11pt;text-align: left;">𝑥 − 1
                    </p>
                    <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s4" style="padding-left: 29pt;text-indent: -28pt;line-height: 78%;text-align: left;">(𝑛𝑥
                        − 𝑛 − 1)𝑥<span class="s5">𝑛</span> + 1 (𝑥 − 1)<span class="s5">2</span> <span
                            class="s27">.</span></p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 10pt;text-indent: 0pt;text-align: center;">𝑛−1
                    </p>
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Thus,
                        ∑(𝑖 + 1)𝑥<span class="s5">𝑖</span> =</p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 10pt;text-indent: 0pt;text-align: center;">𝑖=0
                    </p>
                    <p class="s4"
                        style="padding-top: 7pt;padding-left: 29pt;text-indent: -28pt;line-height: 118%;text-align: left;">
                        (𝑛𝑥 − 𝑛 − 1)𝑥<span class="s5">𝑛</span> + 1 (𝑥 − 1)<span class="s5">2</span></p>
                    <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="text-indent: 0pt;text-align: left;">, (𝑥 ≠ 1).</p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 2pt;text-indent: 0pt;text-align: center;">𝑛−1
                    </p>
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Note
                        that ∑(𝑖 + 1)𝑥<span class="s5">𝑖</span> =</p>
                    <p class="s3" style="padding-top: 4pt;padding-left: 10pt;text-indent: 0pt;text-align: center;">𝑖=𝑘
                    </p>
                    <p class="s4"
                        style="padding-top: 8pt;padding-bottom: 2pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">
                        ((𝑛 − 𝑘)𝑥 − (𝑛 − 𝑘) − 1)𝑥<span class="s5">𝑛</span> + 𝑥<span class="s5">𝑘</span></p>
                    <p style="padding-left: 1pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="padding-left: 1pt;text-indent: 0pt;text-align: center;">(𝑥 − 1)<span
                            class="s5">2</span></p>
                    <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s4" style="text-indent: 0pt;text-align: left;">, (𝑥 ≠ 1).</p>
                    <p style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">These results
                        denote the first derivative [5] of geometric series.</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                </li>
                <li data-list-text="2.4">
                    <h2 style="padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: left;">Binomial
                        Expansions equal to Multiple of 2</h2>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Let us develop some series of
                        binomial coefficients or binomial expansions [18-22] which are equal to the multiple of 2 or
                        exponents of 2 or both.</p>
                    <p class="s3" style="padding-left: 31pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                        𝑛</p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        (1) ∑ 𝑉<span class="s5">𝑛−𝑖</span> = 2<span class="s5">𝑛</span>. (2) ∑ 𝑖 × 𝑉<span
                            class="s5">𝑛−𝑖</span> = 𝑛2<span class="s5">𝑛−1</span>. (3) ∑(𝑖 + 1)𝑉<span
                            class="s5">𝑛−𝑖</span> = (𝑛 + 2)2<span class="s5">𝑛−1</span>.</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: right;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 3pt;padding-left: 31pt;text-indent: -4pt;line-height: 61%;text-align: left;">
                        𝑖=0 n</p>
                    <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑖
                    </p>
                    <p class="s3" style="padding-top: 2pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 100pt;text-indent: 0pt;line-height: 6pt;text-align: center;">𝑛
                    </p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;text-indent: 0pt;text-align: center;">𝑖=0</p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">(4) ∑(i
                        − 1)V<span class="s5">n−i</span> = (n − 2)2<span class="s5">n−1</span>, 𝑉<span
                            class="s5">𝑛</span> = 𝖦 <span class="s32">(𝑟 + 𝑖) </span>, ( 𝑛 ≥ 1, 𝑟 ≥ 0 &amp; 𝑛, 𝑟
                        ∈ 𝑁).</p>
                    <p class="s3" style="padding-left: 82pt;text-indent: 0pt;line-height: 9pt;text-align: left;">i</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 27pt;text-indent: 0pt;text-align: left;">i=0</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">𝑟</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 54pt;text-indent: 0pt;text-align: center;">𝑖=1
                    </p>
                    <p style="padding-left: 1pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="padding-left: 13pt;text-indent: 0pt;text-align: left;">𝑛!</p>
                </li>
                <li data-list-text="2.5">
                    <h2
                        style="padding-top: 12pt;padding-left: 23pt;text-indent: -18pt;line-height: 13pt;text-align: left;">
                        Relations between Binomial Expansion and Combinatorial Geometric Series</h2>
                    <p class="s3" style="padding-left: 70pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 n 𝑛
                    </p>
                    <p class="s4"
                        style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        Relation 1: ∑(𝑖 + 1)𝑉<span class="s5">𝑛−𝑖</span> + ∑(i − 1)V<span class="s5">n−i</span> = ∑
                        𝑖 × 𝑉<span class="s5">𝑛−𝑖</span> = 𝑛2<span class="s5">𝑛−1</span>.</p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s3" style="text-indent: 0pt;text-align: right;">𝑖=0</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 73pt;text-indent: 0pt;text-align: center;">i=0
                    </p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">i 𝑖</p>
                    <p class="s3" style="padding-top: 2pt;text-indent: 0pt;text-align: center;">𝑖=0</p>
                    <p style="padding-top: 11pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Proof: Let us
                        simplify the general terms in the two parts of binomial expansions (Relation 1) as follows:</p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">(𝑖 +
                        1)𝑉<span class="s5">𝑛−𝑖</span> + (i − 1)V<span class="s5">n−i</span> = 2𝑖V<span
                            class="s5">n−i</span>. This idea can be applied to Relation 1.</p>
                    <p class="s3" style="padding-left: 46pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖
                        i<span class="s33"> </span>i</p>
                    <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 n 𝑛
                    </p>
                    <p class="s4"
                        style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        ∑(𝑖 + 1)𝑉<span class="s5">𝑛−𝑖</span> + ∑(i − 1)V<span class="s5">n−i</span> = 2 ∑ 𝑖𝑉<span
                            class="s5">𝑛−𝑖</span> = (𝑛 + 2)2<span class="s5">𝑛−1</span> + (n − 2)2<span
                            class="s5">n−1</span> = 2𝑛2<span class="s5">n−1</span>.</p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 38pt;text-indent: 0pt;text-align: left;">i=0</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">i 𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 47pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 12pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 2pt;text-indent: 0pt;line-height: 8pt;text-align: center;">
                        Then, 2 ∑ 𝑖𝑉<span class="s5">𝑛−𝑖</span> = 2𝑛2<span class="s5">n−1</span> ⟹ ∑ 𝑖𝑉<span
                            class="s5">𝑛−𝑖</span> = 𝑛2<span class="s5">n−1</span>.</p>
                    <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Hence, Relation 1 is proved.</p>
                    <p class="s3" style="padding-left: 32pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
                    <p class="s3" style="padding-left: 32pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
                    <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s3" style="padding-left: 70pt;text-indent: 0pt;text-align: left;">𝑛 n 𝑛</p>
                    <p class="s4"
                        style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        Relation 2: ∑(𝑖 + 1)𝑉<span class="s5">𝑛−𝑖</span> − ∑(i − 1)V<span class="s5">n−i</span> = ∑
                        𝑉<span class="s5">𝑛−𝑖</span> = 2<span class="s5">𝑛</span>.</p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=0</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 73pt;text-indent: 0pt;line-height: 8pt;text-align: center;">
                        i=0</p>
                    <p class="s3" style="padding-left: 39pt;text-indent: 0pt;line-height: 9pt;text-align: left;">i 𝑖
                    </p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 72pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Proof: Let us simplify the general
                        terms in the two parts of binomial expansions (Relation 2) as follows:</p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">(𝑖 +
                        1)𝑉<span class="s5">𝑛−𝑖</span> − (i − 1)V<span class="s5">n−i</span> = 2V<span
                            class="s5">n−i</span>. This idea can be applied to Relation 2.</p>
                    <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 7pt;text-align: center;">𝑖
                        i<span class="s33"> </span>i</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: center;">𝑛 n 𝑛</p>
                    <p class="s4"
                        style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        ∑(𝑖 + 1)𝑉<span class="s5">𝑛−𝑖</span> − ∑(i − 1)V<span class="s5">n−i</span> = 2 ∑ 𝑉<span
                            class="s5">𝑛−𝑖</span> = (𝑛 + 2)2<span class="s5">𝑛−1</span> − (n − 2)2<span
                            class="s5">n−1</span> = 4 × 2<span class="s5">n−1</span>.</p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 38pt;text-indent: 0pt;text-align: left;">i=0</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">i 𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 47pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 10pt;text-indent: 0pt;line-height: 8pt;text-align: center;">
                        Then, 2 ∑ 𝑉<span class="s5">𝑛−𝑖</span> = 22<span class="s5">n</span> ⟹ ∑ 𝑉<span
                            class="s5">𝑛−𝑖</span> = 2<span class="s5">n</span>.</p>
                    <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Hence, Relation 2 is proved.</p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
                </li>
                <li data-list-text="2.6">
                    <h2
                        style="padding-top: 3pt;padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: left;">
                        Annamalai’s Binomial Expansion</h2>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">Let 𝑛,
                        𝑟 ∈ 𝑁 = {0, 1, 2, 3, ⋯ }. The Annamalai<span class="s5">′</span>s binomial identity [19] is
                        given below:</p>
                    <p class="s4"
                        style="padding-top: 13pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑉<span class="s5">𝑟</span> + 𝑉<span class="s5">𝑟</span> + 𝑉<span class="s5">𝑟</span> + ⋯ +
                        𝑉<span class="s5">𝑟</span> = 𝑉<span class="s5">𝑟+1</span> ⟺ 𝑉<span class="s5">0</span> +
                        𝑉<span class="s5">1</span> + 𝑉<span class="s5">2</span> + ⋯ + 𝑉<span class="s5">𝑟</span> =
                        𝑉<span class="s5">𝑟</span> , (∵ 𝑉<span class="s5">𝑟</span> = 𝑉<span class="s5">𝑛</span>).
                    </p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 1 2
                    </p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 𝑛
                        𝑛</p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 𝑛+1
                    </p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 𝑟
                    </p>
                    <p class="s4"
                        style="padding-top: 12pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        <span class="p">From the binomial identity </span>𝑉<span class="s5">𝑟</span> + 𝑉<span
                            class="s5">𝑟</span> + 𝑉<span class="s5">𝑟</span> + ⋯ + 𝑉<span class="s5">𝑟</span> =
                        𝑉<span class="s5">𝑟+1</span><span class="p">, we can derive the following</span></p>
                    <p style="padding-top: 6pt;padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
                        binomial expansions:</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 1 2
                    </p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑛
                    </p>
                    <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">(1). ∑
                        𝑉<span class="s5">0</span></p>
                    <p class="s3"
                        style="padding-top: 4pt;padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: center;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 5pt;text-align: center;">𝑛
                    </p>
                    <p class="s3" style="padding-left: 21pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑛</p>
                    <p class="s4" style="text-indent: 0pt;line-height: 10pt;text-align: right;">(𝑛 + 1)</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: right;">= ∑ 1 = 1 + 1 + 1 + 1 + ⋯
                        + 1 + 1 = .</p>
                    <p class="s4" style="padding-left: 33pt;text-indent: 0pt;line-height: 9pt;text-align: center;">1!
                    </p>
                    <p class="s3" style="padding-left: 17pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 18pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑛</p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 15pt;text-align: left;">(2). ∑
                        𝑉<span class="s5">1</span> = ∑ <span class="s32">(𝑖 + 1) </span>= 1 + 2 + 3 + ⋯ + 𝑛 + (𝑛 +
                        1) = <span class="s32">(𝑛 + 1)(𝑛 + 2)</span>.</p>
                    <p class="s3" style="padding-left: 50pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 26pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 31pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑛</p>
                    <p class="s34" style="padding-left: 35pt;text-indent: 0pt;line-height: 1pt;text-align: left;"> </p>
                    <p class="s4" style="padding-left: 48pt;text-indent: 0pt;line-height: 12pt;text-align: left;">1! 2!
                    </p>
                    <p class="s3" style="padding-left: 18pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 23pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑛</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s35" style="padding-left: 5pt;text-indent: 0pt;line-height: 46%;text-align: left;"><span
                            class="s9">(</span>3<span class="s9">)</span>. ∑ 𝑉<span class="s26">2 </span>= ∑ <span
                            class="s4">(𝑖 + 1)(𝑖 + 2) </span>= 1 + 3 + ⋯ + <span class="s4">(𝑛 + 1)(𝑛 + 2) </span>=
                        <span class="s4">(𝑛 + 1)(𝑛 + 2)(𝑛 + 3)</span>.</p>
                    <p class="s3" style="padding-left: 50pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 26pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 31pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑛</p>
                    <p class="s4" style="text-indent: 0pt;line-height: 12pt;text-align: right;">2!</p>
                    <p class="s3" style="padding-left: 18pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 23pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑛</p>
                    <p class="s4" style="padding-left: 26pt;text-indent: 0pt;line-height: 14pt;text-align: left;">2! 3!
                    </p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s35" style="padding-left: 5pt;text-indent: 0pt;line-height: 46%;text-align: left;"><span
                            class="s9">(</span>4<span class="s9">)</span>. ∑ 𝑉<span class="s26">3 </span>= ∑ <span
                            class="s4">(𝑖 + 1)(𝑖 + 2)(𝑖 + 3) </span>= <span class="s4">(𝑛 + 1)(𝑛 + 2)(𝑛 + 3)(𝑛 +
                            4)</span>.</p>
                    <p class="s3" style="padding-left: 50pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 26pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 31pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑛</p>
                    <p class="s4" style="padding-left: 83pt;text-indent: 0pt;line-height: 12pt;text-align: left;">3! 4!
                    </p>
                    <p class="s3" style="padding-left: 18pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 23pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑛</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s35" style="padding-left: 5pt;text-indent: 0pt;line-height: 46%;text-align: left;"><span
                            class="s9">(</span>5<span class="s9">)</span>. ∑ 𝑉<span class="s26">4 </span>= ∑ <span
                            class="s4">(𝑖 + 1)(𝑖 + 2)(𝑖 + 3)(𝑖 + 4) </span>= <span class="s4">(𝑛 + 1)(𝑛 + 2)(𝑛 +
                            3)(𝑛 + 4)(𝑛 + 5)</span>.</p>
                    <p class="s3" style="padding-left: 50pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 26pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s4" style="padding-left: 101pt;text-indent: 0pt;line-height: 12pt;text-align: left;">4! 5!
                    </p>
                    <p class="s3" style="padding-left: 18pt;text-indent: 0pt;line-height: 3%;text-align: left;">𝑖=0
                        <span class="s36">th</span></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Similarly, we can
                        continue this process up to <i>r </i>times. The <i>r </i>binomial expansion is as follows:</p>
                    <p class="s3" style="padding-left: 30pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑛 𝑛
                    </p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s35" style="padding-left: 5pt;text-indent: 0pt;line-height: 46%;text-align: left;"><span
                            class="s9">(</span>𝑟<span class="s9">)</span>. ∑ 𝑉<span class="s26">𝑟 </span>= ∑ <span
                            class="s4">(𝑖 + 1)(𝑖 + 2)(𝑖 + 3) ⋯ (𝑖 + 𝑟) </span>= <span class="s4">(𝑛 + 1)(𝑛 + 2) ⋯
                            (𝑛 + 𝑟)(𝑛 + 𝑟 + 1)</span>,</p>
                    <p class="s3" style="padding-left: 49pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 26pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s3" style="padding-left: 18pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
                    <p class="s4" style="text-indent: 0pt;line-height: 14pt;text-align: right;">𝑟!</p>
                    <p class="s4" style="padding-left: 26pt;text-indent: 0pt;line-height: 14pt;text-align: left;">(𝑟 +
                        1)!</p>
                    <p class="s4"
                        style="padding-top: 11pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        <span class="p">From the binomial identity </span>𝑉<span class="s5">0</span> + 𝑉<span
                            class="s5">1</span> + 𝑉<span class="s5">2</span> + ⋯ + 𝑉<span class="s5">𝑟</span> =
                        𝑉<span class="s5">𝑟</span> <span class="p">, we can derive the following</span></p>
                    <p style="padding-top: 6pt;padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
                        binomial expansions.</p>
                    <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 𝑛 𝑛
                    </p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 𝑛+1
                    </p>
                    <p class="s4"
                        style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        (𝑖). ∑ 𝑉<span class="s5">𝑖</span> = 𝑉<span class="s5">𝑟</span> ⟹ 1 + 1 + 1 + 1 + 1 + ⋯ + 1
                        + 1 = 𝑟 + 1, (∵ 𝑉<span class="s5">𝑟</span> = 1 for 𝑟 = 0, 1, 2, ⋯ ).</p>
                    <p class="s3" style="padding-left: 47pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 1 0
                    </p>
                    <p class="s3" style="padding-top: 2pt;text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=0</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 5pt;text-align: right;">𝑟</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s35" style="padding-left: 5pt;text-indent: 0pt;line-height: 43%;text-align: left;"><span
                            class="s9">(</span>𝑖𝑖<span class="s9">)</span>. ∑ 𝑉<span class="s26">𝑖 </span>= 𝑉<span
                            class="s26">𝑟 </span>⟹ 1 + <span class="s4">2 </span>+ <span class="s4">2 × 3 </span>+ ⋯ +
                        <span class="s4">2 × 3 × 4 × ⋯ × 𝑟 </span>= <span class="s4">3 × 4 × 5 × ⋯ × 𝑟 × (𝑟 +
                            1)</span>.</p>
                    <p class="s3" style="padding-left: 51pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1 2</p>
                    <p class="s3" style="padding-top: 2pt;text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=0</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 5pt;text-align: right;">𝑟</p>
                    <p class="s4" style="padding-left: 28pt;text-indent: 0pt;line-height: 14pt;text-align: left;">1! 2!
                    </p>
                    <p class="s4" style="text-indent: 0pt;line-height: 14pt;text-align: right;">𝑟!</p>
                    <p class="s4" style="padding-left: 28pt;text-indent: 0pt;line-height: 14pt;text-align: left;">𝑟!
                    </p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s35" style="padding-left: 5pt;text-indent: 0pt;line-height: 43%;text-align: left;"><span
                            class="s9">(</span>𝑖𝑖𝑖<span class="s9">)</span>. ∑ 𝑉<span class="s26">𝑖 </span>=
                        𝑉<span class="s26">𝑟 </span>⟹ 1 + <span class="s4">3 </span>+ <span class="s4">3 × 4 </span>+
                        ⋯ + <span class="s4">3 × 4 × 5 × ⋯ × 𝑟 </span>= <span class="s4">4 × 5 × 6 × ⋯ × 𝑟 × (𝑟 +
                            1)</span>.</p>
                    <p class="s3" style="padding-left: 55pt;text-indent: 0pt;line-height: 8pt;text-align: left;">2 3</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 31pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s4" style="padding-left: 31pt;text-indent: 0pt;line-height: 14pt;text-align: left;">1! 2!
                    </p>
                    <p class="s4" style="text-indent: 0pt;line-height: 14pt;text-align: right;">𝑟!</p>
                    <p class="s4" style="padding-left: 31pt;text-indent: 0pt;line-height: 14pt;text-align: left;">𝑟!
                    </p>
                    <p class="s3" style="padding-top: 8pt;text-indent: 0pt;text-align: right;">𝑟</p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        Similarly, the binomial expansion for ∑ 𝑉<span class="s5">𝑖</span> = 𝑉<span
                            class="s5">𝑟</span></p>
                    <p style="padding-top: 8pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">is given
                        below:</p>
                    <p class="s3" style="padding-left: 220pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 198pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 21pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛+1
                    </p>
                    <p class="s4" style="padding-left: 14pt;text-indent: 0pt;line-height: 10pt;text-align: center;">(𝑛
                        + 1)</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: center;">1 + +
                    </p>
                    <p class="s4" style="padding-left: 14pt;text-indent: 0pt;line-height: 10pt;text-align: center;">1!
                    </p>
                    <p class="s4"
                        style="padding-bottom: 2pt;padding-left: 10pt;text-indent: 0pt;line-height: 13pt;text-align: center;">
                        (𝑛 + 1)(𝑛 + 2)</p>
                    <p style="text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="padding-left: 10pt;text-indent: 0pt;line-height: 12pt;text-align: center;">2!
                    </p>
                    <p class="s4" style="padding-left: 12pt;text-indent: 0pt;line-height: 10pt;text-align: left;">(𝑛 +
                        1)(𝑛 + 2)(𝑛 + 3)</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: left;">+</p>
                    <p class="s4" style="padding-left: 64pt;text-indent: 0pt;line-height: 10pt;text-align: left;">3!</p>
                    <p class="s4" style="padding-top: 7pt;text-indent: 0pt;text-align: left;">+ ⋯ +</p>
                    <p class="s4" style="padding-bottom: 2pt;text-indent: 0pt;line-height: 13pt;text-align: center;">(𝑛
                        + 1)(𝑛 + 2) ⋯ (𝑛 + 𝑟)</p>
                    <p style="text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="text-indent: 0pt;line-height: 12pt;text-align: center;">𝑟!</p>
                    <p class="s4" style="text-indent: 0pt;line-height: 10pt;text-align: right;">(𝑛 + 2)(𝑛 + 3) ⋯ (𝑛 +
                        𝑟)(𝑛 + 𝑟 + 1)</p>
                    <p style="text-indent: 0pt;text-align: left;" />
                    <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: right;">= .</p>
                    <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: center;">𝑟!</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">These expressions
                        are called Annamalai’s binomial expansions.</p>
                </li>
                <li data-list-text="2.7">
                    <h2
                        style="padding-top: 3pt;padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: left;">
                        Annamalai’s Binomial Identity and Theorem</h2>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">A binomial theorem [9, 10] is
                        constituted using the Annamalai’s binomial identities [19] given below:</p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">(𝑖)
                        𝑉<span class="s5">0</span> = 𝑉<span class="s5">𝑛</span> = 1 for 𝑛 = 0, 1, 2, 3, 3, ⋯</p>
                    <p class="s3" style="padding-left: 31pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑛 0
                    </p>
                    <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">(𝑖𝑖)
                        𝑉<span class="s5">𝑚</span> = 𝑉<span class="s5">𝑟</span>, (𝑚, 𝑟 ≥ 1 <span class="s6">&amp;
                        </span>𝑚, 𝑟 ∈ 𝑁).</p>
                    <p class="s3" style="padding-left: 35pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑟 𝑚
                    </p>
                    <p class="s3" style="padding-left: 36pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑟 𝑟
                    </p>
                    <p class="s4"
                        style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        (𝑖𝑖𝑖) ∑ 𝑉<span class="s5">𝑛</span> = 𝑉<span class="s5">𝑛+1</span> (𝑂𝑅) ∑ 𝑉<span
                            class="s5">𝑖</span> = 𝑉<span class="s5">𝑟</span> , (∵ 𝑉<span class="s5">𝑚</span> =
                        𝑉<span class="s5">𝑟</span> <span class="s6">&amp; </span>𝑉<span class="s5">0</span> = 𝑉<span
                            class="s5">𝑛</span> = 1).</p>
                    <p class="s3" style="padding-left: 55pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖 𝑟
                    </p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 32pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 91pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑟</p>
                    <p class="s3" style="padding-left: 75pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑛
                    </p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 31pt;text-indent: 0pt;line-height: 8pt;text-align: center;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 32pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑟 𝑟
                    </p>
                    <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛+1
                    </p>
                    <p class="s3" style="padding-top: 8pt;text-indent: 0pt;text-align: right;">𝑟</p>
                    <p class="s3" style="padding-left: 32pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟 𝑚
                    </p>
                    <p class="s3" style="padding-top: 8pt;text-indent: 0pt;text-align: right;">𝑟</p>
                    <p class="s3" style="padding-left: 23pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 0
                    </p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        𝐓𝐡𝐞𝐨𝐫𝐞𝐦 𝟐. 𝟏: ∑ 𝑉<span class="s5">0</span> + ∑ 𝑉<span class="s5">1</span> + ∑ 𝑉<span
                            class="s5">2</span> + ∑ 𝑉<span class="s5">3</span> + ⋯ + ∑ 𝑉<span class="s5">𝑛</span> =
                        𝑉<span class="s5">𝑛+1</span> − 1.</p>
                    <p class="s3" style="padding-left: 110pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 86pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 40pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 17pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 40pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 17pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 40pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 17pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 64pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 41pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 24pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟+1
                    </p>
                    <p class="s3" style="padding-top: 9pt;padding-left: 47pt;text-indent: 0pt;text-align: left;">𝑟 𝑟
                        𝑟 𝑟</p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        𝑃𝑟𝑜𝑜𝑓. ∑ 𝑉<span class="s5">0</span> = 𝑉<span class="s5">1</span>; ∑ 𝑉<span
                            class="s5">1</span> = 𝑉<span class="s5">2</span>; ∑ 𝑉<span class="s5">2</span> = 𝑉<span
                            class="s5">3</span> ; ⋯ ; ∑ 𝑉<span class="s5">𝑛</span> = 𝑉<span class="s5">𝑛+1</span>.
                    </p>
                    <p class="s3" style="padding-left: 66pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 43pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 23pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟 𝑖
                    </p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 45pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 22pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑟 𝑖
                        𝑟</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;line-height: 8pt;text-align: center;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 62pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖 𝑟
                    </p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 38pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">By adding these
                        expressions on the both sides, we get</p>
                    <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟 𝑟
                        𝑟 𝑟 𝑟 𝑛+1</p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">∑
                        𝑉<span class="s5">0</span> + ∑ 𝑉<span class="s5">1</span> + ∑ 𝑉<span class="s5">2</span> + ∑
                        𝑉<span class="s5">3</span> + ⋯ + ∑ 𝑉<span class="s5">𝑛</span> = ∑ 𝑉<span
                            class="s5">𝑖</span></p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        𝑖=1</p>
                    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛+1
                    </p>
                    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛+1
                    </p>
                    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛+1
                    </p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        Here, ∑ 𝑉<span class="s5">𝑖</span> = 𝑉<span class="s5">0</span> + ∑ 𝑉<span
                            class="s5">𝑖</span> − 𝑉<span class="s5">0</span> = ∑ 𝑉<span class="s5">𝑖</span> − 1 =
                        𝑉<span class="s5">𝑛+1</span> − 1, (∵ 𝑉<span class="s5">0</span> = 1).</p>
                    <p class="s3" style="padding-left: 57pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑟 𝑟
                    </p>
                    <p class="s3" style="padding-top: 2pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑖=1
                    </p>
                    <p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑟 𝑟
                    </p>
                    <p class="s3" style="padding-left: 40pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 17pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=1</p>
                    <p class="s3" style="padding-left: 20pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑟</p>
                    <p class="s3" style="padding-left: 2pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑟 𝑟
                    </p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: center;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 19pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑟 𝑟
                    </p>
                    <p class="s3" style="padding-left: 20pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟+1 𝑟
                    </p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">∴
                        ∑ 𝑉<span class="s5">0</span> + ∑ 𝑉<span class="s5">1</span> + ∑ 𝑉<span class="s5">2</span> +
                        ∑ 𝑉<span class="s5">3</span> + ⋯ + ∑ 𝑉<span class="s5">𝑛</span> = 𝑉<span
                            class="s5">𝑛+𝑟</span> − 1.</p>
                    <p class="s3" style="padding-left: 39pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 15pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 39pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 15pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 39pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 15pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 39pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 15pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 39pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3"
                        style="padding-top: 2pt;padding-left: 15pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        𝑖=0</p>
                    <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑟+1
                    </p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Hence, theorem is
                        proved.</p>
                    <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s3" style="padding-left: 62pt;text-indent: 0pt;text-align: left;">𝑟 𝑟 𝑟 𝑟 𝑟</p>
                    <p class="s4"
                        style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        Note that ∑ 𝑉<span class="s5">𝑖</span> + ∑ 𝑉<span class="s5">𝑖</span> + ∑ 𝑉<span
                            class="s5">𝑖</span> + ∑ 𝑉<span class="s5">𝑖</span> + ⋯ + ∑ 𝑉<span class="s5">𝑖</span> =
                        𝑉<span class="s5">𝑟+1</span> − 1.</p>
                    <p class="s3" style="padding-left: 81pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 58pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 37pt;text-indent: 0pt;line-height: 9pt;text-align: left;">1</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 14pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 37pt;text-indent: 0pt;line-height: 9pt;text-align: left;">2</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 14pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 37pt;text-indent: 0pt;line-height: 9pt;text-align: left;">3</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 14pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 59pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 37pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 21pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛+1
                    </p>
                </li>
                <li data-list-text="2.8">
                    <h2
                        style="padding-top: 11pt;padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: left;">
                        Combinatorial Geometric Series and Theorem</h2>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">This Annamalai’s
                        binomial expansion is applied into the following binomial series:</p>
                    <p class="s3" style="text-indent: 0pt;line-height: 6pt;text-align: center;">𝑛 𝑛 𝑟</p>
                    <p class="s4" style="padding-left: 10pt;text-indent: 0pt;line-height: 15pt;text-align: center;">∑
                        𝑉<span class="s5">𝑟</span>𝑥<span class="s5">𝑖</span> = ∑ 𝖦 <span class="s32">𝑖 + 𝑗
                        </span>𝑥<span class="s5">𝑖</span>.</p>
                    <p class="s3" style="padding-left: 195pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s3" style="padding-top: 2pt;padding-left: 172pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s3" style="padding-left: 29pt;text-indent: 0pt;text-align: left;">𝑖=0 𝑗=1</p>
                    <p style="padding-left: 1pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p class="s4" style="padding-left: 8pt;text-indent: 0pt;text-align: left;">𝑟!</p>
                    <p style="padding-top: 13pt;padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">
                        The following theorem is derived from the Annamalai’s binomial series [19].</p>
                    <p class="s19" style="padding-left: 84pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                        𝑛 𝑛 𝑛</p>
                    <p class="s20"
                        style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        𝐓𝐡𝐞𝐨𝐫𝐞𝐦 𝟐. 𝟐: ∑ 𝑉<span class="s21">𝑟+1</span>𝑥<span class="s21">𝑖</span> = ∑ <span
                            class="s37">𝑉</span><span class="s38">𝑟</span>𝑥<span class="s21">𝑖</span> + ∑ <span
                            class="s37">𝑉</span><span class="s38">𝑟</span><span class="s37"> </span>𝑥<span
                            class="s21">𝑖</span> + ∑ <span class="s37">𝑉</span><span class="s38">𝑟</span><span
                            class="s37"> </span>𝑥<span class="s21">𝑖</span> + ⋯ + ∑ <span class="s37">𝑉</span><span
                            class="s38">𝑟</span><span class="s37"> </span>𝑥<span class="s21">𝑖</span>.</p>
                    <p class="s19" style="padding-left: 101pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖
                    </p>
                    <p class="s19" style="padding-top: 2pt;padding-left: 79pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p class="s3" style="padding-left: 59pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s19" style="padding-top: 2pt;padding-left: 36pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s19" style="padding-left: 23pt;text-indent: 0pt;text-align: left;">𝑖=1</p>
                    <p class="s3" style="padding-left: 7pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−1</p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s19" style="padding-left: 21pt;text-indent: 0pt;text-align: left;">𝑖=2</p>
                    <p class="s3" style="padding-left: 7pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−2</p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s19" style="text-indent: 0pt;text-align: right;">𝑖=𝑛</p>
                    <p class="s3" style="padding-left: 7pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−𝑛
                    </p>
                    <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Proof: Let’s show
                        that the computation of summations of the binomial series (right-hand side of the theorem) is
                        equal to the binomial series (left- hand side of the theorem).</p>
                    <p class="s19" style="padding-left: 9pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛
                        𝑛 𝑛 𝑛</p>
                    <p class="s37"
                        style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        <span class="s20">∑ </span>𝑉<span class="s38">𝑟</span><span class="s20">𝑥</span><span
                            class="s21">𝑖</span><span class="s20"> + ∑ </span>𝑉<span class="s38">𝑟</span> <span
                            class="s20">𝑥</span><span class="s21">𝑖</span><span class="s20"> + ∑ </span>𝑉<span
                            class="s38">𝑟</span> <span class="s20">𝑥</span><span class="s21">𝑖</span><span
                            class="s20"> + ⋯ + ∑ </span>𝑉<span class="s38">𝑟</span> <span class="s39">(</span> <span
                            class="s39">)</span><span class="s20">𝑥</span><span class="s21">𝑖</span><span class="s20">
                            + ∑ </span>𝑉<span class="s38">𝑟</span> <span class="s20">𝑥</span><span
                            class="s21">𝑖</span></p>
                    <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
                    <p class="s19" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=0
                    </p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s19" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=1</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−1</p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s19" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=2</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−2</p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s19" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=𝑛−1</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖− 𝑛−1
                    </p>
                    <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s19" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑖=𝑛</p>
                    <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−𝑛
                    </p>
                    <p class="s20"
                        style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;line-height: 7pt;text-align: left;">=
                        (𝑉<span class="s21">𝑟</span> + 𝑉<span class="s21">𝑟</span>𝑥 + 𝑉<span
                            class="s21">𝑟</span>𝑥<span class="s21">2</span> + 𝑉<span class="s21">𝑟</span>𝑥<span
                            class="s21">3</span> + ⋯ + 𝑉<span class="s21">𝑟</span>𝑥<span class="s21">𝑛</span>) +
                        (𝑉<span class="s21">𝑟</span>𝑥 + 𝑉<span class="s21">𝑟</span>𝑥<span class="s21">2</span> +
                        𝑉<span class="s21">𝑟</span>𝑥<span class="s21">3</span> + 𝑉<span class="s21">𝑟</span>𝑥<span
                            class="s21">4</span> + ⋯ + 𝑉<span class="s21">𝑟</span> 𝑥<span class="s21">𝑛</span>)</p>
                    <p class="s19" style="padding-left: 28pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2
                        3</p>
                    <p class="s19" style="padding-left: 28pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 0 1
                        2 3</p>
                    <p class="s19" style="padding-left: 28pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛−1
                    </p>
                    <p class="s20"
                        style="padding-top: 10pt;padding-left: 29pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        +(𝑉<span class="s21">𝑟</span>𝑥<span class="s21">2</span> + 𝑉<span
                            class="s21">𝑟</span>𝑥<span class="s21">3</span> + 𝑉<span class="s21">𝑟</span>𝑥<span
                            class="s21">4</span> + 𝑉<span class="s21">𝑟</span>𝑥<span class="s21">5</span> + ⋯ +
                        𝑉<span class="s21">𝑟</span> 𝑥<span class="s21">𝑛</span>) + ⋯ + (𝑉<span
                            class="s21">𝑟</span>𝑥<span class="s21">𝑛−1</span> + 𝑉<span class="s21">𝑟</span>𝑥<span
                            class="s21">𝑛</span>) + 𝑉<span class="s21">𝑟</span>𝑥<span class="s21">𝑛</span></p>
                    <p class="s19" style="padding-left: 47pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2
                        3</p>
                    <p class="s19" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑛−2</p>
                    <p class="s19" style="padding-left: 47pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 0
                    </p>
                    <p class="s20"
                        style="padding-top: 9pt;padding-left: 19pt;text-indent: 0pt;line-height: 7pt;text-align: left;">
                        = 𝑉<span class="s21">𝑟</span> + (𝑉<span class="s21">𝑟</span> + 𝑉<span
                            class="s21">𝑟</span>)𝑥 + (𝑉<span class="s21">𝑟</span> + 𝑉<span class="s21">𝑟</span> +
                        𝑉<span class="s21">𝑟</span>)𝑥<span class="s21">2</span> + ⋯ + (𝑉<span class="s21">𝑟</span>
                        + 𝑉<span class="s21">𝑟</span> + 𝑉<span class="s21">𝑟</span> + 𝑉<span class="s21">𝑟</span>
                        + ⋯ + 𝑉<span class="s21">𝑟</span>)𝑥<span class="s21">𝑛</span></p>
                    <p class="s19" style="padding-left: 36pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 0 1
                    </p>
                    <p class="s19" style="padding-left: 34pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2
                    </p>
                    <p class="s19" style="padding-left: 36pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2
                        3 𝑛</p>
                    <p class="s4"
                        style="padding-top: 12pt;padding-left: 14pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                        (Note that 𝑉<span class="s5">𝑝</span> + 𝑉<span class="s5">𝑝</span> + 𝑉<span
                            class="s5">𝑝</span> + ⋯ + 𝑉<span class="s5">𝑝</span> = 𝑉<span class="s5">𝑝+1</span>for
                        𝑟 = 0, 1, 2, 3, ⋯ , and 𝑉<span class="s5">𝑝</span> = 𝑉<span class="s5">𝑝+1</span> = 1)</p>
                    <p class="s3" style="padding-left: 80pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 1 2
                    </p>
                    <p class="s3" style="padding-left: 45pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑟 𝑟 0
                        0</p>
                    <p class="s3" style="padding-left: 263pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛</p>
                    <p class="s1"
                        style="padding-top: 2pt;padding-left: 18pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                        = 𝑉<span class="s2">𝑟+1 </span>+ 𝑉<span class="s2">𝑟+1</span>𝑥 + 𝑉<span
                            class="s2">𝑟+1</span>𝑥<span class="s2">2 </span>+ 𝑉<span class="s2">𝑟+1</span>𝑥<span
                            class="s2">3 </span>+ 𝑉<span class="s2">𝑟+1</span>𝑥<span class="s2">4 </span>+ ⋯ +
                        𝑉<span class="s2">𝑟+1</span>𝑥<span class="s2">𝑛</span><span class="s40">1 </span>+ 𝑉<span
                            class="s2">𝑟+1</span>𝑥<span class="s2">𝑛 </span>= ∑ 𝑉<span class="s2">𝑟+1</span>𝑥<span
                            class="s2">𝑖</span>.</p>
                    <p class="s3" style="padding-left: 36pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 1 2 3
                        4</p>
                    <p class="s3" style="padding-left: 36pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1 𝑛
                    </p>
                    <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑖
                    </p>
                    <p class="s3" style="padding-top: 2pt;text-indent: 0pt;text-align: center;">𝑖=0</p>
                    <p class="s17" style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">
                        Hence, theorem is proved.</p>
                </li>
            </ol>
        </li>
        <li data-list-text="3.">
            <h2 style="padding-top: 14pt;padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: justify;">
                Binomial Expansion equal to the Sum of Geometric Series</h2>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Binomial expansion denotes a series of
                binomial coefficients. In this section, we focus on the summation of multiple binomial expansions or
                summation of multiple series of binomial coefficients.</p>
            <p class="s3" style="padding-left: 89pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2 3</p>
            <p class="s4" style="padding-left: 107pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2 3</p>
            <p class="s3" style="padding-left: 47pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛</p>
            <p class="s41" style="padding-left: 65pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 <span
                    class="s2">𝑛+1</span></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝐓𝐡𝐞𝐨𝐫𝐞𝐦
                𝟑. 𝟏: ∑ (<span class="s10">𝑖 </span>) + ∑ (<span class="s10">𝑖 </span>) + ∑ (<span class="s10">𝑖
                </span>) + ∑ (<span class="s10">𝑖 </span>) + ⋯ + ∑ ( <span class="s8">𝑖 </span>) = 2</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">− 1.</p>
            <p class="s3" style="text-indent: 0pt;line-height: 6pt;text-align: right;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;line-height: 6pt;text-align: right;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;line-height: 6pt;text-align: right;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;line-height: 6pt;text-align: right;">𝑖=0</p>
            <p class="s3" style="padding-left: 58pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">This binomial theorem states that the sum of
                multiple summations of series of binomial coefficients [18-22] is equal to the sum of a geometric series
                with exponents of 2.</p>
            <p class="s6" style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Proof<span
                    class="p">. Let us find the value of each binomial expansion in the binomial theorem step by
                    step.</span></p>
            <p class="s3" style="padding-left: 144pt;text-indent: 0pt;line-height: 7pt;text-align: left;">0</p>
            <p class="s11" style="padding-left: 57pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 <span
                    class="s4">0!</span></p>
            <p class="s4" style="padding-left: 57pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 0 <span
                    class="s18">0</span></p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑆𝑡𝑒𝑝 0: ( )
                =</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">= 1 ⟹ ∑ ( ) = (
                ) = 2 .</p>
            <table style="border-collapse:collapse;margin-left:3pt" cellspacing="0">
                <tr style="height:13pt">
                    <td style="width:57pt" colspan="2">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:36pt">
                        <p class="s42" style="padding-left: 2pt;text-indent: 0pt;line-height: 9pt;text-align: center;">
                            𝑖=0</p>
                    </td>
                    <td style="width:200pt" colspan="5">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                </tr>
                <tr style="height:18pt">
                    <td style="width:148pt" colspan="5">
                        <p class="s43"
                            style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 10pt;text-align: left;">
                            1 1 1</p>
                        <p class="s43" style="text-indent: 0pt;line-height: 4pt;text-align: left;">( ) = ( ) + ( ) = 1 +
                            1 =</p>
                    </td>
                    <td style="width:43pt">
                        <p class="s42"
                            style="padding-top: 6pt;padding-left: 4pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                            1</p>
                    </td>
                    <td style="width:67pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:35pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                </tr>
                <tr style="height:20pt">
                    <td style="width:24pt">
                        <p class="s43" style="padding-left: 6pt;text-indent: 0pt;line-height: 12pt;text-align: left;">𝑖
                        </p>
                    </td>
                    <td style="width:33pt">
                        <p class="s43" style="padding-left: 3pt;text-indent: 0pt;line-height: 12pt;text-align: center;">
                            0</p>
                    </td>
                    <td style="width:36pt">
                        <p class="s43"
                            style="padding-left: 2pt;padding-right: 2pt;text-indent: 0pt;line-height: 13pt;text-align: center;">
                            1</p>
                    </td>
                    <td style="width:31pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:24pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:43pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:67pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:35pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                </tr>
                <tr style="height:21pt">
                    <td style="width:293pt" colspan="8">
                        <p class="s43" style="padding-top: 5pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">2 2
                            2 2 <span class="s44">2</span></p>
                    </td>
                </tr>
                <tr style="height:20pt">
                    <td style="width:293pt" colspan="8">
                        <p class="s43" style="padding-left: 36pt;text-indent: 0pt;line-height: 13pt;text-align: left;">0
                            1 2</p>
                    </td>
                </tr>
                <tr style="height:21pt">
                    <td style="width:293pt" colspan="8">
                        <p class="s43"
                            style="padding-top: 5pt;padding-left: 2pt;text-indent: 0pt;line-height: 14pt;text-align: left;">
                            3 3 3 3 3</p>
                    </td>
                </tr>
                <tr style="height:16pt">
                    <td style="width:293pt" colspan="8">
                        <p class="s43" style="padding-left: 39pt;text-indent: 0pt;line-height: 13pt;text-align: left;">0
                            1 2 3</p>
                    </td>
                </tr>
                <tr style="height:11pt">
                    <td style="width:24pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:33pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:36pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:31pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:24pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:43pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:67pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:35pt">
                        <p class="s42" style="padding-right: 1pt;text-indent: 0pt;line-height: 9pt;text-align: right;">
                            𝑛</p>
                    </td>
                </tr>
            </table>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-left: 57pt;text-indent: 0pt;line-height: 13pt;text-align: left;">0 0!</p>
            <p class="s3" style="padding-top: 3pt;padding-left: 56pt;text-indent: 0pt;text-align: left;">1</p>
            <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑆𝑡𝑒𝑝 1: ∑
            </p>
            <p class="s3"
                style="padding-top: 6pt;padding-left: 54pt;text-indent: -2pt;line-height: 73%;text-align: left;">𝑖=0 2
            </p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">𝑖 0</p>
            <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s4" style="padding-left: 57pt;text-indent: 0pt;text-align: left;">2 .</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 85%;text-align: center;">
                𝑆𝑡𝑒𝑝 2: ∑ (<span class="s10">𝑖 </span>) = (</p>
            <p class="s3" style="padding-left: 49pt;text-indent: 0pt;line-height: 71%;text-align: center;">𝑖=0 3</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) = 1 + 2 + 1 =
                4 = 2 .</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 85%;text-align: center;">
                𝑆𝑡𝑒𝑝 3: ∑ (<span class="s10">𝑖 </span>) = (</p>
            <p class="s3" style="padding-left: 4pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑖=0</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) = 1 + 3 + 3 +
                1 = 8.</p>
            <p class="s4"
                style="padding-top: 10pt;padding-left: 5pt;text-indent: 0pt;line-height: 3pt;text-align: left;">
                Similarly, we can continue the expressions up to &quot;<span class="s45">step n</span></p>
            <p class="s4"
                style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 10pt;text-align: left;">𝑛 <span
                    class="s46">𝑛</span></p>
            <p class="s4" style="padding-left: 291pt;text-indent: 0pt;line-height: 81%;text-align: left;">&quot; such
                that ∑ ( <span class="s8">𝑖 </span>) = 2 .</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=0</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">Now, by adding these
                expressions on both sides, it appears as follows:</p>
            <p class="s3" style="padding-left: 88pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2 3</p>
            <p class="s4" style="padding-left: 106pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2 3</p>
            <p class="s3" style="padding-left: 47pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛</p>
            <p class="s4" style="padding-left: 65pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 <span
                    class="s46">𝑖</span></p>
            <p class="s4" style="text-indent: 0pt;line-height: 81%;text-align: center;">∑ ( <span class="s10">𝑖
                </span>) + ∑ ( <span class="s10">𝑖 </span>) + ∑ (<span class="s10">𝑖 </span>) + ∑ (<span
                    class="s10">𝑖 </span>) + ⋯ + ∑ ( <span class="s8">𝑖 </span>) = ∑ 2 ,</p>
            <p class="s3" style="padding-left: 48pt;text-indent: 0pt;line-height: 6pt;text-align: center;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;line-height: 5pt;text-align: center;">𝑛</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s1"
                style="padding-top: 1pt;padding-left: 88pt;text-indent: 0pt;line-height: 34%;text-align: left;">2<span
                    class="s2">𝑛+1 </span>− 1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=0</p>
            <p class="s3" style="padding-left: 35pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">where ∑ 2<span
                    class="s5">𝑖</span> =</p>
            <p class="s3"
                style="padding-top: 4pt;padding-left: 42pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">2 − 1</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">= 2<span
                    class="s5">𝑛+1</span> − 1 is the geometric sereis with exponents of two.</p>
            <p class="s3" style="padding-left: 87pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2 3</p>
            <p class="s4" style="padding-left: 106pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0 1 2 3</p>
            <p class="s3" style="padding-left: 47pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑛</p>
            <p class="s41" style="padding-left: 65pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 <span
                    class="s2">𝑛+1</span></p>
            <p class="s4" style="padding-left: 69pt;text-indent: 0pt;line-height: 81%;text-align: left;">∴ ∑ (<span
                    class="s10">𝑖 </span>) + ∑ (<span class="s10">𝑖 </span>) + ∑ (<span class="s10">𝑖 </span>) + ∑ (
                <span class="s10">𝑖 </span>) + ⋯ + ∑ ( <span class="s8">𝑖 </span>) = 2</p>
            <p class="s4" style="padding-left: 17pt;text-indent: 0pt;line-height: 12pt;text-align: left;">− 1.</p>
            <p class="s3" style="padding-left: 83pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Hence, theorem is proved.
            </p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Some results of Theorem 3.1
                are given below:</p>
            <p class="s3" style="padding-left: 7pt;text-indent: 0pt;line-height: 9pt;text-align: center;">0</p>
            <p class="s4" style="padding-left: 3pt;text-indent: 0pt;line-height: 8pt;text-align: center;">( ) <span
                    class="s47">0</span></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">1 2 3</p>
            <p class="s4" style="padding-left: 23pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1 2 3</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑝−1</p>
            <p class="s4"
                style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑝 − 1
                <span class="s18">𝑝</span></p>
            <p class="s4" style="padding-left: 10pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑎 ∑ (<span
                    class="s10">𝑖 </span>) + ∑ (<span class="s10">𝑖 </span>) + ∑ (<span class="s10">𝑖 </span>) + ∑ (
                <span class="s10">𝑖 </span>) + ⋯ + ∑ (</p>
            <p class="s10" style="padding-left: 9pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑖 <span
                    class="s4">) = 2</span></p>
            <p class="s4" style="padding-left: 6pt;text-indent: 0pt;line-height: 12pt;text-align: left;">− 1, where 1 ≤
                𝑝 ∈ 𝑁.</p>
            <p class="s3" style="padding-left: 7pt;text-indent: 0pt;line-height: 7pt;text-align: center;">𝑖=0</p>
            <p class="s3" style="padding-left: 7pt;text-indent: 0pt;line-height: 8pt;text-align: center;">0</p>
            <p class="s4" style="padding-left: 3pt;text-indent: 0pt;line-height: 8pt;text-align: center;">( ) <span
                    class="s47">0</span></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 9pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1</p>
            <p class="s4" style="text-indent: 0pt;line-height: 8pt;text-align: right;">1</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 9pt;text-indent: 0pt;line-height: 8pt;text-align: left;">2</p>
            <p class="s4" style="text-indent: 0pt;line-height: 8pt;text-align: right;">2</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 9pt;text-indent: 0pt;line-height: 8pt;text-align: left;">3</p>
            <p class="s4" style="text-indent: 0pt;line-height: 8pt;text-align: right;">3</p>
            <p class="s3" style="padding-left: 6pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑝−1</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑞 − 1 <span
                    class="s18">𝑞</span></p>
            <p class="s4" style="padding-left: 10pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑏 ∑ (<span
                    class="s10">𝑖 </span>) + ∑ (<span class="s10">𝑖 </span>) + ∑ (<span class="s10">𝑖 </span>) + ∑ (
                <span class="s10">𝑖 </span>) + ⋯ + ∑ ( <span class="s10">𝑖 </span>) = 2 − 1, where 1 ≤ 𝑞 ∈ 𝑁.</p>
            <p class="s3" style="padding-left: 24pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 24pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 24pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 24pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 24pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p style="padding-top: 11pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">By subtracting (a) from
                (b), we get</p>
            <p class="s48" style="padding-left: 17pt;text-indent: 0pt;line-height: 10pt;text-align: left;">0 1 <span
                    class="s19">𝑞−1</span></p>
            <p class="s20" style="padding-left: 34pt;text-indent: 0pt;line-height: 7pt;text-align: left;">0 1</p>
            <p class="s19"
                style="padding-top: 1pt;padding-left: 69pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 1</p>
            <p class="s20" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑞 − 1 0 1</p>
            <p class="s19" style="padding-left: 17pt;text-indent: 0pt;text-align: left;">𝑝−1</p>
            <p class="s20"
                style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑝 − 1
            </p>
            <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s19" style="padding-left: 17pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑞 𝑝</p>
            <p class="s20" style="padding-left: 5pt;text-indent: 0pt;line-height: 82%;text-align: left;">(∑ ( <span
                    class="s49">𝑖 </span>) + ∑ (<span class="s49">𝑖 </span>) + ⋯ + ∑ ( <span class="s49">𝑖 </span>))
                − (∑ (<span class="s49">𝑖 </span>) + ∑ ( <span class="s49">𝑖 </span>) + ⋯ + ∑ ( <span class="s49">𝑖
                </span>)) = 2 − 2 ,</p>
            <p class="s19" style="padding-left: 13pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 34pt;text-indent: 0pt;text-align: left;">𝑝</p>
            <p class="s19" style="padding-left: 17pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-top: 8pt;text-indent: 0pt;line-height: 10pt;text-align: center;">𝑝</p>
            <p class="s3" style="padding-top: 7pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">𝑝+1</p>
            <p class="s19" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=0</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑝 + 1</p>
            <p class="s3" style="padding-top: 7pt;padding-left: 8pt;text-indent: 0pt;text-align: left;">𝑝+2</p>
            <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑝 + 2</p>
            <p class="s19" style="padding-left: 3pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s19" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;text-align: right;">𝑞−2</p>
            <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s4" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑞 − 2</p>
            <p class="s19" style="padding-left: 17pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 13pt;text-indent: 0pt;text-align: left;">𝑞−1</p>
            <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s4" style="padding-left: 4pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑞 − 1</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 13pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑞 𝑝</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑖. 𝑒. , ∑ (
                <span class="s8">𝑖 </span>) + ∑ ( <span class="s10">𝑖 </span>) + ∑ ( <span class="s10">𝑖 </span>) + ⋯
                + ∑ ( <span class="s10">𝑖 </span>) + ∑ ( <span class="s10">𝑖 </span>) = 2 − 2 ,</p>
            <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=0</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">where p <span
                    class="s4">&lt; 𝑞 &amp; 𝑝, 𝑞 ∈ 𝑁.</span></p>
            <p style="padding-top: 13pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">By adding (a) and (b), we
                get</p>
            <p class="s48" style="padding-left: 17pt;text-indent: 0pt;line-height: 10pt;text-align: left;">0 1 <span
                    class="s19">𝑝−1</span></p>
            <p class="s20" style="padding-left: 34pt;text-indent: 0pt;line-height: 7pt;text-align: left;">0 1</p>
            <p class="s19"
                style="padding-top: 1pt;padding-left: 69pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 1</p>
            <p class="s20" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑝 − 1 0 1</p>
            <p class="s19" style="padding-left: 17pt;text-indent: 0pt;text-align: left;">𝑞−1</p>
            <p class="s20"
                style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑞 − 1
            </p>
            <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s19" style="padding-left: 17pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑝 𝑞</p>
            <p class="s20" style="padding-left: 5pt;text-indent: 0pt;line-height: 82%;text-align: left;">(∑ ( <span
                    class="s49">𝑖 </span>) + ∑ (<span class="s49">𝑖 </span>) + ⋯ + ∑ ( <span class="s49">𝑖 </span>))
                + (∑ (<span class="s49">𝑖 </span>) + ∑ ( <span class="s49">𝑖 </span>) + ⋯ + ∑ ( <span class="s49">𝑖
                </span>)) = 2 + 2 − 2,</p>
            <p class="s19" style="padding-left: 13pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s19" style="padding-left: 13pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s19" style="padding-left: 14pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s19" style="padding-top: 3pt;padding-left: 13pt;text-indent: 0pt;text-align: left;">0</p>
            <p class="s19" style="padding-left: 46pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s19" style="padding-top: 3pt;padding-left: 13pt;text-indent: 0pt;text-align: left;">1</p>
            <p class="s19" style="padding-left: 26pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s19" style="padding-top: 1pt;padding-left: 13pt;text-indent: 0pt;text-align: left;">𝑝−1</p>
            <p class="s19" style="padding-left: 13pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">𝐼𝑓 𝑝 = 𝑞,
            </p>
            <p class="s20" style="padding-left: 75pt;text-indent: 0pt;line-height: 8pt;text-align: left;">0</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑡ℎ𝑒𝑛 2 (∑ ( )
            </p>
            <p class="s20" style="padding-left: 76pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p class="s19" style="padding-left: 53pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s20" style="padding-left: 35pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1</p>
            <p class="s20" style="text-indent: 0pt;line-height: 8pt;text-align: left;">+ <span class="s37">∑ ( )</span>
            </p>
            <p class="s20" style="padding-left: 36pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p class="s19" style="padding-left: 12pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s20" style="padding-top: 5pt;text-indent: 0pt;text-align: left;">+ ⋯ +</p>
            <p class="s4" style="text-indent: 0pt;line-height: 16pt;text-align: left;">∑ (<span class="s50">𝑝 −
                    1</span>)) = 22<span class="s5">𝑝</span> − 2 = 2(2<span class="s5">𝑝</span> − 1),</p>
            <p class="s20" style="padding-left: 35pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p class="s19" style="padding-left: 2pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s20" style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;">𝑖. 𝑒.,</p>
            <p class="s19"
                style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">0 1 2 3
            </p>
            <p class="s20" style="padding-left: 21pt;text-indent: 0pt;line-height: 6pt;text-align: left;">0 1 2 3</p>
            <p class="s19" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑝−1</p>
            <p class="s20"
                style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑝 − 1
                <span class="s51">𝑝</span></p>
            <p class="s20" style="padding-left: 45pt;text-indent: 0pt;line-height: 81%;text-align: left;">∑ ( <span
                    class="s49">𝑖 </span>) + ∑ (<span class="s49">𝑖 </span>) + ∑ (<span class="s49">𝑖 </span>) + ∑ (
                <span class="s49">𝑖 </span>) + ⋯ + ∑ ( <span class="s49">𝑖 </span>) = <span class="s37">2 − 1, where 1
                    ≤ 𝑞 ∈ 𝑁.</span></p>
            <p class="s19" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=0</p>
            <p class="s19" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=0</p>
            <p class="s19" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=0</p>
            <p class="s19" style="padding-left: 30pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s19" style="padding-left: 45pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p style="padding-top: 9pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3"
                style="padding-top: 3pt;padding-left: 91pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑘 𝑘+1
            </p>
            <p class="s4" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑘</p>
            <p class="s4"
                style="padding-top: 11pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘 + 1
            </p>
            <p class="s3" style="padding-top: 3pt;padding-left: 18pt;text-indent: 0pt;text-align: left;">𝑘+2</p>
            <p class="s4"
                style="padding-top: 11pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘 + 2
            </p>
            <p class="s3"
                style="padding-top: 3pt;padding-left: 47pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛</p>
            <p class="s41" style="padding-left: 65pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 <span
                    class="s2">𝑛+1 𝑘</span></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝐓𝐡𝐞𝐨𝐫𝐞𝐦
                𝟑. 𝟐: ∑ ( <span class="s9">𝑖 </span>) + ∑ (</p>
            <p class="s9" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑖 <span
                    class="s4">) + ∑ (</span></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;"><span
                    class="s9">𝑖 </span>) + ⋯ + ∑ ( <span class="s8">𝑖 </span>) = 2</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">− 2 ,</p>
            <p class="s3" style="text-indent: 0pt;line-height: 6pt;text-align: right;">𝑖=0</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">where <span class="s4">𝑘
                    ≤ 𝑛 &amp; 𝑘, 𝑛 ∈ 𝑁.</span></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s6"
                style="padding-top: 13pt;padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Proof.
                <span class="p">The sum of a geometric series with exponents of 2 is given below:</span></p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">𝑛</p>
            <p class="s4" style="padding-top: 4pt;padding-left: 187pt;text-indent: 0pt;text-align: left;">∑ 2<span
                    class="s5">𝑖</span> = 2<span class="s5">𝑛+1</span> − 2<span class="s5">𝑘</span>.</p>
            <p class="s3"
                style="padding-top: 4pt;padding-left: 188pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=𝑘
            </p>
            <p class="s3" style="padding-left: 108pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘 𝑘+1</p>
            <p class="s4" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑘</p>
            <p class="s4"
                style="padding-top: 6pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘 + 1
            </p>
            <p class="s3" style="padding-left: 18pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑘+2</p>
            <p class="s4"
                style="padding-top: 6pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘 + 2
            </p>
            <p class="s3" style="padding-left: 47pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑛 𝑛</p>
            <p class="s4" style="padding-left: 65pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 <span
                    class="s46">𝑖</span></p>
            <p class="s4" style="padding-left: 72pt;text-indent: 0pt;line-height: 81%;text-align: left;">Then, ∑ ( <span
                    class="s9">𝑖 </span>) + ∑ (</p>
            <p class="s9" style="padding-left: 9pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑖 <span
                    class="s4">) + ∑ (</span></p>
            <p class="s4" style="padding-left: 9pt;text-indent: 0pt;line-height: 81%;text-align: left;"><span
                    class="s9">𝑖 </span>) + ⋯ + ∑ ( <span class="s8">𝑖 </span>) = ∑ 2 .</p>
            <p class="s3"
                style="padding-top: 4pt;padding-left: 25pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑘 𝑘+1
            </p>
            <p class="s4" style="padding-left: 7pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑘</p>
            <p class="s3" style="padding-left: 7pt;text-indent: 0pt;line-height: 7pt;text-align: center;">𝑖=0</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑘 + 1
            </p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 6pt;text-align: center;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: center;">𝑘+2</p>
            <p class="s4" style="padding-left: 42pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘 + 2</p>
            <p class="s3" style="text-indent: 0pt;line-height: 6pt;text-align: center;">𝑖=0</p>
            <p class="s3" style="padding-left: 24pt;text-indent: 0pt;line-height: 7pt;text-align: center;">𝑛</p>
            <p class="s4" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑛</p>
            <p style="padding-top: 7pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 25pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑛+1</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="text-indent: 0pt;line-height: 3pt;text-align: right;">𝑘</p>
            <p class="s3" style="padding-left: 25pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=𝑘</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">∴ ∑ ( <span
                    class="s9">𝑖 </span>) + ∑ (</p>
            <p class="s9" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑖 <span
                    class="s4">) + ∑ (</span></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;"><span
                    class="s9">𝑖 </span>) + ⋯ + ∑ ( <span class="s8">𝑖 </span>) = 2</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">− 2 .</p>
            <p class="s3" style="padding-left: 21pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 21pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 21pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 21pt;text-indent: 0pt;line-height: 5pt;text-align: left;">𝑖=0</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Hence, theorem is proved.
            </p>
            <p style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Some results of Theorem 3.2
                are given below:</p>
            <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">(i) ∑ (<span
                    class="s52">𝑛</span>) = 2<span class="s5">𝑛+1</span> − 2<span class="s5">𝑛</span> = 2<span
                    class="s5">𝑛</span>. (</p>
            <p class="s3" style="padding-left: 11pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
            <p class="s4"
                style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 3pt;text-align: left;">)</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑛</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 − 1 <span
                    class="s12">𝑛</span></p>
            <p class="s5"
                style="padding-top: 13pt;padding-left: 5pt;text-indent: 0pt;line-height: 2pt;text-align: left;">
                𝑛−1<span class="s4">(2</span>2<span class="s4"> − 1) = 3(2</span>𝑛−1<span class="s4">).</span></p>
            <p class="s4" style="padding-top: 4pt;text-indent: 0pt;line-height: 13pt;text-align: right;">𝑖</p>
            <p class="s3" style="padding-left: 23pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 28pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛−2</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 23pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
            <p class="s4" style="padding-left: 23pt;text-indent: 0pt;line-height: 12pt;text-align: left;">𝑖𝑖</p>
            <p class="s3"
                style="padding-top: 11pt;padding-left: 27pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛</p>
            <p class="s4" style="padding-left: 3pt;text-indent: 0pt;line-height: 12pt;text-align: left;">∑ (</p>
            <p class="s3" style="padding-top: 4pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-left: 9pt;text-indent: 0pt;line-height: 81%;text-align: left;"><span
                    class="s10">𝑖 </span>) + ∑ ( <span class="s8">𝑖 </span>) = 2</p>
            <p class="s3" style="padding-left: 46pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">(𝑖𝑖𝑖) ∑
                (<span class="s47">𝑛 − 2</span>) + ∑ (<span class="s47">𝑛 − 1</span>) + ∑ (<span
                    class="s52">𝑛</span>) = 2<span class="s5">𝑛+1</span> − 2<span class="s5">𝑛−2</span> = 2<span
                    class="s5">𝑛−2</span>(2<span class="s5">3</span> − 1) = 7(2<span class="s5">𝑛−2</span>).</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 27pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−3</p>
            <p class="s4"
                style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 3pt;text-align: left;">( )</p>
            <p class="s4" style="padding-left: 4pt;text-indent: 0pt;line-height: 13pt;text-align: center;">𝑖</p>
            <p class="s4"
                style="padding-top: 12pt;padding-left: 3pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑛 − 3
            </p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 7pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−2</p>
            <p class="s4" style="padding-left: 4pt;text-indent: 0pt;line-height: 13pt;text-align: center;">𝑖</p>
            <p class="s4"
                style="padding-top: 12pt;padding-left: 3pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑛 − 2
            </p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 6pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
            <p class="s4" style="padding-left: 6pt;text-indent: 0pt;line-height: 12pt;text-align: left;">𝑖</p>
            <p class="s3" style="padding-top: 5pt;text-indent: 0pt;line-height: 9pt;text-align: right;">𝑛</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 − 1 <span
                    class="s12">𝑛</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑛+1</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑛−3</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑛−3</p>
            <p class="s4" style="padding-left: 10pt;text-indent: 0pt;line-height: 12pt;text-align: left;">𝑖𝑣</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">∑ (</p>
            <p class="s3"
                style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s10" style="padding-left: 9pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑖 <span
                    class="s4">) + ∑ (</span></p>
            <p class="s3" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=0</p>
            <p class="s10" style="padding-left: 10pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑖 <span
                    class="s4">) + ∑ (</span></p>
            <p class="s3" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=0</p>
            <p class="s4" style="padding-left: 9pt;text-indent: 0pt;line-height: 81%;text-align: left;"><span
                    class="s10">𝑖 </span>) + ∑ ( <span class="s8">𝑖 </span>) = 2 − 2</p>
            <p class="s3" style="padding-left: 46pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-left: 10pt;text-indent: 0pt;line-height: 12pt;text-align: left;">= 15(2 ).</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">These results can be
                generalized as follows:</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑝 𝑝+1</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑝+2</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑞−1 𝑞</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 15pt;text-align: left;">∑ (<span
                    class="s27">𝑝</span>) + ∑ (<span class="s47">𝑝 + 1</span>) + ∑ (<span class="s47">𝑝 + 2</span>) +
                ⋯ + ∑ (<span class="s47">𝑞 − 1</span>) + ∑ (<span class="s27">𝑞</span>) = 2<span
                    class="s5">𝑝</span>(2<span class="s5">𝑞−𝑝+1</span> − 1),</p>
            <p class="s4" style="text-indent: 0pt;line-height: 11pt;text-align: right;">𝑖</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="text-indent: 0pt;line-height: 12pt;text-align: right;">𝑖</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="text-indent: 0pt;line-height: 12pt;text-align: right;">𝑖</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="text-indent: 0pt;line-height: 12pt;text-align: right;">𝑖</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-left: 30pt;text-indent: 0pt;line-height: 11pt;text-align: left;">𝑖</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">where 0 ≤ 𝑝 ≤
                𝑞 𝑎𝑛𝑑 𝑝, 𝑞 ∈ 𝑁.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3"
                style="padding-top: 3pt;padding-left: 91pt;text-indent: 0pt;line-height: 9pt;text-align: left;">1 2 3
            </p>
            <p class="s4" style="padding-left: 116pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1 2 3</p>
            <p class="s3" style="padding-top: 3pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑛</p>
            <p class="s4" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑛 <span class="s10">(</span></p>
            <p style="padding-top: 7pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s1" style="padding-left: 26pt;text-indent: 0pt;line-height: 3pt;text-align: left;">) <span
                    class="s2">𝑛</span></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝐓𝐡𝐞𝐨𝐫𝐞𝐦
                𝟑. 𝟑: ∑ 𝑖 (<span class="s10">𝑖 </span>) + ∑ 𝑖 (<span class="s10">𝑖 </span>) + ∑ 𝑖 ( <span
                    class="s10">𝑖 </span>) + ⋯ + ∑ 𝑖 ( <span class="s8">𝑖 </span>) = 𝑛 − 1 2 + 1.</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑖=1</p>
            <p class="s3" style="padding-left: 64pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=1</p>
            <p class="s6"
                style="padding-top: 11pt;padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">
                Proof<span class="p">. Let us find the value of each binomial expansion in the binomial theorem step by
                    step.</span></p>
            <p class="s3" style="text-indent: 0pt;line-height: 7pt;text-align: center;">1</p>
            <p class="s53" style="padding-left: 63pt;text-indent: 0pt;line-height: 11pt;text-align: left;">1 1 <span
                    class="s4">1!</span></p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑆𝑡𝑒𝑝 1: 1 (
                ) = ( ) =</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 16pt;text-align: left;">= 1 ⟹ ∑ 𝑖
                (<span class="s47">1</span>) = 1 = 1 × 2<span class="s5">0</span>, (0! = 1).</p>
            <table style="border-collapse:collapse;margin-left:3pt" cellspacing="0">
                <tr style="height:13pt">
                    <td style="width:103pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                    <td style="width:25pt">
                        <p class="s42" style="padding-left: 9pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                            𝑖=1</p>
                    </td>
                    <td style="width:203pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                </tr>
                <tr style="height:18pt">
                    <td style="width:302pt" colspan="3">
                        <p class="s43" style="padding-top: 1pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">2 2
                            2 <span class="s44">1</span></p>
                    </td>
                    <td style="width:29pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                </tr>
                <tr style="height:20pt">
                    <td style="width:302pt" colspan="3">
                        <p class="s43" style="padding-left: 45pt;text-indent: 0pt;line-height: 12pt;text-align: left;">1
                            2</p>
                    </td>
                    <td style="width:29pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                </tr>
                <tr style="height:21pt">
                    <td style="width:302pt" colspan="3">
                        <p class="s43" style="padding-top: 5pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">2 3
                            3 3 <span class="s44">1</span></p>
                    </td>
                    <td style="width:29pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                </tr>
                <tr style="height:20pt">
                    <td style="width:302pt" colspan="3">
                        <p class="s43" style="padding-left: 45pt;text-indent: 0pt;line-height: 13pt;text-align: left;">1
                            2 3</p>
                    </td>
                    <td style="width:29pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                </tr>
                <tr style="height:21pt">
                    <td style="width:302pt" colspan="3">
                        <p class="s43" style="padding-top: 4pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">2 4
                            4 4 4</p>
                    </td>
                    <td style="width:29pt">
                        <p class="s43"
                            style="padding-top: 11pt;padding-left: 3pt;text-indent: 0pt;line-height: 8pt;text-align: left;">
                            × 2<span class="s54">3</span>.</p>
                    </td>
                </tr>
                <tr style="height:13pt">
                    <td style="width:302pt" colspan="3">
                        <p class="s43" style="padding-left: 45pt;text-indent: 0pt;line-height: 12pt;text-align: left;">1
                            2 3 4</p>
                    </td>
                    <td style="width:29pt">
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    </td>
                </tr>
            </table>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-left: 63pt;text-indent: 0pt;line-height: 13pt;text-align: left;">1 1 1! 0! 𝑖
            </p>
            <p class="s3" style="padding-top: 3pt;padding-left: 56pt;text-indent: 0pt;text-align: left;">2</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 85%;text-align: left;">𝑆𝑡𝑒𝑝
                2: ∑ 𝑖 ( <span class="s10">𝑖 </span>) = 1 (</p>
            <p class="s3" style="padding-left: 51pt;text-indent: 0pt;line-height: 72%;text-align: center;">𝑖=1 3</p>
            <p class="s4" style="padding-top: 4pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + 2 (</p>
            <p class="s4" style="padding-top: 4pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) = 2 + 2 = 4 =
                2 × 2 .</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 85%;text-align: left;">𝑆𝑡𝑒𝑝
                3: ∑ 𝑖 ( <span class="s10">𝑖 </span>) = 1 (</p>
            <p class="s3" style="padding-left: 51pt;text-indent: 0pt;line-height: 71%;text-align: center;">𝑖=1 4</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + 2 (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + 3 (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) = 3 + 6 + 3 =
                12 = 2 × 2 .</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 85%;text-align: left;">𝑆𝑡𝑒𝑝
                4: ∑ 𝑖 ( <span class="s10">𝑖 </span>) = 1 (</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">𝑖=1</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + 2 (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + 3 (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) + 4 (</p>
            <p class="s4" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">) = 4 + 12 + 12
                + 4 = 4</p>
            <p class="s3" style="padding-top: 10pt;text-indent: 0pt;line-height: 9pt;text-align: center;">𝑛</p>
            <p class="s4"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 3pt;text-align: left;">
                Similarly, we can continue the expressions up to &quot;<span class="s45">step n</span></p>
            <p class="s41" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 <span
                    class="s2">𝑛−1</span><span class="s1">.</span></p>
            <p class="s4" style="padding-left: 245pt;text-indent: 0pt;line-height: 81%;text-align: center;">&quot; such
                that ∑ 𝑖 ( <span class="s8">𝑖 </span>) = 𝑛2</p>
            <p class="s3" style="padding-left: 247pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑖=1</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">Now, by adding these
                expressions on both sides, it appears as follows:</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1 2 3 4</p>
            <p class="s4" style="padding-left: 35pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1 2 3 2</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛</p>
            <p class="s4" style="padding-left: 2pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑛</p>
            <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑖−1</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">∑ 𝑖 (<span
                    class="s10">𝑖 </span>) + ∑ 𝑖 (<span class="s10">𝑖 </span>) + ∑ 𝑖 ( <span class="s10">𝑖 </span>)
                + ∑ 𝑖 (<span class="s10">𝑖 </span>) + ⋯ + ∑ 𝑖 ( <span class="s8">𝑖 </span>) = ∑ 𝑖 × 2 .</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 22pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=1</p>
            <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">where ∑ 𝑖 ×
                2<span class="s5">𝑖</span> = (𝑛 − 1)2<span class="s5">𝑛</span> + 1 .</p>
            <p class="s3"
                style="padding-top: 4pt;padding-left: 40pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 20pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1 2 3 4</p>
            <p class="s4" style="padding-left: 45pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1 2 3 2</p>
            <p class="s3" style="padding-left: 20pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑛</p>
            <p class="s4" style="padding-left: 45pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 <span
                    class="s10">(</span></p>
            <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s1" style="padding-left: 20pt;text-indent: 0pt;line-height: 3pt;text-align: left;">) <span
                    class="s2">𝑛</span></p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">∴ ∑ 𝑖 (<span
                    class="s10">𝑖 </span>) + ∑ 𝑖 (<span class="s10">𝑖 </span>) + ∑ 𝑖 ( <span class="s10">𝑖 </span>)
                + ∑ 𝑖 (<span class="s10">𝑖 </span>) + ⋯ + ∑ 𝑖 ( <span class="s8">𝑖 </span>) = 𝑛 − 1 2 + 1.</p>
            <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Hence, theorem is proved.
            </p>
            <p style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Some results of Theorem 3.3
                are given below:</p>
            <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 9pt;text-align: left;">1 2 3</p>
            <p class="s4" style="padding-left: 39pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1 2 3</p>
            <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑘 𝑘+1</p>
            <p class="s4" style="padding-left: 2pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑘</p>
            <p class="s4"
                style="padding-top: 7pt;padding-left: 12pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘 + 1
            </p>
            <p class="s3" style="padding-left: 15pt;text-indent: 0pt;line-height: 10pt;text-align: left;">𝑛+1</p>
            <p class="s4"
                style="padding-top: 7pt;padding-left: 12pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 + 1
            </p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">{∑ 𝑖 ( <span
                    class="s10">𝑖 </span>) + ∑ 𝑖 (<span class="s10">𝑖 </span>) + ∑ 𝑖 (<span class="s10">𝑖 </span>)
                + ⋯ + ∑ 𝑖 ( <span class="s9">𝑖 </span>) + ∑ 𝑖 (</p>
            <p class="s9" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑖 <span
                    class="s4">) + ⋯ + ∑ 𝑖 (</span></p>
            <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">𝑖 <span
                    class="s4">)}</span></p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 42pt;text-indent: 0pt;line-height: 9pt;text-align: left;">1</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 42pt;text-indent: 0pt;line-height: 8pt;text-align: left;">2</p>
            <p class="s4" style="padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: left;">1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 6pt;text-align: right;">𝑖=1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 8pt;text-align: right;">3</p>
            <p class="s4" style="padding-left: 10pt;text-indent: 0pt;line-height: 8pt;text-align: left;">2 3</p>
            <p class="s3" style="text-indent: 0pt;line-height: 6pt;text-align: center;">𝑖=1</p>
            <p class="s3" style="padding-left: 13pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑘</p>
            <p class="s4" style="text-indent: 0pt;line-height: 8pt;text-align: right;">𝑘</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=1</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑛+1</p>
            <p style="padding-top: 7pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑘+1</p>
            <p class="s4" style="padding-left: 77pt;text-indent: 0pt;line-height: 81%;text-align: left;">− {∑ 𝑖 ( <span
                    class="s10">𝑖 </span>) + ∑ 𝑖 (<span class="s10">𝑖 </span>) + ∑ 𝑖 (<span class="s10">𝑖 </span>)
                + ⋯ + ∑ 𝑖 ( <span class="s9">𝑖 </span>)} = 𝑛2</p>
            <p class="s4" style="padding-left: 17pt;text-indent: 0pt;line-height: 12pt;text-align: left;">− 𝑘2</p>
            <p class="s3" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 7pt;text-align: right;">𝑖=1</p>
            <p class="s3" style="padding-left: 64pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=1</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-top: 3pt;padding-left: 22pt;text-indent: 0pt;text-align: left;">𝑘+1</p>
            <p class="s4"
                style="padding-top: 11pt;padding-left: 11pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘 + 1
            </p>
            <p class="s3" style="padding-top: 3pt;padding-left: 18pt;text-indent: 0pt;text-align: left;">𝑘+2</p>
            <p class="s4"
                style="padding-top: 11pt;padding-left: 12pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘 + 2
            </p>
            <p class="s3"
                style="padding-top: 3pt;padding-left: 22pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛 𝑛+1
            </p>
            <p class="s4" style="padding-left: 10pt;text-indent: 0pt;line-height: 8pt;text-align: center;">𝑛</p>
            <p class="s4"
                style="padding-top: 11pt;padding-left: 12pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛 + 1
            </p>
            <p style="padding-top: 7pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 22pt;text-indent: 0pt;line-height: 3pt;text-align: left;">𝑛 𝑘</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 81%;text-align: left;">⟹ ∑ 𝑖 ( <span
                    class="s9">𝑖 </span>) + ∑ 𝑖 ( <span class="s9">𝑖 </span>) + ⋯ + ∑ 𝑖 ( <span class="s8">𝑖
                </span>) + ∑ 𝑖 ( <span class="s10">𝑖 </span>) = 2(𝑛2 − 𝑘2 ) and</p>
            <p class="s3" style="padding-left: 23pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑘</p>
            <p class="s3"
                style="padding-top: 4pt;padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑘+1</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=1</p>
            <p class="s3"
                style="padding-top: 4pt;padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 23pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑖=1</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑛</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">∑ 𝑖 (<span
                    class="s47">𝑘</span>) + ∑ 𝑖 (<span class="s47">𝑘 + 1</span>) + ⋯ + ∑ 𝑖 (<span class="s47">𝑛 −
                    1</span>) + ∑ 𝑖 (<span class="s52">𝑛</span>) = 2{(𝑛 − 1)2<span class="s5">𝑛−1</span> − (𝑘 −
                1)2<span class="s5">𝑘−1</span>},</p>
            <p class="s4" style="text-indent: 0pt;line-height: 12pt;text-align: right;">𝑖</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=1</p>
            <p class="s4" style="text-indent: 0pt;line-height: 12pt;text-align: right;">𝑖</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=1</p>
            <p class="s4" style="text-indent: 0pt;line-height: 12pt;text-align: right;">𝑖</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=1</p>
            <p class="s4" style="padding-left: 36pt;text-indent: 0pt;line-height: 11pt;text-align: left;">𝑖</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖=1</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">where 𝑘 &lt;
                𝑛 &amp; 𝑘, 𝑛 ∈ 𝑁.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-top: 3pt;padding-left: 135pt;text-indent: 0pt;text-align: left;">𝑛−1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"><span
                    style=" color: #212121;">𝐓𝐡𝐞𝐨𝐫𝐞𝐦 𝟑. 𝟒: (𝑝 + 1) ∫ </span>∑ 𝑉<span
                    class="s5">𝑝+1</span>𝑥<span class="s5">𝑖</span></p>
            <p class="s3" style="padding-top: 4pt;padding-left: 136pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-top: 3pt;padding-left: 106pt;text-indent: 0pt;text-align: left;">𝑛−1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">𝑑𝑥 + 𝐶 = (𝑝
                + 1) <span style=" color: #212121;">∫ </span>∑ 𝑉<span class="s5">𝑝+1</span>𝑥<span
                    class="s5">𝑖</span></p>
            <p class="s3" style="padding-top: 4pt;padding-left: 107pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-top: 3pt;text-indent: 0pt;text-align: center;">𝑛</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">𝑑𝑥 + 1 = ∑
                𝑉<span class="s5">𝑝</span>𝑥<span class="s5">𝑖</span> ,</p>
            <p class="s3" style="padding-top: 4pt;text-indent: 0pt;text-align: center;">𝑖=0</p>
            <p class="s56" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">where C is the constant of
                Integration and C =1 because 1 is the first term of geometric series.</p>
            <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s56" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Proof. Let us prove the theorem
                on integral calculus using the following binomial expansions.</p>
            <p class="s3" style="padding-left: 10pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑛</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p style="text-indent: 0pt;text-align: left;" />
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s35" style="padding-left: 5pt;text-indent: 0pt;line-height: 52%;text-align: left;">∑ 𝑉<span
                    class="s46">𝑝</span>𝑥<span class="s26">𝑖 </span>= 1 + <span class="s4">(𝑝 + 1) </span>𝑥 + <span
                    class="s4">(𝑝 + 1)(𝑝 + 2) </span>𝑥<span class="s26">2 </span>+ ⋯ + <span class="s4">(𝑛 + 1)(𝑛 +
                    2) … (𝑛 + 𝑝) </span>𝑥<span class="s26">𝑛 </span>.</p>
            <p class="s5" style="padding-left: 28pt;text-indent: 0pt;line-height: 11pt;text-align: left;">𝑖<span
                    class="s4"> 1! 2!</span></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑛−1</p>
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">𝑝!</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p style="text-indent: 0pt;text-align: left;" />
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s35" style="padding-left: 5pt;text-indent: 0pt;line-height: 52%;text-align: left;">∑ 𝑉<span
                    class="s46">𝑝+1</span>𝑥<span class="s26">𝑖 </span>= 1 + <span class="s4">(𝑝 + 2) </span>𝑥 +
                <span class="s4">(𝑝 + 2)(𝑝 + 3) </span>𝑥<span class="s26">2 </span>+ ⋯ + <span class="s4">𝑛(𝑛 +
                    1)(𝑛 + 2) … (𝑛 + 𝑝) </span>𝑥<span class="s26">𝑛−1</span>.</p>
            <p class="s3" style="padding-left: 29pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖</p>
            <p class="s3" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-left: 6pt;text-indent: 0pt;line-height: 13pt;text-align: left;">1! 2!</p>
            <p class="s4" style="padding-left: 6pt;text-indent: 0pt;line-height: 13pt;text-align: left;">(𝑝 + 1)!</p>
            <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;">Let’s prove that the
                integration (left-hand side of the theorem) is equal to the binomial series (right- hand side of the
                theorem).</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-top: 3pt;padding-left: 16pt;text-indent: 0pt;text-align: left;">𝑛−1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"><span
                    style=" color: #212121;">∫ </span>∑ 𝑉<span class="s5">𝑝+1</span>𝑥<span class="s5">𝑖</span></p>
            <p class="s3"
                style="padding-top: 4pt;padding-left: 17pt;text-indent: 0pt;line-height: 10pt;text-align: left;">𝑖=0
            </p>
            <p class="s3" style="padding-left: 56pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑛−1</p>
            <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s4" style="text-indent: 0pt;text-align: left;">𝑑𝑥 = 𝑥 +</p>
            <p class="s55" style="padding-top: 9pt;padding-bottom: 2pt;text-indent: 0pt;text-align: right;">(𝑝 + 2)
                𝑥<span class="s57">2</span></p>
            <p style="text-indent: 0pt;line-height: 1pt;text-align: left;" />
            <p class="s55" style="text-indent: 0pt;text-align: right;">1! 2</p>
            <p class="s55"
                style="padding-top: 8pt;padding-left: 12pt;text-indent: 0pt;line-height: 12pt;text-align: left;">(𝑝 +
                2)(𝑝 + 3) 𝑥<span class="s57">3</span></p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s55" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">+</p>
            <p class="s55" style="padding-left: 45pt;text-indent: 0pt;line-height: 11pt;text-align: left;">2! 3</p>
            <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s55" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">+ ⋯ +</p>
            <p class="s55" style="padding-top: 8pt;padding-bottom: 2pt;text-indent: 0pt;text-align: right;">𝑛(𝑛 + 1) …
                (𝑛 + 𝑝) 𝑥<span class="s57">𝑛</span></p>
            <p style="text-indent: 0pt;line-height: 1pt;text-align: left;" />
            <p class="s55" style="text-indent: 0pt;text-align: right;">(𝑝 + 1)! 𝑛</p>
            <p style="padding-top: 4pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s55" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">+ 𝐶.</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p style="text-indent: 0pt;text-align: left;" />
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s59" style="padding-left: 5pt;text-indent: 0pt;line-height: 52%;text-align: left;"><span
                    class="s58">(</span>𝑝 + 1<span class="s58">) </span>∫ <span style=" color: #000;">∑ 𝑉</span><span
                    class="s46">𝑝+1</span><span style=" color: #000;">𝑥</span><span class="s26">𝑖 </span><span
                    style=" color: #000;">𝑑𝑥 = 1 + </span><span class="s4">(𝑝 + 1) </span>𝑥 + <span class="s55">(𝑝
                    + 1)(𝑝 + 2) </span>𝑥<span class="s60">2 </span>+ <span class="s55">(𝑝 + 1)(𝑝 + 2)(𝑝 + 3)
                </span>𝑥<span class="s60">3</span></p>
            <p class="s3" style="padding-left: 81pt;text-indent: 0pt;line-height: 8pt;text-align: left;">𝑖</p>
            <p class="s3" style="padding-top: 1pt;padding-left: 57pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-left: 57pt;text-indent: 0pt;line-height: 13pt;text-align: left;">1! <span
                    style=" color: #212121;">2! 3!</span></p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s35" style="padding-left: 77pt;text-indent: 0pt;line-height: 74%;text-align: left;"><span
                    style=" color: #212121;">+ ⋯ + </span><span class="s4">(𝑛 + 1)(𝑛 + 2) … (𝑛 + 𝑝) </span>𝑥<span
                    class="s26">𝑛</span>, where 𝐶 = 1.</p>
            <p class="s4" style="text-indent: 0pt;line-height: 11pt;text-align: center;">𝑝!</p>
            <p class="s3" style="padding-left: 103pt;text-indent: 0pt;text-align: left;">𝑛−1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 52pt;text-indent: 0pt;text-align: left;"><span
                    style=" color: #212121;">(𝑝 + 1) ∫ </span>∑ 𝑉<span class="s5">𝑝+1</span>𝑥<span
                    class="s5">𝑖</span></p>
            <p class="s3" style="padding-top: 4pt;padding-left: 104pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Hence, theorem is proved.</p>
            <p class="s3" style="padding-left: 106pt;text-indent: 0pt;text-align: left;">𝑛−1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">𝑑𝑥 + 𝐶 = (𝑝
                + 1) <span style=" color: #212121;">∫ </span>∑ 𝑉<span class="s5">𝑝+1</span>𝑥<span
                    class="s5">𝑖</span></p>
            <p class="s3" style="padding-top: 4pt;padding-left: 107pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="text-indent: 0pt;text-align: center;">𝑛</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">𝑑𝑥 + 1 = ∑
                𝑉<span class="s5">𝑝</span>𝑥<span class="s5">𝑖</span> .</p>
            <p class="s3" style="padding-top: 4pt;text-indent: 0pt;text-align: center;">𝑖=0</p>
            <p style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Some results of Theorem 3.4
                are given below:</p>
            <p class="s3" style="padding-top: 1pt;padding-left: 140pt;text-indent: 0pt;text-align: left;">𝑛−1</p>
            <p style="padding-top: 8pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">𝑛−1</p>
            <p style="padding-top: 8pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 7pt;text-align: left;">𝑛</p>
            <p class="s61" style="padding-left: 46pt;text-indent: 0pt;line-height: 6pt;text-align: left;">𝑥<span
                    class="s62">𝑛+1 </span>− 1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;text-align: left;"><span
                    style=" color: #212121;">Let 𝑝 = 0. Then (𝑝 + 1) ∫ </span>∑ 𝑉<span class="s5">𝑝+1</span>𝑥<span
                    class="s5">𝑖</span></p>
            <p class="s3" style="padding-top: 4pt;text-indent: 0pt;text-align: right;">𝑖=0</p>
            <p class="s4" style="padding-top: 1pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">𝑑𝑥 + 1 = <span
                    style=" color: #212121;">∫ </span>∑ 𝑉<span class="s5">1</span>𝑥<span class="s5">𝑖</span></p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s3" style="padding-top: 4pt;padding-left: 65pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s4" style="padding-top: 1pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">𝑑𝑥 + 1 = ∑
                𝑥<span class="s5">𝑖</span> <span style=" color: #212121;">=</span></p>
            <p class="s3" style="padding-top: 4pt;padding-left: 54pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s55"
                style="padding-top: 1pt;padding-left: 15pt;text-indent: 0pt;line-height: 11pt;text-align: center;">.</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s55" style="text-indent: 0pt;line-height: 11pt;text-align: center;">𝑥 − 1</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s3" style="padding-left: 109pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"><span
                    style=" color: #212121;">Let 𝑝 = 1. Then 2 ∫ </span>∑ 𝑉<span class="s5">2</span>𝑥<span
                    class="s5">𝑖</span></p>
            <p class="s3" style="padding-top: 4pt;padding-left: 110pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 57pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">𝑑𝑥 + 1 = ∑
                𝑉<span class="s5">1</span>𝑥<span class="s5">𝑖</span></p>
            <p class="s3" style="padding-top: 4pt;padding-left: 53pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s3" style="padding-left: 22pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑛−1</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 4pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">⟹ ∑ 𝑉<span
                    class="s5">1</span>𝑥<span class="s5">𝑖</span> =</p>
            <p class="s3" style="padding-top: 4pt;padding-left: 23pt;text-indent: 0pt;text-align: left;">𝑖=0</p>
            <p class="s4"
                style="padding-top: 6pt;padding-left: 30pt;text-indent: -26pt;line-height: 78%;text-align: left;">(𝑟𝑥
                − 𝑟 − 1)𝑥<span class="s5">𝑟</span> + 1 (𝑥 − 1)<span class="s5">2</span> <span class="s27">,</span>
            </p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;">which is the first
                derivative of geometric series. More details about the first derivative of geometric series are given in
                Section 2.1.</p>
            <p style="padding-top: 2pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s56" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">In general, the integration of
                summation of geometric series is constituted as follows:</p>
            <p class="s3" style="padding-top: 1pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">𝑛−1 𝑛 𝑛</p>
            <p class="s4"
                style="padding-top: 4pt;padding-left: 10pt;text-indent: 0pt;line-height: 9pt;text-align: center;"><span
                    style=" color: #212121;">(𝑝 + 1) ∫ </span>∑ 𝑉<span class="s5">𝑝+1</span>𝑥<span
                    class="s5">𝑖</span> 𝑑𝑥 + 𝐶 = ∑ 𝑉<span class="s5">𝑝</span> 𝑥<span class="s5">𝑖</span> +
                𝑉<span class="s5">𝑝</span> 𝑥<span class="s5">𝑖</span> = ∑ 𝑉<span class="s5">𝑝</span> 𝑥<span
                    class="s5">𝑖</span> ,</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="text-indent: 0pt;line-height: 10pt;text-align: right;">𝑖=𝑘</p>
            <p class="s3" style="padding-left: 7pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−𝑘</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="text-indent: 0pt;line-height: 10pt;text-align: right;">𝑖=𝑘+1</p>
            <p class="s3" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−(𝑘+1)</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: right;">𝑖−𝑘</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-left: 25pt;text-indent: 0pt;line-height: 10pt;text-align: left;">𝑖=𝑘</p>
            <p class="s3" style="padding-left: 6pt;text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−𝑘</p>
            <p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: left;">𝑖−𝑘</p>
            <p style="text-indent: 0pt;text-align: left;" />
            <p class="s4" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">where the
                integral constant 𝑖𝑠 𝐶 = 𝑉<span class="s5">𝑝</span> 𝑥<span class="s5">𝑖</span> because it is the
                first term of the series.</p>
            <p style="padding-top: 7pt;text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="4.">
            <h2 style="padding-left: 23pt;text-indent: -18pt;line-height: 14pt;text-align: justify;">Conclusion</h2>
        </li>
    </ol>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">In this article, the n<span class="s63">th</span>
        derivative [22-33] of geometric series has been introduced and its applications used in combinatorics including
        binomial expansions. Also, computation of the summation of series of binomial expansions and geometric series
        were derived in an innovative way. Theorems and relations between the binomial expansions and geometric series
        have been developed for researchers, who are working in science, economics, engineering, and management,</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">References</h2>
    <ol id="l4">
        <li data-list-text="[1]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Sum of the
                Summations of Binomial Expansions with Geometric Series. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53</span>.</p>
        </li>
        <li data-list-text="[2]">
            <p style="padding-top: 13pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022)
                Computation and combinatorial Techniques for Binomial Coefficients and Geometric Series. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v4"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v4.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[3]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Combinatorial
                Geometric Series. <i>OSF Preprints</i><a href="https://www.doi.org/10.31219/osf.io/fumzn"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">. </a><span class="s64">https://www.doi.org/10.31219/osf.io/fumzn</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[4]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2010) Application of
                Exponential Decay and Geometric Series in Effective Medicine. <i>Advances in Bioscience and
                    Biotechnology</i><a href="https://doi.org/10.4236/abb.2010.11008"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">, Vol. 1(1), pp 51-54. </a><span
                    class="s64">https://doi.org/10.4236/abb.2010.11008.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[5]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: justify;">Annamalai, C. (2022) Calculus and
                Computation for Geometric Series with Binomial Coefficients. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v16"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-</span><a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v16"
                    style=" color: #00F; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank"> </a><span class="s64">v16</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[6]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Computing Method for
                Binomial Expansions and Geometric Series. <a href="https://www.doi.org/10.33774/coe-2022-pnx53-v3"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v3</span>.</p>
        </li>
        <li data-list-text="[7]">
            <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -36pt;text-align: justify;">Annamalai, C. (2022)
                Computational Method for Summation of Binomial Expansions equal to Sum of Geometric Series with
                Exponents of 2. <a href="https://www.doi.org/10.33774/coe-2022-pnx53-v2"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v5</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[8]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2009) A novel
                computational technique for the geometric progression of powers of two. <i>Journal of Scientific and
                    Mathematical Research</i><a href="https://doi.org/10.5281/zenodo.6642923"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">, Vol.3, pp 16-17. </a><span
                    class="s64">https://doi.org/10.5281/zenodo.6642923</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[9]">
            <p style="padding-left: 40pt;text-indent: -35pt;text-align: left;">Annamalai C (2022) Sum of Summations of
                Annamalai’s Binomial Expansions. <i>SSRN</i></p>
            <p style="padding-left: 41pt;text-indent: 0pt;text-align: left;"><a
                    href="http://dx.doi.org/10.2139/ssrn.4119994"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">4119994. </a><a href="http://dx.doi.org/10.2139/ssrn.4119994"
                    target="_blank">http://dx.doi.org/10.2139/ssrn.4119994.</a></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[10]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;"><a
                    href="https://www.doi.org/10.31219/osf.io/n89xk"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Annamalai, C. (2015) A Theorem on the Annamalai’s Binomial Identities. OSF
                    Preprints. </a><span class="s64">https://www.doi.org/10.31219/osf.io/n89xk</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[11]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Summations of Single
                Terms and Successive Terms of Geometric Series. <i>SSRN </i>4085922<a
                    href="http://dx.doi.org/10.2139/ssrn.4085922"
                    style=" color: #212121; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">. </a><span class="s64">http://dx.doi.org/10.2139/ssrn.4085922</span><span
                    style=" color: #212121;">.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[12]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: justify;">Annamalai, C. (2022) Computing Method
                for Sum of Geometric Series and Binomial Expansions. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v2"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-</span><a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v2"
                    style=" color: #00F; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank"> </a><span class="s64">v2</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[13]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Computation Method
                for Summation of Binomial Expansions equal to Sum of Geometric Series with Exponents of Two. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v2"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v6</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[14]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: justify;"><a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v20"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Annamalai, C. (2022) Computational Method for Combinatorial Geometric Series and
                    Binomial Theorems. Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-</span><a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v20"
                    style=" color: #00F; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank"> </a><a href="https://www.doi.org/10.33774/coe-2022-pnx53-v20"
                    target="_blank">pnx53-v20</a></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[15]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Summations of Single
                Terms and Successive Terms of Geometric Series. <i>SSRN </i>4085922<a
                    href="http://dx.doi.org/10.2139/ssrn.4085922"
                    style=" color: #212121; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">. </a><span class="s64">http://dx.doi.org/10.2139/ssrn.4085922</span><span
                    style=" color: #212121;">.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[16]">
            <p style="padding-left: 40pt;text-indent: -35pt;text-align: left;">Annamalai, C. (2022) Sum of Geometric
                Series with Negative Exponents. <i>SSRN </i><span class="s66">4088497.</span></p>
            <p style="padding-left: 41pt;text-indent: 0pt;text-align: left;"><span
                    class="s64">http://dx.doi.org/10.2139/ssrn.4088497</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[17]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;"><a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v17"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Annamalai, C. (2022) Combinatorial Geometric Series and Binomial Theorems. Cambridge
                    Open Engage. </a><span class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v17.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[18]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: justify;"><a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v18"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Annamalai, C. (2022) Computing Method for Combinatorial Geometric Series and
                    Binomial Expansion. Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-</span><a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v18"
                    style=" color: #00F; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank"> </a><span class="s64">pnx53-v18.</span></p>
        </li>
        <li data-list-text="[19]">
            <p style="padding-top: 13pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022)
                Numerical Method and Computation for Combinatorial Geometric Series and Binomial Theorems. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v19"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v19.</span></p>
        </li>
        <li data-list-text="[20]">
            <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022)
                Annamalai&#39;s Binomial Identity and Theorem. <i>SSRN </i>4088497<a
                    href="http://dx.doi.org/10.2139/ssrn.4088497"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">. </a><span class="s64">http://dx.doi.org/10.2139/ssrn.4088497</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[21]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Novel Binomial
                Series and its Summations. <i>SSRN </i>4078523<a href="http://dx.doi.org/10.2139/ssrn.4078523"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">. </a><span class="s64">http://dx.doi.org/10.2139/ssrn.4078523</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[22]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Numerical
                Computational Method for Computation of Binomial Expansions and Geometric Series. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v7"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v7.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[23]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Algorithmic and
                Numerical Techniques for Computation of Binomial and Geometric Series . <i>Cambridge Open Engage. </i><a
                    href="http://www.doi.org/10.33774/coe-2022-pnx53-v8" class="a" target="_blank">https:/</a><a
                    href="http://www.doi.org/10.33774/coe-2022-pnx53-v8"
                    target="_blank">/www.doi.org/10.33774/coe-2022-pnx53-v8.</a></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[24]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) A Binomial Expansion
                equal to Multiple of 2 with Non-Negative Exponents. <i>SSRN </i><a
                    href="http://dx.doi.org/10.2139/ssrn.4116671"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">4116671. </a><span class="s64">http://dx.doi.org/10.2139/ssrn.4116671</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[25]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Differentiation and
                Integration of Annamalai’s Binomial Expansion. <span class="s67">SSRN </span><a
                    href="https://dx.doi.org/10.2139/ssrn.4110255"
                    style=" color: #212121; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">4110255. </a><span class="s64">https://dx.doi.org/10.2139/ssrn.4110255</span><span
                    style=" color: #212121;">.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[26]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Computation and
                Numerical Method for Summations of Binomial and Geometric Series. <i>Cambridge Open Engage.</i></p>
            <p class="s64" style="padding-left: 41pt;text-indent: 0pt;text-align: left;">
                https://www.doi.org/10.33774/coe-2022-pnx53-v9.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[27]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: justify;">Annamalai, C. (2022) Combinatorial and
                Algorithmic Technique for Computation of Binomial Expansions and Geometric Series with its Derivatives.
                <a href="https://www.doi.org/10.33774/coe-2022-pnx53-v9"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v10.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[28]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: justify;">Annamalai, C. (2022) Computation
                Method for the Summation of Series of Binomial Expansions and Geometric Series with its Derivatives. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v9"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v11.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[29]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: justify;">Annamalai, C. (2022) Computational
                Technique and Differential Calculus for the Summation of Geometric Series and Binomial Expansions. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v12"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v12.</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[30]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Computation of
                Summations of Annamalai’s Binomial Expansions. <a href="https://www.doi.org/10.31219/osf.io/cw6hq"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">OSF Preprints. </a><span
                    class="s64">https://www.doi.org/10.31219/osf.io/cw6hq</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[31]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Computation and
                Calculus for the Summation of Geometric Series and Binomial Expansions. <i>Cambridge Open Engage.</i>
            </p>
            <p class="s64" style="padding-left: 44pt;text-indent: 0pt;text-align: left;">
                https://www.doi.org/10.33774/coe-2022-pnx53-v13.</p>
        </li>
        <li data-list-text="[32]">
            <p style="padding-top: 13pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022)
                Computational Techniques and Calculus for the Summation of Geometric Series and Binomial Expansions. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v14"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v14.</span></p>
        </li>
        <li data-list-text="[33]">
            <p style="padding-top: 3pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022)
                Computational Method and Calculus for the Summation of Geometric Series and Binomial Expansions. <a
                    href="https://www.doi.org/10.33774/coe-2022-pnx53-v15"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">Cambridge Open Engage. </a><span
                    class="s64">https://www.doi.org/10.33774/coe-2022-pnx53-v15</span>.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[34]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Factorials and
                Integers for Applications in Computing and Cryptography. <i>Cambridge Open Engage</i>.</p>
            <p style="padding-left: 41pt;text-indent: 0pt;text-align: left;"><a
                    href="https://www.doi.org/10.33774/coe-2022-b6mks">https://www.doi.org/10.33774/coe-2022-b6mks.</a>
            </p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="[35]">
            <p style="padding-left: 41pt;text-indent: -36pt;text-align: left;">Annamalai, C. (2022) Factorials, Integers
                and Mathematical and Binomial Techniques for Machine Learning and Cybersecurity. <i>Cambridge Open
                    Engage</i><a href="https://www.doi.org/10.33774/coe-2022-b6mks-v2"
                    style=" color: black; font-family:&quot;Times New Roman&quot;, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt;"
                    target="_blank">. </a><span class="s64">https://www.doi.org/10.33774/coe-2022-b6mks-v2</span>.</p>
        </li>
    </ol>
</body>

</html>

  `;

  return (
    <div>
      <Navbar />
      <div className="paper-content">
        <HtmlRenderer html={htmlContent} />
      </div>
      <Footer />
    </div>
  );
};

export default ComputationalAndNumericalMethodsWithoutRelaxingtheConstraintsOnTheNumberofNodesInTheGraph;
