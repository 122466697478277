const page2Data = [{
    "id": 1,
    "authorOneFname": "Markus",
    "authorTwoFname": "Elisa",
    "authorThreeFname": "Carie",
    "authorFourFname": "Wendall",
    "authorFiveFname": "Clevey",
    "journalTitle": "Al Madinah International University",
    "journalPublisher": "Juana",
    "publicationLanguage": "Latvian",
    "institution": "University of Northern Colorado",
    "website": "canalblog.com",
    "state": null,
    "preparedDate": 2009,
    "openPaper": "imgur.com",
    "code": "BQJ",
    "github": "Windler, Hagenes and Mertz"
  }, {
    "id": 2,
    "authorOneFname": "Rosita",
    "authorTwoFname": "Trevar",
    "authorThreeFname": "Monika",
    "authorFourFname": "Heath",
    "authorFiveFname": "Veronika",
    "journalTitle": "The Johns Hopkins University",
    "journalPublisher": "Victoria",
    "publicationLanguage": "Ndebele",
    "institution": "Katholische Fachhochschule Berlin (KFB)",
    "website": "moonfruit.com",
    "state": null,
    "preparedDate": 2004,
    "openPaper": "house.gov",
    "code": "HOM",
    "github": "Miller-Schroeder"
  }, {
    "id": 3,
    "authorOneFname": "Damian",
    "authorTwoFname": "Evey",
    "authorThreeFname": "Kaila",
    "authorFourFname": "Mickey",
    "authorFiveFname": "Westleigh",
    "journalTitle": "California State University, Channel Islands",
    "journalPublisher": "Tonia",
    "publicationLanguage": "Assamese",
    "institution": "Ecole Supérieure d'Optique",
    "website": "sciencedirect.com",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "blogs.com",
    "code": "SMY",
    "github": "Jacobi, Fay and Armstrong"
  }, {
    "id": 4,
    "authorOneFname": "Christin",
    "authorTwoFname": "Janel",
    "authorThreeFname": "Gilbertine",
    "authorFourFname": "Monro",
    "authorFiveFname": "Troy",
    "journalTitle": "Chiang Mai University",
    "journalPublisher": "Melamie",
    "publicationLanguage": "Malayalam",
    "institution": "Karaganda State Technical University",
    "website": "epa.gov",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "github.io",
    "code": "EMT",
    "github": "Swift LLC"
  }, {
    "id": 5,
    "authorOneFname": "Jermayne",
    "authorTwoFname": "Karl",
    "authorThreeFname": "Shayne",
    "authorFourFname": "Les",
    "authorFiveFname": "Noelyn",
    "journalTitle": "Universitatea de Vest \"Vasile Goldi&#351;\" ",
    "journalPublisher": "Katee",
    "publicationLanguage": "Zulu",
    "institution": "Otto-von-Guericke Universität Magdeburg",
    "website": "privacy.gov.au",
    "state": null,
    "preparedDate": 2009,
    "openPaper": "cornell.edu",
    "code": "GKA",
    "github": "Bayer, Kunde and Wiegand"
  }, {
    "id": 6,
    "authorOneFname": "Nikolai",
    "authorTwoFname": "Keelby",
    "authorThreeFname": "Peirce",
    "authorFourFname": "Care",
    "authorFiveFname": "Erroll",
    "journalTitle": "Odessa National Polytechnic University",
    "journalPublisher": "Bill",
    "publicationLanguage": "English",
    "institution": "Cheljabinsk State Institute of Teacher Training",
    "website": "163.com",
    "state": "Provincie Noord-Brabant",
    "preparedDate": 2004,
    "openPaper": "rambler.ru",
    "code": "GRW",
    "github": "Veum-Muller"
  }, {
    "id": 7,
    "authorOneFname": "Darrell",
    "authorTwoFname": "Ealasaid",
    "authorThreeFname": "Else",
    "authorFourFname": "Berta",
    "authorFiveFname": "Joycelin",
    "journalTitle": "Zurich University of Applied Sciences Winterthur",
    "journalPublisher": "Addy",
    "publicationLanguage": "Bislama",
    "institution": "Great Lakes Christian College",
    "website": "biglobe.ne.jp",
    "state": null,
    "preparedDate": 2017,
    "openPaper": "is.gd",
    "code": "SPT",
    "github": "Mitchell-Wilderman"
  }, {
    "id": 8,
    "authorOneFname": "Philippa",
    "authorTwoFname": "Bartholomeus",
    "authorThreeFname": "Cody",
    "authorFourFname": "Zed",
    "authorFiveFname": "Tabby",
    "journalTitle": "Guangxi Normal University",
    "journalPublisher": "Euphemia",
    "publicationLanguage": "Chinese",
    "institution": "Universidad de Huánuco",
    "website": "jalbum.net",
    "state": null,
    "preparedDate": 2010,
    "openPaper": "csmonitor.com",
    "code": "JAD",
    "github": "Ledner and Sons"
  }, {
    "id": 9,
    "authorOneFname": "Carole",
    "authorTwoFname": "Waly",
    "authorThreeFname": "Leonore",
    "authorFourFname": "Jena",
    "authorFiveFname": "Roley",
    "journalTitle": "Universidad Laica \"Eloy Alfaro\" de Manabi",
    "journalPublisher": "Clare",
    "publicationLanguage": "Nepali",
    "institution": "Wentworth Institute of Technology",
    "website": "thetimes.co.uk",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "cyberchimps.com",
    "code": "BXO",
    "github": "Boehm Inc"
  }, {
    "id": 10,
    "authorOneFname": "Brinna",
    "authorTwoFname": "Harley",
    "authorThreeFname": "Estelle",
    "authorFourFname": "Rurik",
    "authorFiveFname": "Nathaniel",
    "journalTitle": "Humboldt State University",
    "journalPublisher": "Cristal",
    "publicationLanguage": "Ndebele",
    "institution": "Academy of Music, Dance and Fine Arts",
    "website": "booking.com",
    "state": null,
    "preparedDate": 2019,
    "openPaper": "reddit.com",
    "code": "UME",
    "github": "Harvey, Cruickshank and Graham"
  }, {
    "id": 11,
    "authorOneFname": "Rafi",
    "authorTwoFname": "Brodie",
    "authorThreeFname": "Dante",
    "authorFourFname": "Denna",
    "authorFiveFname": "Sinclair",
    "journalTitle": "Universidad de Oriente",
    "journalPublisher": "Ardene",
    "publicationLanguage": "Armenian",
    "institution": "Institute of Teachers Education, Temenggong Ibrahim",
    "website": "kickstarter.com",
    "state": null,
    "preparedDate": 2020,
    "openPaper": "dell.com",
    "code": "KIT",
    "github": "Batz Group"
  }, {
    "id": 12,
    "authorOneFname": "Raymund",
    "authorTwoFname": "Jordain",
    "authorThreeFname": "Uriel",
    "authorFourFname": "Templeton",
    "authorFiveFname": "Baxie",
    "journalTitle": "Abia State University",
    "journalPublisher": "Prudy",
    "publicationLanguage": "Fijian",
    "institution": "Universidad Privada Juan Mejía Baca",
    "website": "de.vu",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "skyrock.com",
    "code": "YBF",
    "github": "Aufderhar-Hodkiewicz"
  }, {
    "id": 13,
    "authorOneFname": "Magdalen",
    "authorTwoFname": "Lou",
    "authorThreeFname": "Laurianne",
    "authorFourFname": "Ozzie",
    "authorFiveFname": "Adriaens",
    "journalTitle": "Universidad Americana",
    "journalPublisher": "Lily",
    "publicationLanguage": "Bulgarian",
    "institution": "Northeast Forest University",
    "website": "timesonline.co.uk",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "wikispaces.com",
    "code": "JFN",
    "github": "Roob Group"
  }, {
    "id": 14,
    "authorOneFname": "Bjorn",
    "authorTwoFname": "Blondell",
    "authorThreeFname": "Pier",
    "authorFourFname": "Clay",
    "authorFiveFname": "Christel",
    "journalTitle": "Ecole Supérieure de Commerce et Management",
    "journalPublisher": "Bunny",
    "publicationLanguage": "Polish",
    "institution": "University of Twente",
    "website": "census.gov",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "umn.edu",
    "code": "BVA",
    "github": "Upton-Hahn"
  }, {
    "id": 15,
    "authorOneFname": "Maire",
    "authorTwoFname": "Ronald",
    "authorThreeFname": "Seamus",
    "authorFourFname": "Gunilla",
    "authorFiveFname": "Willi",
    "journalTitle": "University of Asia and the Pacific",
    "journalPublisher": "Beatrix",
    "publicationLanguage": "Dhivehi",
    "institution": "Danish Business Academy",
    "website": "privacy.gov.au",
    "state": null,
    "preparedDate": 2005,
    "openPaper": "patch.com",
    "code": "WYS",
    "github": "Schimmel-Zulauf"
  }, {
    "id": 16,
    "authorOneFname": "Calypso",
    "authorTwoFname": "Annie",
    "authorThreeFname": "Katuscha",
    "authorFourFname": "Ernest",
    "authorFiveFname": "Chelsea",
    "journalTitle": "National Yunlin University of Science and Technology",
    "journalPublisher": "Annalise",
    "publicationLanguage": "Swahili",
    "institution": "University of the Immaculate Conception",
    "website": "acquirethisname.com",
    "state": null,
    "preparedDate": 2009,
    "openPaper": "theguardian.com",
    "code": "OWK",
    "github": "Stiedemann and Sons"
  }, {
    "id": 17,
    "authorOneFname": "Min",
    "authorTwoFname": "Jason",
    "authorThreeFname": "Linette",
    "authorFourFname": "Sigismund",
    "authorFiveFname": "Archibold",
    "journalTitle": "University of Rochester",
    "journalPublisher": "Dorthea",
    "publicationLanguage": "Fijian",
    "institution": "National College of Arts",
    "website": "uiuc.edu",
    "state": null,
    "preparedDate": 0,
    "openPaper": "sfgate.com",
    "code": "AAY",
    "github": "Windler-Stanton"
  }, {
    "id": 18,
    "authorOneFname": "Nicolette",
    "authorTwoFname": "Zeb",
    "authorThreeFname": "Marion",
    "authorFourFname": "Cortie",
    "authorFiveFname": "De witt",
    "journalTitle": "Islamic University of Gaza",
    "journalPublisher": "Marna",
    "publicationLanguage": "Arabic",
    "institution": "Universidad Agraria del Ecuador",
    "website": "ovh.net",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "barnesandnoble.com",
    "code": "ARS",
    "github": "Kemmer, Rippin and Bernier"
  }, {
    "id": 19,
    "authorOneFname": "Forester",
    "authorTwoFname": "Ilyse",
    "authorThreeFname": "Taylor",
    "authorFourFname": "Timothea",
    "authorFiveFname": "Alexandrina",
    "journalTitle": "University of Lausanne",
    "journalPublisher": "Josefa",
    "publicationLanguage": "Yiddish",
    "institution": "University Marien Ngouabi Brazzaville",
    "website": "sogou.com",
    "state": null,
    "preparedDate": 2006,
    "openPaper": "clickbank.net",
    "code": "MPZ",
    "github": "Mann-Gulgowski"
  }, {
    "id": 20,
    "authorOneFname": "Christiano",
    "authorTwoFname": "Matt",
    "authorThreeFname": "Natasha",
    "authorFourFname": "Ahmad",
    "authorFiveFname": "Romonda",
    "journalTitle": "Shandong Normal University",
    "journalPublisher": "Ami",
    "publicationLanguage": "Bosnian",
    "institution": "Lakshmibai National Institute of Physical Education",
    "website": "diigo.com",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "wikia.com",
    "code": "KGW",
    "github": "Dare Group"
  }, {
    "id": 21,
    "authorOneFname": "Dedie",
    "authorTwoFname": "Evelina",
    "authorThreeFname": "Randall",
    "authorFourFname": "Jere",
    "authorFiveFname": "Domini",
    "journalTitle": "Pontifícia Universidade Católica de Campinas",
    "journalPublisher": "Darelle",
    "publicationLanguage": "German",
    "institution": "St. Francis College, Fort Wayne",
    "website": "t.co",
    "state": null,
    "preparedDate": 2012,
    "openPaper": "unicef.org",
    "code": "MAW",
    "github": "Ritchie, Berge and McCullough"
  }, {
    "id": 22,
    "authorOneFname": "Selie",
    "authorTwoFname": "Shawnee",
    "authorThreeFname": "Fallon",
    "authorFourFname": "Omero",
    "authorFiveFname": "Antonie",
    "journalTitle": "International University of Africa (IUA)",
    "journalPublisher": "Cele",
    "publicationLanguage": "Gagauz",
    "institution": "Pennsylvania College of Optometry",
    "website": "mediafire.com",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "timesonline.co.uk",
    "code": "POU",
    "github": "Mayert and Sons"
  }, {
    "id": 23,
    "authorOneFname": "Olenka",
    "authorTwoFname": "Petrina",
    "authorThreeFname": "Velvet",
    "authorFourFname": "Bryn",
    "authorFiveFname": "Garold",
    "journalTitle": "Mississippi College",
    "journalPublisher": "Dinny",
    "publicationLanguage": "Hiri Motu",
    "institution": "Universidade Católica Dom Bosco",
    "website": "loc.gov",
    "state": null,
    "preparedDate": 2010,
    "openPaper": "nsw.gov.au",
    "code": "SGW",
    "github": "Dooley, Tremblay and O'Connell"
  }, {
    "id": 24,
    "authorOneFname": "Kaine",
    "authorTwoFname": "Powell",
    "authorThreeFname": "Trip",
    "authorFourFname": "Pavlov",
    "authorFiveFname": "Mahmoud",
    "journalTitle": "Union College Kentucky",
    "journalPublisher": "Eddy",
    "publicationLanguage": "Icelandic",
    "institution": "Universidad del Istmo",
    "website": "seesaa.net",
    "state": "Leiria",
    "preparedDate": 2016,
    "openPaper": "dot.gov",
    "code": "GNA",
    "github": "Cremin, Parisian and Funk"
  }, {
    "id": 25,
    "authorOneFname": "Lyssa",
    "authorTwoFname": "Shaylah",
    "authorThreeFname": "Hugh",
    "authorFourFname": "Lorettalorna",
    "authorFiveFname": "Myron",
    "journalTitle": "University of Newcastle-upon-Tyne",
    "journalPublisher": "Consuelo",
    "publicationLanguage": "German",
    "institution": "Trent University",
    "website": "ucoz.ru",
    "state": null,
    "preparedDate": 2008,
    "openPaper": "surveymonkey.com",
    "code": "CGO",
    "github": "Johnson, Walsh and Buckridge"
  }, {
    "id": 26,
    "authorOneFname": "Cobby",
    "authorTwoFname": "Libby",
    "authorThreeFname": "Ezekiel",
    "authorFourFname": "Meg",
    "authorFiveFname": "Dorita",
    "journalTitle": "Oslo School of Architecture",
    "journalPublisher": "Inger",
    "publicationLanguage": "Bislama",
    "institution": "Universidad Nacional Andrés Bello",
    "website": "nih.gov",
    "state": "Chiapas",
    "preparedDate": 2003,
    "openPaper": "blogger.com",
    "code": "RTA",
    "github": "Stehr, Volkman and Jacobs"
  }, {
    "id": 27,
    "authorOneFname": "Gilda",
    "authorTwoFname": "Dannye",
    "authorThreeFname": "Allix",
    "authorFourFname": "Bernardine",
    "authorFiveFname": "Lefty",
    "journalTitle": "Moscow State University of Ecological Engineering",
    "journalPublisher": "Imelda",
    "publicationLanguage": "Papiamento",
    "institution": "Universidade Castelo Branco",
    "website": "sina.com.cn",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "mac.com",
    "code": "PPD",
    "github": "Waters-Grimes"
  }, {
    "id": 28,
    "authorOneFname": "Kenny",
    "authorTwoFname": "Rubetta",
    "authorThreeFname": "Huntlee",
    "authorFourFname": "Roby",
    "authorFiveFname": "Keelby",
    "journalTitle": "Fachhochschule Stuttgart, Hochschule für Technik",
    "journalPublisher": "Zilvia",
    "publicationLanguage": "Estonian",
    "institution": "Klaipeda University",
    "website": "gmpg.org",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "studiopress.com",
    "code": "BHS",
    "github": "Shields, Gleason and Bogisich"
  }, {
    "id": 29,
    "authorOneFname": "Danita",
    "authorTwoFname": "Thaddeus",
    "authorThreeFname": "Enrichetta",
    "authorFourFname": "Barnett",
    "authorFiveFname": "Juliana",
    "journalTitle": "European Business School Schloß Reichartshausen",
    "journalPublisher": "Kimberlee",
    "publicationLanguage": "Kannada",
    "institution": "Mahidol University International College",
    "website": "jigsy.com",
    "state": null,
    "preparedDate": 2004,
    "openPaper": "mozilla.com",
    "code": "HOY",
    "github": "Kulas and Sons"
  }, {
    "id": 30,
    "authorOneFname": "Rusty",
    "authorTwoFname": "Ludovika",
    "authorThreeFname": "Winny",
    "authorFourFname": "Manya",
    "authorFiveFname": "Andriana",
    "journalTitle": "University of San Agustin",
    "journalPublisher": "Delphine",
    "publicationLanguage": "Armenian",
    "institution": "Universidad Chapultepéc",
    "website": "rambler.ru",
    "state": null,
    "preparedDate": 2020,
    "openPaper": "tuttocitta.it",
    "code": "PKG",
    "github": "Kunze, Haag and Bailey"
  }, {
    "id": 31,
    "authorOneFname": "Woodie",
    "authorTwoFname": "Rica",
    "authorThreeFname": "Eydie",
    "authorFourFname": "Merla",
    "authorFiveFname": "L;urette",
    "journalTitle": "Ecole Nationale d'Ingénieurs de Metz",
    "journalPublisher": "Georgeanne",
    "publicationLanguage": "Zulu",
    "institution": "University of Patras",
    "website": "stanford.edu",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "cnn.com",
    "code": "CRV",
    "github": "Will and Sons"
  }, {
    "id": 32,
    "authorOneFname": "Cortie",
    "authorTwoFname": "Robin",
    "authorThreeFname": "Row",
    "authorFourFname": "Johnnie",
    "authorFiveFname": "Mickey",
    "journalTitle": "University of New England",
    "journalPublisher": "Etheline",
    "publicationLanguage": "Assamese",
    "institution": "Fachhochschule Kärnten",
    "website": "illinois.edu",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "aol.com",
    "code": "JUB",
    "github": "Ferry-Windler"
  }, {
    "id": 33,
    "authorOneFname": "Felike",
    "authorTwoFname": "Corrie",
    "authorThreeFname": "Genvieve",
    "authorFourFname": "Bondy",
    "authorFiveFname": "Benedikta",
    "journalTitle": "Mahatma Phule Agricultural University",
    "journalPublisher": "Codee",
    "publicationLanguage": "Burmese",
    "institution": "State University of New York at Oswego",
    "website": "devhub.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "quantcast.com",
    "code": "CRL",
    "github": "Hackett and Sons"
  }, {
    "id": 34,
    "authorOneFname": "Ailis",
    "authorTwoFname": "Enoch",
    "authorThreeFname": "Nickola",
    "authorFourFname": "Viviene",
    "authorFiveFname": "Shell",
    "journalTitle": "University of Veterinary & Animal Science",
    "journalPublisher": "Kellsie",
    "publicationLanguage": "German",
    "institution": "Colorado Technical University",
    "website": "sogou.com",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "alibaba.com",
    "code": "YNO",
    "github": "Roberts, Kautzer and Goldner"
  }, {
    "id": 35,
    "authorOneFname": "Nelli",
    "authorTwoFname": "Saundra",
    "authorThreeFname": "Ludovika",
    "authorFourFname": "Lucie",
    "authorFiveFname": "Constance",
    "journalTitle": "Debre Markos University",
    "journalPublisher": "Bell",
    "publicationLanguage": "Latvian",
    "institution": "School of Design and Crafts",
    "website": "blogtalkradio.com",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "cisco.com",
    "code": "GCY",
    "github": "Ratke-Jacobi"
  }, {
    "id": 36,
    "authorOneFname": "Alex",
    "authorTwoFname": "Artair",
    "authorThreeFname": "Jacques",
    "authorFourFname": "Olenolin",
    "authorFiveFname": "Kinnie",
    "journalTitle": "Uttar Pradesh Technical University",
    "journalPublisher": "Adoree",
    "publicationLanguage": "Sotho",
    "institution": "Imam Reza University",
    "website": "chicagotribune.com",
    "state": null,
    "preparedDate": 2008,
    "openPaper": "ibm.com",
    "code": "RAE",
    "github": "Bradtke-Willms"
  }, {
    "id": 37,
    "authorOneFname": "Lena",
    "authorTwoFname": "Bobbee",
    "authorThreeFname": "Andrea",
    "authorFourFname": "Terry",
    "authorFiveFname": "Olympe",
    "journalTitle": "Grodno State Agrarian University",
    "journalPublisher": "Donni",
    "publicationLanguage": "Bislama",
    "institution": "Damanhour University",
    "website": "nhs.uk",
    "state": "Porto",
    "preparedDate": 2017,
    "openPaper": "aboutads.info",
    "code": "GKK",
    "github": "West, Quigley and Pouros"
  }, {
    "id": 38,
    "authorOneFname": "Yardley",
    "authorTwoFname": "Kaylee",
    "authorThreeFname": "Caryl",
    "authorFourFname": "Pierrette",
    "authorFiveFname": "Franchot",
    "journalTitle": "Virtual University of Pakistan",
    "journalPublisher": "Erminie",
    "publicationLanguage": "Kyrgyz",
    "institution": "Alaska Bible College",
    "website": "devhub.com",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "yandex.ru",
    "code": "MAQ",
    "github": "Goodwin Inc"
  }, {
    "id": 39,
    "authorOneFname": "Rosabella",
    "authorTwoFname": "Sasha",
    "authorThreeFname": "Normie",
    "authorFourFname": "Nessie",
    "authorFiveFname": "Torie",
    "journalTitle": "Dallas Christian College",
    "journalPublisher": "Agnella",
    "publicationLanguage": "Guaraní",
    "institution": "Salem State College",
    "website": "google.co.jp",
    "state": "Mexico",
    "preparedDate": 2007,
    "openPaper": "wired.com",
    "code": "TPE",
    "github": "Predovic, Baumbach and Tillman"
  }, {
    "id": 40,
    "authorOneFname": "Melantha",
    "authorTwoFname": "Spencer",
    "authorThreeFname": "Albina",
    "authorFourFname": "Laverne",
    "authorFiveFname": "Derby",
    "journalTitle": "Bentley College",
    "journalPublisher": "Auroora",
    "publicationLanguage": "Tok Pisin",
    "institution": "Wawasan Open University",
    "website": "howstuffworks.com",
    "state": null,
    "preparedDate": 2007,
    "openPaper": "omniture.com",
    "code": "JMO",
    "github": "Oberbrunner-Reynolds"
  }, {
    "id": 41,
    "authorOneFname": "Helenka",
    "authorTwoFname": "Maridel",
    "authorThreeFname": "Mano",
    "authorFourFname": "Dilan",
    "authorFiveFname": "Mariejeanne",
    "journalTitle": "Bayan College for Science & Technology",
    "journalPublisher": "Lizette",
    "publicationLanguage": "Norwegian",
    "institution": "Leyte State University ",
    "website": "opera.com",
    "state": null,
    "preparedDate": 2012,
    "openPaper": "sohu.com",
    "code": "KWN",
    "github": "Jacobs and Sons"
  }, {
    "id": 42,
    "authorOneFname": "Emlen",
    "authorTwoFname": "Janene",
    "authorThreeFname": "Nettie",
    "authorFourFname": "Adaline",
    "authorFiveFname": "Holly",
    "journalTitle": "Fachhochschule Lübeck",
    "journalPublisher": "Lucine",
    "publicationLanguage": "Bislama",
    "institution": "FAST - National University of Computer and Emerging Sciences (NUCES)",
    "website": "china.com.cn",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "statcounter.com",
    "code": "CIH",
    "github": "Wolff-Feil"
  }, {
    "id": 43,
    "authorOneFname": "Melli",
    "authorTwoFname": "Obie",
    "authorThreeFname": "Ron",
    "authorFourFname": "Maurie",
    "authorFiveFname": "Kiley",
    "journalTitle": "University of Waterloo",
    "journalPublisher": "Wynn",
    "publicationLanguage": "Lithuanian",
    "institution": "University of Technology Jamaica",
    "website": "xing.com",
    "state": null,
    "preparedDate": 2003,
    "openPaper": "intel.com",
    "code": "KLW",
    "github": "Kassulke LLC"
  }, {
    "id": 44,
    "authorOneFname": "Tawsha",
    "authorTwoFname": "Isak",
    "authorThreeFname": "Herb",
    "authorFourFname": "Igor",
    "authorFiveFname": "Christal",
    "journalTitle": "University of Lethbridge",
    "journalPublisher": "Murial",
    "publicationLanguage": "Filipino",
    "institution": "Osaka Shoin Women's College",
    "website": "businessweek.com",
    "state": "Győr-Moson-Sopron",
    "preparedDate": 2015,
    "openPaper": "biblegateway.com",
    "code": "RTY",
    "github": "Stokes, Crist and Ondricka"
  }, {
    "id": 45,
    "authorOneFname": "Thurston",
    "authorTwoFname": "Jilly",
    "authorThreeFname": "Filberto",
    "authorFourFname": "Dionis",
    "authorFiveFname": "Falkner",
    "journalTitle": "Nagoya Economics University",
    "journalPublisher": "Gus",
    "publicationLanguage": "Portuguese",
    "institution": "Bucknell University",
    "website": "washington.edu",
    "state": null,
    "preparedDate": 2004,
    "openPaper": "tripod.com",
    "code": "EDW",
    "github": "Nolan, Dooley and Wisozk"
  }, {
    "id": 46,
    "authorOneFname": "Alvera",
    "authorTwoFname": "Wayne",
    "authorThreeFname": "Donica",
    "authorFourFname": "Jakob",
    "authorFiveFname": "Irvin",
    "journalTitle": "Raffles University",
    "journalPublisher": "Charil",
    "publicationLanguage": "Fijian",
    "institution": "Institut d'Agriculture, de Technologie et d'Education de Kibungo",
    "website": "hostgator.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "si.edu",
    "code": "AAK",
    "github": "Upton Inc"
  }, {
    "id": 47,
    "authorOneFname": "Llywellyn",
    "authorTwoFname": "Porter",
    "authorThreeFname": "Baxie",
    "authorFourFname": "Mata",
    "authorFiveFname": "Gian",
    "journalTitle": "Université Paris Nord (Paris XIII)",
    "journalPublisher": "Ophelia",
    "publicationLanguage": "Georgian",
    "institution": "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie",
    "website": "ca.gov",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "amazon.de",
    "code": "MIB",
    "github": "Bahringer, McCullough and Harris"
  }, {
    "id": 48,
    "authorOneFname": "Kettie",
    "authorTwoFname": "Blaire",
    "authorThreeFname": "Valery",
    "authorFourFname": "Derby",
    "authorFiveFname": "Jeri",
    "journalTitle": "Fundación Universitaria del Area Andina. Sede Pereira",
    "journalPublisher": "Nicolea",
    "publicationLanguage": "Albanian",
    "institution": "Bashkir State Agrarian University",
    "website": "ehow.com",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "archive.org",
    "code": "XYE",
    "github": "Wisoky, Oberbrunner and Connelly"
  }, {
    "id": 49,
    "authorOneFname": "Margery",
    "authorTwoFname": "Ettie",
    "authorThreeFname": "Ida",
    "authorFourFname": "Talbot",
    "authorFiveFname": "Violetta",
    "journalTitle": "Universidade de Passo Fundo",
    "journalPublisher": "Tessi",
    "publicationLanguage": "Azeri",
    "institution": "Inchon University",
    "website": "whitehouse.gov",
    "state": null,
    "preparedDate": 2012,
    "openPaper": "tripadvisor.com",
    "code": "PBZ",
    "github": "Renner-Veum"
  }, {
    "id": 50,
    "authorOneFname": "Cherey",
    "authorTwoFname": "Flori",
    "authorThreeFname": "Kissie",
    "authorFourFname": "Paulo",
    "authorFiveFname": "Rickie",
    "journalTitle": "Walter Sisulu University for Technology and Science",
    "journalPublisher": "Frances",
    "publicationLanguage": "Zulu",
    "institution": "Philosophisch-Theologisches Studium Erfurt, Staatlich anerkannte Wissenschaftliche Hochschule",
    "website": "scientificamerican.com",
    "state": null,
    "preparedDate": 2019,
    "openPaper": "cbsnews.com",
    "code": "COH",
    "github": "Dietrich-Pfeffer"
  }, {
    "id": 51,
    "authorOneFname": "Malinda",
    "authorTwoFname": "Edmon",
    "authorThreeFname": "Mendy",
    "authorFourFname": "Phyllida",
    "authorFiveFname": "Norby",
    "journalTitle": "Technical University in Zvolen",
    "journalPublisher": "Nikoletta",
    "publicationLanguage": "Gujarati",
    "institution": "Universidade da Madeira",
    "website": "unesco.org",
    "state": null,
    "preparedDate": 2020,
    "openPaper": "de.vu",
    "code": "OIT",
    "github": "Cronin-Cronin"
  }, {
    "id": 52,
    "authorOneFname": "Tildie",
    "authorTwoFname": "Ingeberg",
    "authorThreeFname": "Keeley",
    "authorFourFname": "Powell",
    "authorFiveFname": "Alene",
    "journalTitle": "Bunkyo University",
    "journalPublisher": "Celinka",
    "publicationLanguage": "Haitian Creole",
    "institution": "Universitas Mulawarman",
    "website": "amazonaws.com",
    "state": null,
    "preparedDate": 2017,
    "openPaper": "bloomberg.com",
    "code": "JLR",
    "github": "Marquardt-Grant"
  }, {
    "id": 53,
    "authorOneFname": "Melvin",
    "authorTwoFname": "Cherin",
    "authorThreeFname": "Ester",
    "authorFourFname": "Leanor",
    "authorFiveFname": "Woodrow",
    "journalTitle": "Kyoto University of Education",
    "journalPublisher": "Steffane",
    "publicationLanguage": "Amharic",
    "institution": "Arizona State University, Polytechnic Campus",
    "website": "phoca.cz",
    "state": null,
    "preparedDate": 2017,
    "openPaper": "cloudflare.com",
    "code": "SVK",
    "github": "Schuppe-Harvey"
  }, {
    "id": 54,
    "authorOneFname": "Joyous",
    "authorTwoFname": "Pip",
    "authorThreeFname": "Dorie",
    "authorFourFname": "Rhoda",
    "authorFiveFname": "Clemente",
    "journalTitle": "St. Petersburg State Cinema and TV University",
    "journalPublisher": "Margareta",
    "publicationLanguage": "Bulgarian",
    "institution": "Shanghai City College",
    "website": "cisco.com",
    "state": null,
    "preparedDate": 2004,
    "openPaper": "w3.org",
    "code": "KBE",
    "github": "Deckow, Pollich and Kautzer"
  }, {
    "id": 55,
    "authorOneFname": "Korney",
    "authorTwoFname": "Gaye",
    "authorThreeFname": "Sean",
    "authorFourFname": "Bayard",
    "authorFiveFname": "Izaak",
    "journalTitle": "Université Alioune Diop de Bambey",
    "journalPublisher": "Carolee",
    "publicationLanguage": "Finnish",
    "institution": "DeVry Institute of Technology, Irving",
    "website": "admin.ch",
    "state": "Castelo Branco",
    "preparedDate": 2019,
    "openPaper": "uol.com.br",
    "code": "CWW",
    "github": "Stark, Bergnaum and Walter"
  }, {
    "id": 56,
    "authorOneFname": "Cori",
    "authorTwoFname": "Leupold",
    "authorThreeFname": "Maddi",
    "authorFourFname": "Janek",
    "authorFiveFname": "Edin",
    "journalTitle": "Imo State University",
    "journalPublisher": "Leena",
    "publicationLanguage": "Tetum",
    "institution": "University of South Florida",
    "website": "wsj.com",
    "state": null,
    "preparedDate": 2004,
    "openPaper": "whitehouse.gov",
    "code": "ZRZ",
    "github": "Keeling and Sons"
  }, {
    "id": 57,
    "authorOneFname": "Davey",
    "authorTwoFname": "Erroll",
    "authorThreeFname": "Nanni",
    "authorFourFname": "Fielding",
    "authorFiveFname": "Dom",
    "journalTitle": "Universität für Bodenkultur Wien",
    "journalPublisher": "Enrica",
    "publicationLanguage": "Dzongkha",
    "institution": "Semey State University",
    "website": "youtu.be",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "godaddy.com",
    "code": "MSD",
    "github": "Reinger LLC"
  }, {
    "id": 58,
    "authorOneFname": "Kanya",
    "authorTwoFname": "Agathe",
    "authorThreeFname": "Bette-ann",
    "authorFourFname": "Oona",
    "authorFiveFname": "Yoshi",
    "journalTitle": "Utsunomiya University",
    "journalPublisher": "Adrianna",
    "publicationLanguage": "Luxembourgish",
    "institution": "Jain University, Centre for Management Studies",
    "website": "squarespace.com",
    "state": null,
    "preparedDate": 2010,
    "openPaper": "parallels.com",
    "code": "LDV",
    "github": "Carter-Dooley"
  }, {
    "id": 59,
    "authorOneFname": "Ryun",
    "authorTwoFname": "Odey",
    "authorThreeFname": "Myranda",
    "authorFourFname": "Bordy",
    "authorFiveFname": "Ira",
    "journalTitle": "Southern Oregon University",
    "journalPublisher": "Daile",
    "publicationLanguage": "Irish Gaelic",
    "institution": "Sichuan Agricultural University",
    "website": "vkontakte.ru",
    "state": "Viseu",
    "preparedDate": 2011,
    "openPaper": "google.com.br",
    "code": "MCJ",
    "github": "Wuckert-Crona"
  }, {
    "id": 60,
    "authorOneFname": "Rici",
    "authorTwoFname": "Michaeline",
    "authorThreeFname": "Tymothy",
    "authorFourFname": "Myca",
    "authorFiveFname": "Ivar",
    "journalTitle": "Kiev National University of Construction and Architecture",
    "journalPublisher": "Sybilla",
    "publicationLanguage": "Dzongkha",
    "institution": "Trinity Theological Seminary",
    "website": "surveymonkey.com",
    "state": null,
    "preparedDate": 2005,
    "openPaper": "oaic.gov.au",
    "code": "MPF",
    "github": "Spencer-Ondricka"
  }, {
    "id": 61,
    "authorOneFname": "Sonny",
    "authorTwoFname": "Maurits",
    "authorThreeFname": "Lucien",
    "authorFourFname": "Kaylil",
    "authorFiveFname": "Germaine",
    "journalTitle": "Divine Word College",
    "journalPublisher": "Geneva",
    "publicationLanguage": "Tajik",
    "institution": "Universidad de la Marina Mercante",
    "website": "instagram.com",
    "state": "Île-de-France",
    "preparedDate": 2011,
    "openPaper": "goodreads.com",
    "code": "DAB",
    "github": "Beahan, Lubowitz and Champlin"
  }, {
    "id": 62,
    "authorOneFname": "Kathi",
    "authorTwoFname": "Kare",
    "authorThreeFname": "Ellwood",
    "authorFourFname": "Dynah",
    "authorFiveFname": "Gaby",
    "journalTitle": "Universidad de Ciego de Avila",
    "journalPublisher": "Sheeree",
    "publicationLanguage": "Amharic",
    "institution": "Moscow State University of Technology (Stankin)",
    "website": "lulu.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "ebay.co.uk",
    "code": "PED",
    "github": "Wiegand, Legros and Cronin"
  }, {
    "id": 63,
    "authorOneFname": "Arnoldo",
    "authorTwoFname": "Germana",
    "authorThreeFname": "Grete",
    "authorFourFname": "Olag",
    "authorFiveFname": "Judy",
    "journalTitle": "Talladega College",
    "journalPublisher": "Dorene",
    "publicationLanguage": "Chinese",
    "institution": "Riara University School of Business and Law",
    "website": "chronoengine.com",
    "state": "Martinique",
    "preparedDate": 2011,
    "openPaper": "eepurl.com",
    "code": "LFR",
    "github": "Casper, Green and Schowalter"
  }, {
    "id": 64,
    "authorOneFname": "Corly",
    "authorTwoFname": "Winny",
    "authorThreeFname": "Theodoric",
    "authorFourFname": "Elita",
    "authorFiveFname": "Jameson",
    "journalTitle": "Universidad Nacional de Quilmes",
    "journalPublisher": "Sandi",
    "publicationLanguage": "Azeri",
    "institution": "Multimedia University of Kenya",
    "website": "tuttocitta.it",
    "state": null,
    "preparedDate": 0,
    "openPaper": "phpbb.com",
    "code": "NAC",
    "github": "Hartmann-Price"
  }, {
    "id": 65,
    "authorOneFname": "Sydney",
    "authorTwoFname": "Kalie",
    "authorThreeFname": "Udell",
    "authorFourFname": "Melloney",
    "authorFiveFname": "Carol-jean",
    "journalTitle": "Temple University",
    "journalPublisher": "Stephannie",
    "publicationLanguage": "Georgian",
    "institution": "Dr. D.Y. Patil University",
    "website": "angelfire.com",
    "state": null,
    "preparedDate": 2019,
    "openPaper": "bravesites.com",
    "code": "HVM",
    "github": "Thiel-Jaskolski"
  }, {
    "id": 66,
    "authorOneFname": "Adriana",
    "authorTwoFname": "Dal",
    "authorThreeFname": "Braden",
    "authorFourFname": "Stillmann",
    "authorFiveFname": "Ransell",
    "journalTitle": "ITT Technical Institute Maitland",
    "journalPublisher": "Mavis",
    "publicationLanguage": "French",
    "institution": "Universitas Ibn Khaldun Bogor",
    "website": "wordpress.com",
    "state": null,
    "preparedDate": 2020,
    "openPaper": "google.ca",
    "code": "YAK",
    "github": "Trantow, Rodriguez and Renner"
  }, {
    "id": 67,
    "authorOneFname": "Ardenia",
    "authorTwoFname": "Hobart",
    "authorThreeFname": "Mal",
    "authorFourFname": "Eadith",
    "authorFiveFname": "Opaline",
    "journalTitle": "Universidad Nacional del Callao",
    "journalPublisher": "Ulrika",
    "publicationLanguage": "Icelandic",
    "institution": "Fachhochschule Deggendorf",
    "website": "thetimes.co.uk",
    "state": null,
    "preparedDate": 2004,
    "openPaper": "aol.com",
    "code": "IGO",
    "github": "Walter, Ryan and Hartmann"
  }, {
    "id": 68,
    "authorOneFname": "Maryellen",
    "authorTwoFname": "Sheila-kathryn",
    "authorThreeFname": "Donaugh",
    "authorFourFname": "Wayne",
    "authorFiveFname": "Diarmid",
    "journalTitle": "Sultan Mizan Zainal Abidin Polytechnic",
    "journalPublisher": "Lorna",
    "publicationLanguage": "Ndebele",
    "institution": "Université Chouaib Doukkali",
    "website": "people.com.cn",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "hubpages.com",
    "code": "WEM",
    "github": "Bauch and Sons"
  }, {
    "id": 69,
    "authorOneFname": "Carlyle",
    "authorTwoFname": "Cyrille",
    "authorThreeFname": "Mamie",
    "authorFourFname": "Tommie",
    "authorFiveFname": "Archy",
    "journalTitle": "Linguistic University of Nizhny Novgorod",
    "journalPublisher": "Zsazsa",
    "publicationLanguage": "Haitian Creole",
    "institution": "Sanno University",
    "website": "cdbaby.com",
    "state": null,
    "preparedDate": 2018,
    "openPaper": "xrea.com",
    "code": "BTT",
    "github": "Terry Group"
  }, {
    "id": 70,
    "authorOneFname": "Corry",
    "authorTwoFname": "Hersch",
    "authorThreeFname": "Griswold",
    "authorFourFname": "Dilan",
    "authorFiveFname": "Alys",
    "journalTitle": "Akrofi-Christaller Institute of Theeology, Mission and  Culture",
    "journalPublisher": "Zia",
    "publicationLanguage": "Spanish",
    "institution": "Brigham Young University",
    "website": "yahoo.co.jp",
    "state": null,
    "preparedDate": 2018,
    "openPaper": "wikia.com",
    "code": "VAV",
    "github": "Adams LLC"
  }, {
    "id": 71,
    "authorOneFname": "Melinde",
    "authorTwoFname": "Tracee",
    "authorThreeFname": "Ana",
    "authorFourFname": "Urbano",
    "authorFiveFname": "Janela",
    "journalTitle": "Hogeschool voor de Kunsten Utrecht (HKU)",
    "journalPublisher": "Gail",
    "publicationLanguage": "Tamil",
    "institution": "Medical College of Pennsylvania and Hahnemann University",
    "website": "tuttocitta.it",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "mapy.cz",
    "code": "YHF",
    "github": "Heller Group"
  }, {
    "id": 72,
    "authorOneFname": "Jewel",
    "authorTwoFname": "Staffard",
    "authorThreeFname": "Dorothy",
    "authorFourFname": "Clarice",
    "authorFiveFname": "Chlo",
    "journalTitle": "Universidad Dr. Jose Matias Delgado",
    "journalPublisher": "Andriana",
    "publicationLanguage": "Kyrgyz",
    "institution": "Institut d'Agriculture, de Technologie et d'Education de Kibungo",
    "website": "amazon.co.jp",
    "state": null,
    "preparedDate": 2018,
    "openPaper": "behance.net",
    "code": "WAT",
    "github": "Krajcik, Runte and Gusikowski"
  }, {
    "id": 73,
    "authorOneFname": "Berkie",
    "authorTwoFname": "Cecilio",
    "authorThreeFname": "Sutton",
    "authorFourFname": "Gris",
    "authorFiveFname": "Zacharia",
    "journalTitle": "National American University, Colorado Springs",
    "journalPublisher": "Maryjo",
    "publicationLanguage": "New Zealand Sign Language",
    "institution": "Gonabad University of Medical Sciences",
    "website": "mozilla.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "networkadvertising.org",
    "code": "YVR",
    "github": "Ernser and Sons"
  }, {
    "id": 74,
    "authorOneFname": "Myron",
    "authorTwoFname": "Letisha",
    "authorThreeFname": "Dulsea",
    "authorFourFname": "Amelia",
    "authorFiveFname": "Dorian",
    "journalTitle": "Politécnico Grancolombiano - Institución Universitaria",
    "journalPublisher": "Tedi",
    "publicationLanguage": "Tswana",
    "institution": "Dneprodzerzhinsk State Technical University",
    "website": "homestead.com",
    "state": null,
    "preparedDate": 2009,
    "openPaper": "shutterfly.com",
    "code": "UDI",
    "github": "Wolf LLC"
  }, {
    "id": 75,
    "authorOneFname": "Salmon",
    "authorTwoFname": "Rees",
    "authorThreeFname": "Stillmann",
    "authorFourFname": "Symon",
    "authorFiveFname": "Juanita",
    "journalTitle": "NED University of Engineering and Technology Karachi",
    "journalPublisher": "Dael",
    "publicationLanguage": "Armenian",
    "institution": "Akita University",
    "website": "simplemachines.org",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "vk.com",
    "code": "BHS",
    "github": "Mertz, Trantow and Wolff"
  }, {
    "id": 76,
    "authorOneFname": "Lezley",
    "authorTwoFname": "Hastie",
    "authorThreeFname": "Therese",
    "authorFourFname": "Vlad",
    "authorFiveFname": "Walden",
    "journalTitle": "Escuela Militar de Ingeniería",
    "journalPublisher": "Amii",
    "publicationLanguage": "Irish Gaelic",
    "institution": "Kean University of New Jersey",
    "website": "jiathis.com",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "nba.com",
    "code": "DOR",
    "github": "Orn-Weimann"
  }, {
    "id": 77,
    "authorOneFname": "Dominick",
    "authorTwoFname": "Krispin",
    "authorThreeFname": "Lavina",
    "authorFourFname": "Marleen",
    "authorFiveFname": "Sada",
    "journalTitle": "Universidad Nacional de San Antonio Abad",
    "journalPublisher": "Roda",
    "publicationLanguage": "Swahili",
    "institution": "Union College",
    "website": "berkeley.edu",
    "state": null,
    "preparedDate": 2007,
    "openPaper": "mozilla.org",
    "code": "ZIH",
    "github": "Marvin, Connelly and Moen"
  }, {
    "id": 78,
    "authorOneFname": "Alonzo",
    "authorTwoFname": "Imogene",
    "authorThreeFname": "Demetria",
    "authorFourFname": "Lewes",
    "authorFiveFname": "Joellen",
    "journalTitle": "Khalifa University of Science, Technology and Research",
    "journalPublisher": "Odele",
    "publicationLanguage": "Tok Pisin",
    "institution": "Luther College",
    "website": "umich.edu",
    "state": null,
    "preparedDate": 2005,
    "openPaper": "i2i.jp",
    "code": "PUN",
    "github": "O'Hara LLC"
  }, {
    "id": 79,
    "authorOneFname": "Ola",
    "authorTwoFname": "Marmaduke",
    "authorThreeFname": "Howey",
    "authorFourFname": "Borg",
    "authorFiveFname": "Eugenie",
    "journalTitle": "Universidad Mexicana",
    "journalPublisher": "Hermione",
    "publicationLanguage": "Thai",
    "institution": "Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology",
    "website": "reverbnation.com",
    "state": "Ontario",
    "preparedDate": 2019,
    "openPaper": "usatoday.com",
    "code": "RYO",
    "github": "Abbott LLC"
  }, {
    "id": 80,
    "authorOneFname": "Tuesday",
    "authorTwoFname": "Mahmoud",
    "authorThreeFname": "Ynes",
    "authorFourFname": "Claus",
    "authorFiveFname": "Orelia",
    "journalTitle": "Vitebsk State Medical University",
    "journalPublisher": "Nicolette",
    "publicationLanguage": "Mongolian",
    "institution": "Universitas Pancasila",
    "website": "csmonitor.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "tinypic.com",
    "code": "CJC",
    "github": "Bosco-Cummings"
  }, {
    "id": 81,
    "authorOneFname": "Dur",
    "authorTwoFname": "Donnajean",
    "authorThreeFname": "Alford",
    "authorFourFname": "Candide",
    "authorFiveFname": "Mirabella",
    "journalTitle": "Ungku Omar Premier Polytechnic",
    "journalPublisher": "Peria",
    "publicationLanguage": "German",
    "institution": "United States Naval Academy",
    "website": "dmoz.org",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "yelp.com",
    "code": "PDK",
    "github": "Kunde, Bruen and Rippin"
  }, {
    "id": 82,
    "authorOneFname": "Jenna",
    "authorTwoFname": "Winfred",
    "authorThreeFname": "Glennis",
    "authorFourFname": "Nadia",
    "authorFiveFname": "Roda",
    "journalTitle": "Fachhochschule Regensburg",
    "journalPublisher": "Mallissa",
    "publicationLanguage": "Swahili",
    "institution": "Universidad de la Tercera Edad",
    "website": "jugem.jp",
    "state": null,
    "preparedDate": 2020,
    "openPaper": "nih.gov",
    "code": "PJC",
    "github": "O'Reilly Group"
  }, {
    "id": 83,
    "authorOneFname": "Lauri",
    "authorTwoFname": "Nicoli",
    "authorThreeFname": "Mallissa",
    "authorFourFname": "Glynnis",
    "authorFiveFname": "Ruby",
    "journalTitle": "Universitas Bakrie",
    "journalPublisher": "Doll",
    "publicationLanguage": "Zulu",
    "institution": "Deen Dayal Upadhyay Gorakhpur University",
    "website": "github.io",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "weather.com",
    "code": "MTY",
    "github": "Schowalter, Jaskolski and Oberbrunner"
  }, {
    "id": 84,
    "authorOneFname": "Evelyn",
    "authorTwoFname": "Waldemar",
    "authorThreeFname": "Conrado",
    "authorFourFname": "Reagan",
    "authorFiveFname": "Vere",
    "journalTitle": "Technische Universität Clausthal",
    "journalPublisher": "Mercy",
    "publicationLanguage": "Greek",
    "institution": "Universidad Nacional de General San Martín",
    "website": "economist.com",
    "state": null,
    "preparedDate": 2004,
    "openPaper": "geocities.jp",
    "code": "UMS",
    "github": "Nikolaus, Dietrich and Halvorson"
  }, {
    "id": 85,
    "authorOneFname": "Denny",
    "authorTwoFname": "Montague",
    "authorThreeFname": "Aurea",
    "authorFourFname": "Griff",
    "authorFiveFname": "Jobye",
    "journalTitle": "Université de Tunis",
    "journalPublisher": "George",
    "publicationLanguage": "Assamese",
    "institution": "Université Nancy II",
    "website": "tripod.com",
    "state": null,
    "preparedDate": 2012,
    "openPaper": "delicious.com",
    "code": "XJD",
    "github": "Carter and Sons"
  }, {
    "id": 86,
    "authorOneFname": "Charline",
    "authorTwoFname": "Trish",
    "authorThreeFname": "Price",
    "authorFourFname": "Ailbert",
    "authorFiveFname": "Alysa",
    "journalTitle": "Detroit College of Business - Warren",
    "journalPublisher": "Hyacintha",
    "publicationLanguage": "Swati",
    "institution": "University of Arkansas at Little Rock",
    "website": "addthis.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "printfriendly.com",
    "code": "CAY",
    "github": "Feil Group"
  }, {
    "id": 87,
    "authorOneFname": "Bethany",
    "authorTwoFname": "Reggy",
    "authorThreeFname": "Benjamin",
    "authorFourFname": "Antonius",
    "authorFiveFname": "Tann",
    "journalTitle": "University College of Arts, Crafts and Design",
    "journalPublisher": "Zitella",
    "publicationLanguage": "Czech",
    "institution": "Trinity Theological Seminary",
    "website": "ehow.com",
    "state": null,
    "preparedDate": 2021,
    "openPaper": "shutterfly.com",
    "code": "BGV",
    "github": "Padberg-Grant"
  }, {
    "id": 88,
    "authorOneFname": "Carline",
    "authorTwoFname": "Neddie",
    "authorThreeFname": "Katleen",
    "authorFourFname": "Arabella",
    "authorFiveFname": "Gertie",
    "journalTitle": "Fachhochschule Nordhessen",
    "journalPublisher": "Daphne",
    "publicationLanguage": "Haitian Creole",
    "institution": "Universidad Pedagogica Nacional",
    "website": "a8.net",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "reuters.com",
    "code": "NUP",
    "github": "Donnelly-Raynor"
  }, {
    "id": 89,
    "authorOneFname": "Lianne",
    "authorTwoFname": "Teddi",
    "authorThreeFname": "Cole",
    "authorFourFname": "Sayers",
    "authorFiveFname": "Lev",
    "journalTitle": "Clemson University",
    "journalPublisher": "Fredra",
    "publicationLanguage": "Chinese",
    "institution": "St. Joseph's University",
    "website": "alibaba.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "over-blog.com",
    "code": "SZH",
    "github": "Feeney-Hermann"
  }, {
    "id": 90,
    "authorOneFname": "Alie",
    "authorTwoFname": "Petrina",
    "authorThreeFname": "Roman",
    "authorFourFname": "Grover",
    "authorFiveFname": "Cece",
    "journalTitle": "Western Michigan University",
    "journalPublisher": "Britta",
    "publicationLanguage": "Northern Sotho",
    "institution": "Universidade Federal de Roraima",
    "website": "umich.edu",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "china.com.cn",
    "code": "LRD",
    "github": "Strosin Group"
  }, {
    "id": 91,
    "authorOneFname": "Eachelle",
    "authorTwoFname": "Leda",
    "authorThreeFname": "Tallia",
    "authorFourFname": "Harriott",
    "authorFiveFname": "Glen",
    "journalTitle": "Kwan Dong University",
    "journalPublisher": "Wally",
    "publicationLanguage": "Dhivehi",
    "institution": "COMSATS Institute of Information Technology, Lahore",
    "website": "people.com.cn",
    "state": null,
    "preparedDate": 2010,
    "openPaper": "microsoft.com",
    "code": "YOH",
    "github": "Sanford, Marvin and Nienow"
  }, {
    "id": 92,
    "authorOneFname": "Thorstein",
    "authorTwoFname": "Cahra",
    "authorThreeFname": "Cobbie",
    "authorFourFname": "Fania",
    "authorFiveFname": "Benito",
    "journalTitle": "Universidad Pontificia de Salamanca",
    "journalPublisher": "Timothea",
    "publicationLanguage": "Swahili",
    "institution": "Azerbaijan Toursim Institute",
    "website": "biglobe.ne.jp",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "newyorker.com",
    "code": "OKN",
    "github": "Ernser, Osinski and Ziemann"
  }, {
    "id": 93,
    "authorOneFname": "Dalila",
    "authorTwoFname": "Eleanore",
    "authorThreeFname": "Tiena",
    "authorFourFname": "Anderea",
    "authorFiveFname": "Elinore",
    "journalTitle": "National Technical University (Kharkiv Polytechnical Institute)",
    "journalPublisher": "Chloris",
    "publicationLanguage": "Hungarian",
    "institution": "Georgian Technical University",
    "website": "goo.ne.jp",
    "state": null,
    "preparedDate": 2017,
    "openPaper": "ftc.gov",
    "code": "ABL",
    "github": "Cremin-Harber"
  }, {
    "id": 94,
    "authorOneFname": "Flint",
    "authorTwoFname": "Hanny",
    "authorThreeFname": "Cassandra",
    "authorFourFname": "Antonina",
    "authorFiveFname": "Jacquie",
    "journalTitle": "New Hampshire College",
    "journalPublisher": "Winne",
    "publicationLanguage": "Northern Sotho",
    "institution": "The Master's College",
    "website": "ebay.com",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "vinaora.com",
    "code": "YHM",
    "github": "Schiller-Lang"
  }, {
    "id": 95,
    "authorOneFname": "Dion",
    "authorTwoFname": "Minta",
    "authorThreeFname": "Michaeline",
    "authorFourFname": "Lynett",
    "authorFiveFname": "Winifield",
    "journalTitle": "University of Calabria",
    "journalPublisher": "Gwyn",
    "publicationLanguage": "Fijian",
    "institution": "Ecole Centrale de Lille",
    "website": "yolasite.com",
    "state": "Örebro",
    "preparedDate": 2011,
    "openPaper": "drupal.org",
    "code": "IGO",
    "github": "Goodwin-Beer"
  }, {
    "id": 96,
    "authorOneFname": "Andras",
    "authorTwoFname": "Cosmo",
    "authorThreeFname": "Henriette",
    "authorFourFname": "Lloyd",
    "authorFiveFname": "Ed",
    "journalTitle": "University of Hull",
    "journalPublisher": "Flossie",
    "publicationLanguage": "Lithuanian",
    "institution": "Brussels School of International Studies",
    "website": "home.pl",
    "state": "Sor-Trondelag",
    "preparedDate": 2013,
    "openPaper": "g.co",
    "code": "HBA",
    "github": "Goyette, Rodriguez and Ritchie"
  }, {
    "id": 97,
    "authorOneFname": "Lissie",
    "authorTwoFname": "Talyah",
    "authorThreeFname": "Madelin",
    "authorFourFname": "Tanney",
    "authorFiveFname": "Wilbur",
    "journalTitle": "Universitas Padjadjaran",
    "journalPublisher": "Kim",
    "publicationLanguage": "Bosnian",
    "institution": "Gyeongsang National University",
    "website": "e-recht24.de",
    "state": null,
    "preparedDate": 2007,
    "openPaper": "amazonaws.com",
    "code": "CYU",
    "github": "O'Kon Inc"
  }, {
    "id": 98,
    "authorOneFname": "Imelda",
    "authorTwoFname": "Yank",
    "authorThreeFname": "Beatrisa",
    "authorFourFname": "Brigida",
    "authorFiveFname": "Shea",
    "journalTitle": "Islamic Azad University, Semnan",
    "journalPublisher": "Shelbi",
    "publicationLanguage": "Gujarati",
    "institution": "Nayanova University",
    "website": "msn.com",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "infoseek.co.jp",
    "code": "LBD",
    "github": "Cruickshank-Kozey"
  }, {
    "id": 99,
    "authorOneFname": "Seamus",
    "authorTwoFname": "Bjorn",
    "authorThreeFname": "Amelita",
    "authorFourFname": "Colline",
    "authorFiveFname": "Pacorro",
    "journalTitle": "Butler University",
    "journalPublisher": "Kris",
    "publicationLanguage": "Macedonian",
    "institution": "University of Aden",
    "website": "wikipedia.org",
    "state": null,
    "preparedDate": 2018,
    "openPaper": "uol.com.br",
    "code": "JAT",
    "github": "Blick, Kozey and Wintheiser"
  }, {
    "id": 100,
    "authorOneFname": "Evelyn",
    "authorTwoFname": "Mace",
    "authorThreeFname": "Carlota",
    "authorFourFname": "Bear",
    "authorFiveFname": "Marys",
    "journalTitle": "University of Colorado at Denver",
    "journalPublisher": "Gayleen",
    "publicationLanguage": "Czech",
    "institution": "Universidad Abierta Para Adultos",
    "website": "usgs.gov",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "creativecommons.org",
    "code": "ELQ",
    "github": "Metz and Sons"
  }]

  export default page2Data;