import React from 'react'
import '../assets/css/componentFooter.css'
import { Link } from 'react-router-dom'


const Footer = () => {
    return (
        <div>
            <footer id="componentFooter" className="componentFooter">
                <div className="container componentFooter-top">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 componentFooter-about">
                            <a href="index.html" className="logo d-flex align-items-center">
                                <span className="sitename">PPH</span>
                            </a>
                            <div className="componentFooter-contact pt-3">
                                <p>Main Gate Rd, IIT Area,</p>
                                <p>Mumbai, Maharashtra 400076</p>
                                <p className="mt-3">
                                    {/* <strong>Phone:</strong> <span>+1 5589 55488 55</span> */}
                                </p>
                                <p>
                                    <strong>Email:</strong> <span>publish@primepublicationhub.com</span>
                                </p>
                            </div>
                            <div className="social-links d-flex mt-4">
                                <a href>
                                    <i className="bi bi-twitter-x" />
                                </a>
                                <a href>
                                    <i className="bi bi-facebook" />
                                </a>
                                <a href>
                                    <i className="bi bi-instagram" />
                                </a>
                                <a href>
                                    <i className="bi bi-linkedin" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 componentFooter-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <a href="/">About us</a>
                                </li>
                                <li>
                                    <a href="/">Services</a>
                                </li>
                                <li>
                                    <a href="/">Terms of service</a>
                                </li>
                                <li>
                                    <a href="/">Privacy policy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-3 componentFooter-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li>
                                    <a href="/">Content Quality</a>
                                </li>
                                <li>
                                    <a href="/">Ethical Approval</a>
                                </li>
                                <li>
                                    <a href="/">Plagiarism Check</a>
                                </li>
                                <li>
                                    <a href="/">Formatting</a>
                                </li>
                                <li>
                                    <a href="/">More</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-3 componentFooter-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li>
                                    <a href="/">Web Design</a>
                                </li>
                                <li>
                                    <a href="/">Web Development</a>
                                </li>
                                <li>
                                    <a href="/">Product Management</a>
                                </li>
                                <li>
                                    <a href="/">Marketing</a>
                                </li>
                                <li>
                                    <a href="/">Graphic Design</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-3 componentFooter-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li>
                                    <a href="/">Software Development</a>
                                </li>
                                <li>
                                    <a href="/">Cybersecurity</a>
                                </li>
                                <li>
                                    <a href="/">DevOps Services</a>
                                </li>
                                <li>
                                    <a href="/">Cloud Solutions</a>
                                </li>
                                <li>
                                    <a href="/">IoT Solutions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container copyright text-center mt-4">
                    <p>
                        © <span>Copyright</span>{" "}
                        <strong className="px-1 sitename">PPH</strong>{" "}
                        <span>All Rights Reserved</span>|<Link to="/disclaimer">Disclaimer</Link> | <Link to="/privacyandpolicy">Privacy Policy</Link> | <Link to="/termsandconditions">Terms & Conditions</Link>
                    </p>
                </div>
            </footer>

            <a
                href="/"
                id="scroll-top"
                className="scroll-top d-flex align-items-center justify-content-center"
            >
                <i className="bi bi-arrow-up-short" />
            </a>
        </div>

    )
}

export default Footer
