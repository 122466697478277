import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
// import { useState } from 'react';
import '../App.css'


const PaperStatus = () => {
    // const [paperStatus, setPaperStatus] = useState('');
    return (
        <div>
            <Navbar />
            <div className='paperStatus'>
                <div className="card">
                    <div className="input-group ">
                        <h5 className="card-header">Author Id</h5>
                        <input type="text" className="form-control" placeholder="Search by id" aria-label="Recipient's username" aria-describedby="basic-addon2" /* onChange={(e) => { setPaperStatus(e.target.value) }}*/ />
                        <span className="input-group-text" id="basic-addon2"><img className='searchIcon' src="https://img.icons8.com/?size=100&id=59878&format=png&color=000000" alt="icon-not-found" /></span>
                    </div>
                    <h1>Paper status</h1>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PaperStatus
