import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import data from '../Data/Data'
import '../App.css'

const ExploreAll = () => {

    const [search, setSearch] = useState("");
    console.log(search);

    // When the user clicks on the button, scroll to the top of the document
    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    const dataPublication = data.filter((data) => {
        return search.toLowerCase() === '' ? data : data.journalTitle.toLowerCase().includes(search)
    }).map((data) => {

        return (
            <div className='publication-card' key={data._id}>
                    <p>
                        <span className='authors'>{data.authorOneFname}{data.authorTwoFname ? ", " : ""}{data.authorTwoFname}{data.authorThreeFname ? ", " : ""}{data.authorThreeFname}{data.authorFourFname ? ", " : ""}{data.authorFourFname}{data.authorFiveFname ? ", " : ""}{data.authorFiveFname},{" "}</span>
                        <span className='journalTitle'> 
                        {/* <a href={data.journalLink}>{data.journalTitle}{" "}</a> */}
                            <Link className='publication-card-link' to={data.journalLink}>
                                {data.journalTitle},{" "}
                            </Link>
                        </span>
                        <span className='journalPublisher'>{data.journalPublisher},{" "}</span>
                        <span className='publicationLanguage'>{data.publicationLanguage},{" "}</span>
                        <span className='institution'>{data.institution},{" "}</span>
                        <span className='website'>
                            <Link className='publication-card-link' to={data.website}>
                                {data.website},{" "}
                            </Link>
                        </span>
                        <span className='state'>{data.state},{" "}</span>
                        <span className='preparedDate'>{data.preparedDate},{" "}</span>
                        <span className='openPaper'>
                            [<Link to={data.journalLink}>Open</Link>]
                        </span>
                        {data.code === "" ? null : <span className='code'>[<Link to={data.code}>Code</Link>]</span>}</p>
                    <hr />
                </div>
        )
    })


    return (
        <div>
            <Navbar />
            <div className="sectionTwoPublications">
                <div className="research-paper-component" >
                    <div className="research-paper-component-item">
                        <div className="card">
                            <div className="input-group mb-3">
                                <h5 className="card-header">Publications</h5>
                                <input type="text" className="form-control" placeholder="Search by title" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => { setSearch(e.target.value) }} />
                                <span className="input-group-text" id="basic-addon2"><img className='searchIcon' src="https://img.icons8.com/?size=100&id=59878&format=png&color=000000" alt="icon-not-found" /></span>
                            </div>
                            <div className="card-body">
                                {dataPublication}
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a class="page-link" href="/" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li class="page-item"><Link to='/explore-all'><a class="page-link" href="/" id='myPgBtn' onClick={topFunction}>1</a></Link></li>
                                    <li class="page-item"><Link to='/explore-all/page2'><a class="page-link" href="/" id='myPgBtn' onClick={topFunction}>2</a></Link></li>
                                    <li class="page-item"><Link to='/explore-all/page3'><a class="page-link" href="/" id='myPgBtn' onClick={topFunction}>3</a></Link></li>
                                    <li class="page-item"><Link to='/explore-all/page4'><a class="page-link" href="/" id='myPgBtn' onClick={topFunction}>4</a></Link></li>
                                    <li class="page-item"><Link to='/explore-all/page5'><a class="page-link" href="/" id='myPgBtn' onClick={topFunction}>5</a></Link></li>
                                    <li class="page-item">
                                        <a class="page-link" href="/" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default ExploreAll
