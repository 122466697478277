const Data = [
  {
    "id": 4,
    "authorId": 2412003,
    "journalTitle": "Computational and Numerical Methods for Combinatorial Geometric Series and its Applications",
    "journalPublisher": "Chinnaraji Annamalai",
    "institution": "Indian Institute of Technology, Kharagpur",
    "website": "www.iitkgp.ac.in",
    "authorOneFname": "Chinnaraji Annamalai",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "journalLink":"computational-and-numerical-methods-for-combinatorial-geometric-series-and-its-applications",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",

}, {
    "id": 17,
    "authorId": 2412016,
    "journalTitle": "An Exploration Of The Quantum Frontier In Data Analytics And Computational Intelligence",
    "journalPublisher": "Subharun Pal",
    "journalLink":"an-exploration-of-the-quantum-frontier-in-data-analytics-and-computational-intelligence",
    "institution": "Indian Institute of Technology Jammu",
    "website": "www.iitjammu.ac.in",
    "authorOneFname": "Subharun Pal",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",

},
{
    "id": 25,
    "authorId": 2412024,
    "journalTitle": "The Role of Quantum Computing in Advancing Cross-disciplinary Informatics",
    "journalPublisher": "Erna Hudianti Pujiarini",
    "journalLink":"the-role-of-quantum-computing-in-advancing-cross-disciplinary-informatics",
    "institution": "Universitas Teknologi Digital Indonesia",
    "website": "",
    "authorOneFname": "Ira Zulfa",
    "authorTwoFname": "Asmadi",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


},
{
    "id": 5,
    "authorId": 2412004,
    "authorOneFname": "Singaraju Jyothi",
    "authorTwoFname": "Bhargavi P",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "journalTitle": "Data Science and Computational Biology",
    "journalPublisher": "Singaraju Jyothi",
    "journalLink":"data-science-and-computational-biology",
    "publicationLanguage": "English",
    "institution": "Sri Padmavati Mahila University, India",
    "website": "www.spmvv.ac.in",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


},
{
    "id": 6,
    "authorId": 2412005,
    "journalTitle": "Enhancing Future Link Prediction in Quantum Computing Semantic Networks through LLM-Initiated Node Features",
    "journalPublisher": "Gilchan Park",
    "journalLink":"enhancing-future-link-prediction-in-quantum-computing-semantic-networks-through-llm-initiated-node-features",
    "institution": "Brookhaven National Laboratory, Computational Science Initiative Upton",
    "website": "www.bnl.gov",
    "authorOneFname": "Gilchan Park",
    "authorTwoFname": "Paul Baity",
    "authorThreeFname": "Byung-Jun Yoon",
    "authorFourFname": "Adolfy Hoisie ",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


},
{
    "id": 7,
    "authorId": 2412006,
    "journalTitle": "Envisioning a Computing Continuum for Science",
    "journalPublisher": "Manish Parashar",
    "journalLink":"envisioning-a-computing-continuum-for-science",
    "institution": "Scientific Computing and Imaging (SCI) Institute",
    "website": "www.utah.edu",
    "authorOneFname": "Manish Parashar",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",

},
{
  "id": 1,
  'authorId': 2412000,
  "authorOneFname": "Ocotlán Díaz-Parra",
  "authorTwoFname": "Jorge A. Ruiz-Vanoye",
  "authorThreeFname": "Alejandro Fuentes-Penna",
  "authorFourFname": "Ricardo A. Barrera-Cámara",
  "journalTitle": "A brief review of the History of Computational Intelligence, Bio-Inspired Computing and Scientific Computing ",
  "journalLink": "a-brief-review-of-the-history-of-computational-intelligence",
  "journalPublisher": "Ocotlán Díaz-Parra",
  "publicationLanguage": "English",
  "institution": " Editorial Académica Dragón Azteca",
  "website": "www.editada.org",
  "state": 'Mexico',
  "preparedDate": ' Jun 26, 2024 ',
  "openPaper": "imgur.com",
  "code": "",
}, {
  "id": 2,
  "authorId": 2412001,
  "authorOneFname": "K. Shan",
  "authorTwoFname": "Huang. J",
  "authorThreeFname": "Salmon. J.",
  "authorFourFname": "",
  "authorFiveFname": "",
  "journalTitle": "Advancements and Future Directions in Molecular Dynamics (MD) Simulations",
  "journalPublisher": "Aline Clementine Beatrice",
  "publicationLanguage": "English",
  "institution": "Kampala International University Uganda",
  "website": "",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",

},
{
  "id": 3,
  "authorId": 2412002,
  "authorOneFname": "Parag Mhashilkar",
  "authorTwoFname": "Anthony Tiradani",
  "authorThreeFname": "Burt Holzman",
  "authorFourFname": "Mats Rynge",
  "authorFiveFname": "",
  "journalTitle": "Cloud Bursting with GlideinWMS: Means to satisfy ever increasing computing needs for Scientific Workflows",
  "journalPublisher": "Information Sciences Institute ",
  "publicationLanguage": "English",
  "institution": "University of California at San Diego",
  "website": "www.isi.edu",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",


},
{
  "id": 4,
  "authorId": 2412003,
  "journalTitle": "Computational and Numerical Methods for Combinatorial Geometric Series and its Applications",
  "journalPublisher": "Chinnaraji Annamalai",
  "institution": "Indian Institute of Technology, Kharagpur",
  "website": "www.iitkgp.ac.in",
  "authorOneFname": "Chinnaraji Annamalai",
  "authorTwoFname": "",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",


},
{
  "id": 5,
  "authorId": 2412004,
  "authorOneFname": "Singaraju Jyothi",
  "authorTwoFname": "Bhargavi P",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "journalTitle": "Data Science and Computational Biology",
  "journalPublisher": "Singaraju Jyothi",
  "publicationLanguage": "English",
  "institution": "Sri Padmavati Mahila University, India",
  "website": "www.spmvv.ac.in",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",


},
{
  "id": 6,
  "authorId": 2412005,
  "journalTitle": "Enhancing Future Link Prediction in Quantum Computing Semantic Networks through LLM-Initiated Node Features",
  "journalPublisher": "Gilchan Park",
  "institution": "Brookhaven National Laboratory, Computational Science Initiative Upton",
  "website": "www.bnl.gov",
  "authorOneFname": "Gilchan Park",
  "authorTwoFname": "Paul Baity",
  "authorThreeFname": "Byung-Jun Yoon",
  "authorFourFname": "Adolfy Hoisie ",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",


},
{
  "id": 7,
  "authorId": 2412006,
  "journalTitle": "Everywhere & Nowhere: Envisioning a Computing Continuum for Science",
  "journalPublisher": "Manish Parashar",
  "institution": "Scientific Computing and Imaging (SCI) Institute",
  "website": "www.utah.edu",
  "authorOneFname": "Manish Parashar",
  "authorTwoFname": "",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",

},
{
  "id": 8,
  "authorId": 2412007,
  "journalTitle": "EXPLORATORY RESEARCH OPPORTUNITIES OF COMPUTING IN LIFE SCIENCES",
  "journalPublisher": "Dr.Vignesh Ramamoorthy H ",
  "institution": "Sri Krishna Arts and Science College",
  "website": "www.skasc.ac.in",
  "authorOneFname": "Dr.Vignesh Ramamoorthy H ",
  "authorTwoFname": "Dr.Jeen Marseline K.S",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",

},
{
  "id": 9,
  "authorId": 2412008,
  "journalTitle": "Fractals fractional ai based analyses and applications to complex systems",
  "journalPublisher": "Lebanese American University",
  "institution": "University of Massachusetts Chan Medical School",
  "website": "www.lau.edu.lb",
  "authorOneFname": "Dumitru Baleanu",
  "authorTwoFname": "Majaz Moonis",
  "authorThreeFname": "Yu-Dong Zhang",
  "authorFourFname": "Osvaldo Gervasi",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
},
{
  "id": 10,
  "authorId": 2412009,
  "journalTitle": "Future for Scientific Computing Using Python Vol. 2, No. 1(2015)",
  "journalPublisher": "Rakesh Kumar ",
  "institution": "International Journal of Engineering Technologies and Management Research",
  "website": "www.ijetmr.com",
  "authorOneFname": "Rakesh Kumar ",
  "authorTwoFname": "",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
},
{
  "id": 11,
  "authorId": 2412010,
  "journalTitle": "Hardware and Software Solutions for Energy-Efficient Computing in Scientific Programming",
  "journalPublisher": "Cristian Mateos",
  "institution": "University of Turin",
  "website": "www.imati.cnr.it",
  "authorOneFname": "Ivan Merelli",
  "authorTwoFname": "Marco Aldinucci",
  "authorThreeFname": "Daniele Cesini",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": 'Turin',
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
},
{
  "id": 12,
  "authorId": 2412011,
  "journalTitle": "Impact of Quantum Computing in Quantum Supremacy and Parallel Universes",
  "journalPublisher": "Rabab Khan Rongon",
  "institution": "Department of Computer Science and Engineering School of Engineering and Technology IUBAT",
  "website": "www.iubat.edu",
  "authorOneFname": "Rabab Khan Rongon",
  "authorTwoFname": "",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": 'Dhaka',
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
},
{
  "id": 13,
  "authorId": 2412012,
  "journalTitle": "INFORMATION TECHNOLOGIES DRIVING INNOVATION IN BIOLOGICAL SCIENCE: A FOCUS ON BIOINFORMATICS APPLICATION DEVELOPMENT",
  "journalPublisher": "Tariq Rafique",
  "institution": "University of Agriculture Faisalabad",
  "website": "",
  "authorOneFname": "Zeshan Anwar",
  "authorTwoFname": "Abdur Rahman",
  "authorThreeFname": "Tariq Rafique",
  "authorFourFname": "Hrishitva Patel",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 14,
  "authorId": 2412013,
  "journalTitle": "Machine Learning Applications to Computational Plasma Physics and Reduced-Order Plasma Modeling",
  "journalPublisher": "Farbod Faraj",
  "institution": "Department of Aeronautics, Imperial College",
  "website": "www.imperial.ac.uk",
  "authorOneFname": "Maryam Reza",
  "authorTwoFname": "Farbod Faraj",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": "London",
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
},
{
  "id": 15,
  "authorId": 2412014,
  "journalTitle": "Nanowires: Exponential speedup in quantum computing",
  "journalPublisher": "Heliyon",
  "institution": "Department of Computer Science & Engineering, IUBAT",
  "website": "www.cell.com/heliyon",
  "authorOneFname": "Mariam Akter Mimona",
  "authorTwoFname": "Emtiuz Ahmed",
  "authorThreeFname": "Farzana Kamal ",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
},
{
  "id": 16,
  "authorId": 2412015,
  "journalTitle": "Quantum Cohesion: Bridging Discrete Models and Continuous Systems in Computational Physics",
  "journalPublisher": "Douglas C",
  "institution": "",
  "website": "youvan.com",
  "authorOneFname": "Douglas C",
  "authorTwoFname": "",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 17,
  "authorId": 2412016,
  "journalTitle": "QUANTUM COMPUTING AND ALGORITHMS: AN EXPLORATION OF THE QUANTUM FRONTIER IN DATA ANALYTICS AND COMPUTATIONAL INTELLIGENCE",
  "journalPublisher": "Subharun Pal",
  "institution": "Indian Institute of Technology Jammu",
  "website": "www.iitjammu.ac.in",
  "authorOneFname": "Subharun Pal",
  "authorTwoFname": "",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 18,
  "authorId": 2412017,
  "journalTitle": "Quantum Computing at the Frontiers of Biological Sciences",
  "journalPublisher": "Prashant S. Emani",
  "institution": "University of California",
  "website": "",
  "authorOneFname": "Jonathan Warrell",
  "authorTwoFname": "Stefan Bekiranov",
  "authorThreeFname": "Michael Gandal",
  "authorFourFname": "Jacob Taylor",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": 'Los Angeles',
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 19,
  "authorId": 2412018,
  "journalTitle": "TRANSFORMATIVE APPLICATIONS AND PERSISTENT CHALLENGES IN THE DIGITAL AGE",
  "journalPublisher": "Harish Kumar Reddy Kommera",
  "institution": "Jawaharlal Nehru Technological University",
  "website": "",
  "authorOneFname": "Jawaharlal Nehru Technological University",
  "authorTwoFname": "",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 20,
  "authorId": 2412019,
  "journalTitle": "Quantum mechanical methods in computational chemistry",
  "journalPublisher": "Odette Agnes Regina",
  "institution": "Kampala International University Uganda",
  "website": "",
  "authorOneFname": "Agnes Regina",
  "authorTwoFname": "",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 21,
  "authorId": 2412020,
  "journalTitle": "Quantum Prophecy: Exploring the Role of Divinity and Pantheism in Future Quantum Computing Systems",
  "journalPublisher": "Douglas C",
  "institution": "",
  "website": "youvan.com",
  "authorOneFname": "Douglas C",
  "authorTwoFname": "",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 22,
  "authorId": 2412021,
  "journalTitle": "RECONFIGURABLE COMPUTING FOR COMPUTATIONAL SCIENCE: A NEW FOCUS IN HIGH PERFORMANCE COMPUTING",
  "journalPublisher": "Dale Shires",
  "institution": "Vincent Natoli and David Richie Stone Ridge Technology, Inc",
  "website": "",
  "authorOneFname": "Brian Henz",
  "authorTwoFname": "Dale Shires",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 23,
  "authorId": 2412022,
  "journalTitle": "Regional-scale fault-to- structure earthquake simulations with the EQSIM framework",
  "journalPublisher": "David McCallen",
  "institution": "Lawrence Berkeley National Laboratory",
  "website": "",
  "authorOneFname": "Ramesh Pankajakshan",
  "authorTwoFname": "N Anders Petersson",
  "authorThreeFname": "Mamun Miah",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": "Berkeley",
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 24,
  "authorId": 2412023,
  "journalTitle": " A Computational Physics Case Study",
  "journalPublisher": "Willow Veytsman",
  "institution": "University of Rochester",
  "website": "www.rochester.edu",
  "authorOneFname": "Willow Veytsman",
  "authorTwoFname": "Chen Ding",
  "authorThreeFname": "Shuang Zhai",
  "authorFourFname": "Adam B. Sefkow",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": "Rochester",
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 25,
  "authorId": 2412024,
  "journalTitle": "The Role of Quantum Computing in Advancing Cross-disciplinary Informatics",
  "journalPublisher": "Erna Hudianti Pujiarini",
  "institution": "Universitas Teknologi Digital Indonesia",
  "website": "",
  "authorOneFname": "Ira Zulfa",
  "authorTwoFname": "Asmadi",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": null,
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, {
  "id": 26,
  "authorId": 2412025,
  "journalTitle": "Why are some students “not into” computational thinking activities embedded within high school science units? Key takeaways from a microethnographic discourse analysis study",
  "journalPublisher": "Umit Aslan",
  "institution": "Department of Learning Sciences, Northwestern University",
  "website": "www.northwestern.edu",
  "authorOneFname": "Michael Horn",
  "authorTwoFname": "Uri Wilensky",
  "authorThreeFname": "",
  "authorFourFname": "",
  "authorFiveFname": "",
  "publicationLanguage": "English",
  "state": "Evanston",
  "preparedDate": 2024,
  "openPaper": "",
  "code": "",
}, 
// {
//   "id": 27,
//   "author": 2412026,
//   "journalTitle": "",
//   "journalPublisher": " ",
//   "institution": "",
//   "website": "",
//   "authorOneFname": " ",
//   "authorTwoFname": "",
//   "authorThreeFname": "",
//   "authorFourFname": "",
//   "authorFiveFname": "",
//   "publicationLanguage": "English",
//   "state": null,
//   "preparedDate": 2024,
//   "openPaper": "",
//   "code": "",
// }, {
//   "id": 28,
//   "authorId": 2412027,
//   "journalTitle": "",
//   "journalPublisher": " ",
//   "institution": "",
//   "website": "",
//   "authorOneFname": " ",
//   "authorTwoFname": "",
//   "authorThreeFname": "",
//   "authorFourFname": "",
//   "authorFiveFname": "",
//   "publicationLanguage": "English",
//   "state": null,
//   "preparedDate": 2024,
//   "openPaper": "",
//   "code": "",
// }, {
//   "id": 29,
//   "authorId": 2412028,
//   "journalTitle": "",
//   "journalPublisher": " ",
//   "institution": "",
//   "website": "",
//   "authorOneFname": " ",
//   "authorTwoFname": "",
//   "authorThreeFname": "",
//   "authorFourFname": "",
//   "authorFiveFname": "",
//   "publicationLanguage": "English",
//   "state": null,
//   "preparedDate": 2024,
//   "openPaper": "",
//   "code": "",
// }


]
export default Data;