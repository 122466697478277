const page3Data = [{
    "id": 1,
    "authorOneFname": "Bill",
    "authorTwoFname": "Petr",
    "authorThreeFname": "Saunderson",
    "authorFourFname": "Andrej",
    "authorFiveFname": "Zelig",
    "journalTitle": "Universitas Muria Kudus",
    "journalPublisher": "Eden",
    "publicationLanguage": "Afrikaans",
    "institution": "Hanoi University of Architecture",
    "website": "mayoclinic.com",
    "state": null,
    "preparedDate": 0,
    "openPaper": "microsoft.com",
    "code": "MGP",
    "github": "Smitham-Nikolaus"
  }, {
    "id": 2,
    "authorOneFname": "Homere",
    "authorTwoFname": "Fulton",
    "authorThreeFname": "Blakeley",
    "authorFourFname": "Stacy",
    "authorFiveFname": "Elijah",
    "journalTitle": "Universidad Luterana Salvadorena",
    "journalPublisher": "Jody",
    "publicationLanguage": "Lithuanian",
    "institution": "Tokyo National University of Fine Arts and Music",
    "website": "constantcontact.com",
    "state": null,
    "preparedDate": 2005,
    "openPaper": "squarespace.com",
    "code": "HPA",
    "github": "Lueilwitz-Stamm"
  }, {
    "id": 3,
    "authorOneFname": "Nissa",
    "authorTwoFname": "Lorrie",
    "authorThreeFname": "Dino",
    "authorFourFname": "Mandy",
    "authorFiveFname": "Grier",
    "journalTitle": "California Institute of Technology",
    "journalPublisher": "Chiarra",
    "publicationLanguage": "Polish",
    "institution": "Kisii University",
    "website": "techcrunch.com",
    "state": "Québec",
    "preparedDate": 2016,
    "openPaper": "weibo.com",
    "code": "BOQ",
    "github": "O'Kon and Sons"
  }, {
    "id": 4,
    "authorOneFname": "Danila",
    "authorTwoFname": "Annetta",
    "authorThreeFname": "Colet",
    "authorFourFname": "Kirby",
    "authorFiveFname": "Hoebart",
    "journalTitle": "Moldova Technical University",
    "journalPublisher": "Betsey",
    "publicationLanguage": "Fijian",
    "institution": "ITT Technical Institute Maitland",
    "website": "skyrock.com",
    "state": null,
    "preparedDate": 2018,
    "openPaper": "sciencedaily.com",
    "code": "AUJ",
    "github": "Klein, Hansen and Rice"
  }, {
    "id": 5,
    "authorOneFname": "Mireille",
    "authorTwoFname": "Monika",
    "authorThreeFname": "Percival",
    "authorFourFname": "Pepe",
    "authorFiveFname": "Robyn",
    "journalTitle": "University of Cape Coast",
    "journalPublisher": "Almire",
    "publicationLanguage": "Bosnian",
    "institution": "Ryazan State Pedagogical University",
    "website": "bbc.co.uk",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "ning.com",
    "code": "ACA",
    "github": "Stracke-McDermott"
  }, {
    "id": 6,
    "authorOneFname": "Violante",
    "authorTwoFname": "Reg",
    "authorThreeFname": "Reba",
    "authorFourFname": "Roda",
    "authorFiveFname": "Cully",
    "journalTitle": "Epoka University",
    "journalPublisher": "Coleen",
    "publicationLanguage": "Dari",
    "institution": "Queen Arwa University",
    "website": "google.com.hk",
    "state": null,
    "preparedDate": 2019,
    "openPaper": "dropbox.com",
    "code": "FEB",
    "github": "Jakubowski, Hirthe and Kuvalis"
  }, {
    "id": 7,
    "authorOneFname": "Ros",
    "authorTwoFname": "Ephrem",
    "authorThreeFname": "Hill",
    "authorFourFname": "Pail",
    "authorFiveFname": "Bennie",
    "journalTitle": "University of Maiduguri",
    "journalPublisher": "Celinka",
    "publicationLanguage": "Afrikaans",
    "institution": "University of Pavia",
    "website": "weather.com",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "berkeley.edu",
    "code": "OTD",
    "github": "Mills and Sons"
  }, {
    "id": 8,
    "authorOneFname": "Donielle",
    "authorTwoFname": "Jorie",
    "authorThreeFname": "Hephzibah",
    "authorFourFname": "Caryl",
    "authorFiveFname": "Reiko",
    "journalTitle": "Université M'hamed Bouguerra de Boumerdes",
    "journalPublisher": "Davine",
    "publicationLanguage": "Polish",
    "institution": "Université Alioune Diop de Bambey",
    "website": "google.com.hk",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "webs.com",
    "code": "HOR",
    "github": "Nicolas-Ward"
  }, {
    "id": 9,
    "authorOneFname": "Luciano",
    "authorTwoFname": "Jaquelyn",
    "authorThreeFname": "Auguste",
    "authorFourFname": "Fredericka",
    "authorFiveFname": "Phaedra",
    "journalTitle": "Textile Institute of Pakistan",
    "journalPublisher": "Marta",
    "publicationLanguage": "West Frisian",
    "institution": "Isra University",
    "website": "guardian.co.uk",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "eepurl.com",
    "code": "MNB",
    "github": "Smitham-Lang"
  }, {
    "id": 10,
    "authorOneFname": "Kariotta",
    "authorTwoFname": "Opal",
    "authorThreeFname": "Moe",
    "authorFourFname": "Carroll",
    "authorFiveFname": "Kendal",
    "journalTitle": "Milwaukee Institute of Art and Design",
    "journalPublisher": "Patrica",
    "publicationLanguage": "Papiamento",
    "institution": "Central Michigan University",
    "website": "fotki.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "chronoengine.com",
    "code": "POV",
    "github": "Ryan Group"
  }, {
    "id": 11,
    "authorOneFname": "Kurt",
    "authorTwoFname": "Morgan",
    "authorThreeFname": "Corabel",
    "authorFourFname": "Blake",
    "authorFiveFname": "Ilse",
    "journalTitle": "Institut Supérieur d'Agriculture Rhone-Alpes",
    "journalPublisher": "Melly",
    "publicationLanguage": "Macedonian",
    "institution": "Universitas Ibn Khaldun Bogor",
    "website": "goo.gl",
    "state": "Rhône-Alpes",
    "preparedDate": 2004,
    "openPaper": "reddit.com",
    "code": "RBL",
    "github": "Hamill-Stamm"
  }, {
    "id": 12,
    "authorOneFname": "Terrell",
    "authorTwoFname": "Milli",
    "authorThreeFname": "Peggy",
    "authorFourFname": "Flori",
    "authorFiveFname": "Teddy",
    "journalTitle": "TaTung University ",
    "journalPublisher": "Othilia",
    "publicationLanguage": "Maltese",
    "institution": "Université des Antilles et de la Guyane",
    "website": "ibm.com",
    "state": null,
    "preparedDate": 2001,
    "openPaper": "samsung.com",
    "code": "DCG",
    "github": "Ryan-Friesen"
  }, {
    "id": 13,
    "authorOneFname": "Shelley",
    "authorTwoFname": "Brew",
    "authorThreeFname": "Kellby",
    "authorFourFname": "Courtenay",
    "authorFiveFname": "Kerstin",
    "journalTitle": "University of Central Texas",
    "journalPublisher": "Marline",
    "publicationLanguage": "Zulu",
    "institution": "University of Genoa",
    "website": "prlog.org",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "vinaora.com",
    "code": "MAB",
    "github": "Streich, Ritchie and Davis"
  }, {
    "id": 14,
    "authorOneFname": "Deanne",
    "authorTwoFname": "Aeriel",
    "authorThreeFname": "Etan",
    "authorFourFname": "Gay",
    "authorFiveFname": "Moritz",
    "journalTitle": "McMurry University",
    "journalPublisher": "Jillane",
    "publicationLanguage": "Haitian Creole",
    "institution": "Mississippi Valley State University",
    "website": "smugmug.com",
    "state": null,
    "preparedDate": 2020,
    "openPaper": "ucoz.ru",
    "code": "PHO",
    "github": "Grady-Steuber"
  }, {
    "id": 15,
    "authorOneFname": "Arlana",
    "authorTwoFname": "Tonye",
    "authorThreeFname": "Timotheus",
    "authorFourFname": "Aldric",
    "authorFiveFname": "Kaela",
    "journalTitle": "Dirección General de Institutos Tecnológicos",
    "journalPublisher": "Noellyn",
    "publicationLanguage": "Croatian",
    "institution": "Logan College of Chiropractic",
    "website": "whitehouse.gov",
    "state": null,
    "preparedDate": 2020,
    "openPaper": "state.gov",
    "code": "EVD",
    "github": "Grimes-Vandervort"
  }, {
    "id": 16,
    "authorOneFname": "Waylen",
    "authorTwoFname": "Dela",
    "authorThreeFname": "Augustine",
    "authorFourFname": "Ilsa",
    "authorFiveFname": "Adara",
    "journalTitle": "Lees-McRae College",
    "journalPublisher": "Anne-corinne",
    "publicationLanguage": "Guaraní",
    "institution": "Free University Institute \"Carlo Cattaneo\"",
    "website": "cbc.ca",
    "state": null,
    "preparedDate": 2012,
    "openPaper": "yellowbook.com",
    "code": "EIL",
    "github": "Medhurst Group"
  }, {
    "id": 17,
    "authorOneFname": "Fredrika",
    "authorTwoFname": "Mureil",
    "authorThreeFname": "Nevile",
    "authorFourFname": "Sauncho",
    "authorFiveFname": "Drona",
    "journalTitle": "Widener University",
    "journalPublisher": "Audry",
    "publicationLanguage": "Kurdish",
    "institution": "Shizuoka University",
    "website": "free.fr",
    "state": null,
    "preparedDate": 2010,
    "openPaper": "about.me",
    "code": "FRM",
    "github": "Mertz, Lowe and Volkman"
  }, {
    "id": 18,
    "authorOneFname": "Jess",
    "authorTwoFname": "Alexandra",
    "authorThreeFname": "Estrellita",
    "authorFourFname": "Glen",
    "authorFiveFname": "Abbe",
    "journalTitle": "Fujian Agricultural University",
    "journalPublisher": "Tyne",
    "publicationLanguage": "Swati",
    "institution": "Keller Graduate School of Management",
    "website": "microsoft.com",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "phoca.cz",
    "code": "0",
    "github": "Spencer, Boyer and Wunsch"
  }, {
    "id": 19,
    "authorOneFname": "Beret",
    "authorTwoFname": "Alfreda",
    "authorThreeFname": "Andras",
    "authorFourFname": "Rob",
    "authorFiveFname": "Jocelyn",
    "journalTitle": "Université de Versailles Saint-Quentin-en-Yvelines",
    "journalPublisher": "Daphne",
    "publicationLanguage": "Dutch",
    "institution": "Meru University of Science and Technology",
    "website": "stanford.edu",
    "state": null,
    "preparedDate": 2010,
    "openPaper": "github.com",
    "code": "DSN",
    "github": "Pfeffer and Sons"
  }, {
    "id": 20,
    "authorOneFname": "Bryn",
    "authorTwoFname": "Nina",
    "authorThreeFname": "Northrop",
    "authorFourFname": "Erick",
    "authorFiveFname": "Danni",
    "journalTitle": "Durham College",
    "journalPublisher": "Siouxie",
    "publicationLanguage": "Luxembourgish",
    "institution": "Helsinki University of Technology",
    "website": "angelfire.com",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "auda.org.au",
    "code": "XSP",
    "github": "Gusikowski LLC"
  }, {
    "id": 21,
    "authorOneFname": "Angelina",
    "authorTwoFname": "Kenneth",
    "authorThreeFname": "Ninette",
    "authorFourFname": "Ethelda",
    "authorFiveFname": "Hetty",
    "journalTitle": "Antioch University Los Angeles",
    "journalPublisher": "Junette",
    "publicationLanguage": "Icelandic",
    "institution": "Chongqing University",
    "website": "ucsd.edu",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "behance.net",
    "code": "AKF",
    "github": "Monahan, Walker and Pfannerstill"
  }, {
    "id": 22,
    "authorOneFname": "Roshelle",
    "authorTwoFname": "Padraig",
    "authorThreeFname": "Melinde",
    "authorFourFname": "Edie",
    "authorFiveFname": "Abramo",
    "journalTitle": "Islamic Azad University, Bandar Abbas",
    "journalPublisher": "Orelle",
    "publicationLanguage": "Oriya",
    "institution": "University of New Brunswick, Saint John",
    "website": "home.pl",
    "state": "Västernorrland",
    "preparedDate": 2015,
    "openPaper": "newsvine.com",
    "code": "UKT",
    "github": "Kirlin Group"
  }, {
    "id": 23,
    "authorOneFname": "Phillie",
    "authorTwoFname": "Dirk",
    "authorThreeFname": "Elayne",
    "authorFourFname": "Meade",
    "authorFiveFname": "Selinda",
    "journalTitle": "Zarka Private University",
    "journalPublisher": "Mavis",
    "publicationLanguage": "Haitian Creole",
    "institution": "Dicle (Tirgris) University",
    "website": "intel.com",
    "state": null,
    "preparedDate": 2010,
    "openPaper": "homestead.com",
    "code": "ILD",
    "github": "Kuhlman LLC"
  }, {
    "id": 24,
    "authorOneFname": "Joey",
    "authorTwoFname": "Hiram",
    "authorThreeFname": "Petronille",
    "authorFourFname": "Suzann",
    "authorFiveFname": "Gael",
    "journalTitle": "Universidade Mackenzie",
    "journalPublisher": "Norrie",
    "publicationLanguage": "Norwegian",
    "institution": "Isra University",
    "website": "forbes.com",
    "state": "Florida",
    "preparedDate": 2015,
    "openPaper": "ocn.ne.jp",
    "code": "UKA",
    "github": "Moore, Gottlieb and Schowalter"
  }, {
    "id": 25,
    "authorOneFname": "Olympie",
    "authorTwoFname": "Tomaso",
    "authorThreeFname": "Ruprecht",
    "authorFourFname": "Felicia",
    "authorFiveFname": "Aleece",
    "journalTitle": "Soonchunhyang University",
    "journalPublisher": "Georgia",
    "publicationLanguage": "Tsonga",
    "institution": "Universidad Columbia del Paraguay",
    "website": "ow.ly",
    "state": "Île-de-France",
    "preparedDate": 2013,
    "openPaper": "dell.com",
    "code": "FBS",
    "github": "Barton-Moen"
  }, {
    "id": 26,
    "authorOneFname": "Griff",
    "authorTwoFname": "Hamlin",
    "authorThreeFname": "Billye",
    "authorFourFname": "Myrta",
    "authorFiveFname": "Onfre",
    "journalTitle": "University of Manchester",
    "journalPublisher": "Jaquith",
    "publicationLanguage": "Polish",
    "institution": "Balamand University",
    "website": "omniture.com",
    "state": null,
    "preparedDate": 2018,
    "openPaper": "nymag.com",
    "code": "BNO",
    "github": "Homenick and Sons"
  }, {
    "id": 27,
    "authorOneFname": "Ardelis",
    "authorTwoFname": "Sanford",
    "authorThreeFname": "Freddie",
    "authorFourFname": "Barret",
    "authorFiveFname": "Zebulen",
    "journalTitle": "Vietnam Maritime University",
    "journalPublisher": "Iona",
    "publicationLanguage": "Hungarian",
    "institution": "Technische Universität Darmstadt",
    "website": "webs.com",
    "state": null,
    "preparedDate": 2009,
    "openPaper": "ca.gov",
    "code": "PCU",
    "github": "Schaden-Donnelly"
  }, {
    "id": 28,
    "authorOneFname": "Gwenora",
    "authorTwoFname": "Shelbi",
    "authorThreeFname": "Arleyne",
    "authorFourFname": "Alicia",
    "authorFiveFname": "Honey",
    "journalTitle": "University of Nueva Caceres",
    "journalPublisher": "Emlynne",
    "publicationLanguage": "Marathi",
    "institution": "American InterContinental University - Atlanta",
    "website": "hubpages.com",
    "state": null,
    "preparedDate": 2008,
    "openPaper": "imgur.com",
    "code": "TLS",
    "github": "Aufderhar Inc"
  }, {
    "id": 29,
    "authorOneFname": "Jarib",
    "authorTwoFname": "Krishna",
    "authorThreeFname": "Sabrina",
    "authorFourFname": "Benita",
    "authorFiveFname": "Perl",
    "journalTitle": "Katholische Fachhochschule Freiburg, Hochschule für Sozialwesen, Religionspädagogik und Pflege",
    "journalPublisher": "Elora",
    "publicationLanguage": "Thai",
    "institution": "University of Veterinary and Pharmaceutical Science",
    "website": "mapquest.com",
    "state": "Poitou-Charentes",
    "preparedDate": 2009,
    "openPaper": "hc360.com",
    "code": "IXD",
    "github": "McKenzie and Sons"
  }, {
    "id": 30,
    "authorOneFname": "Sherman",
    "authorTwoFname": "Rebecka",
    "authorThreeFname": "Aarika",
    "authorFourFname": "Ryon",
    "authorFiveFname": "Wilek",
    "journalTitle": "Yan Shan University",
    "journalPublisher": "Rozalin",
    "publicationLanguage": "Pashto",
    "institution": "Dong Yang University of Technology",
    "website": "fema.gov",
    "state": null,
    "preparedDate": 2006,
    "openPaper": "last.fm",
    "code": "MTN",
    "github": "Oberbrunner, McGlynn and Cormier"
  }, {
    "id": 31,
    "authorOneFname": "Elladine",
    "authorTwoFname": "Blondelle",
    "authorThreeFname": "Carce",
    "authorFourFname": "Virge",
    "authorFiveFname": "Costa",
    "journalTitle": "Voronezh State Medical Academy",
    "journalPublisher": "Emmalynn",
    "publicationLanguage": "Thai",
    "institution": "FAST - National University of Computer and Emerging Sciences (NUCES)",
    "website": "wikispaces.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "independent.co.uk",
    "code": "TBW",
    "github": "Hoeger LLC"
  }, {
    "id": 32,
    "authorOneFname": "Boy",
    "authorTwoFname": "Curcio",
    "authorThreeFname": "Aaron",
    "authorFourFname": "Freida",
    "authorFiveFname": "Clint",
    "journalTitle": "Irish International University (Distance Education)",
    "journalPublisher": "Margo",
    "publicationLanguage": "Kurdish",
    "institution": "Birjand University",
    "website": "livejournal.com",
    "state": "Oslo",
    "preparedDate": 2005,
    "openPaper": "csmonitor.com",
    "code": "EMM",
    "github": "Pagac, Huels and Swift"
  }, {
    "id": 33,
    "authorOneFname": "Stanleigh",
    "authorTwoFname": "Arty",
    "authorThreeFname": "Hurley",
    "authorFourFname": "Laina",
    "authorFiveFname": "Edin",
    "journalTitle": "Sri Padmavati Women's University",
    "journalPublisher": "Meridel",
    "publicationLanguage": "Swahili",
    "institution": "Centro Universitário de João Pessoa",
    "website": "booking.com",
    "state": null,
    "preparedDate": 2000,
    "openPaper": "yahoo.com",
    "code": "PRK",
    "github": "Skiles and Sons"
  }, {
    "id": 34,
    "authorOneFname": "Daryn",
    "authorTwoFname": "Amabelle",
    "authorThreeFname": "Zola",
    "authorFourFname": "Verney",
    "authorFiveFname": "Garrek",
    "journalTitle": "University of Science, Arts and Technology",
    "journalPublisher": "Deina",
    "publicationLanguage": "Latvian",
    "institution": "University of Sassari",
    "website": "comsenz.com",
    "state": null,
    "preparedDate": 2009,
    "openPaper": "examiner.com",
    "code": "PBB",
    "github": "Rogahn and Sons"
  }, {
    "id": 35,
    "authorOneFname": "Aime",
    "authorTwoFname": "Mallorie",
    "authorThreeFname": "Gilly",
    "authorFourFname": "Madelena",
    "authorFiveFname": "Sher",
    "journalTitle": "Hawler Medical University",
    "journalPublisher": "Sara-ann",
    "publicationLanguage": "Chinese",
    "institution": "Faculdade Integradas do Ceará",
    "website": "com.com",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "odnoklassniki.ru",
    "code": "KRC",
    "github": "Mayert-Schulist"
  }, {
    "id": 36,
    "authorOneFname": "Marylou",
    "authorTwoFname": "Charlotta",
    "authorThreeFname": "Thane",
    "authorFourFname": "Gordy",
    "authorFiveFname": "Rowena",
    "journalTitle": "Cukurova University",
    "journalPublisher": "Janela",
    "publicationLanguage": "Bosnian",
    "institution": "Johnson County Community College",
    "website": "umn.edu",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "twitter.com",
    "code": "HRR",
    "github": "Howe-Keeling"
  }, {
    "id": 37,
    "authorOneFname": "Kalina",
    "authorTwoFname": "Beryl",
    "authorThreeFname": "Sib",
    "authorFourFname": "Brewster",
    "authorFiveFname": "Emeline",
    "journalTitle": "Meisei University",
    "journalPublisher": "Missy",
    "publicationLanguage": "Bengali",
    "institution": "International Business School of Scandinavia",
    "website": "biblegateway.com",
    "state": "Île-de-France",
    "preparedDate": 2011,
    "openPaper": "ed.gov",
    "code": "HLC",
    "github": "Beer, Schowalter and Strosin"
  }, {
    "id": 38,
    "authorOneFname": "Isa",
    "authorTwoFname": "Bobina",
    "authorThreeFname": "Sigmund",
    "authorFourFname": "Dulcinea",
    "authorFiveFname": "Kerwinn",
    "journalTitle": "Tansian University",
    "journalPublisher": "Gina",
    "publicationLanguage": "Spanish",
    "institution": "Daugavpils University",
    "website": "printfriendly.com",
    "state": null,
    "preparedDate": 2020,
    "openPaper": "ft.com",
    "code": "VPG",
    "github": "Klocko, Littel and Walter"
  }, {
    "id": 39,
    "authorOneFname": "Deck",
    "authorTwoFname": "Trstram",
    "authorThreeFname": "Bernette",
    "authorFourFname": "Bastien",
    "authorFiveFname": "Sarge",
    "journalTitle": "Mindanao State University",
    "journalPublisher": "Eva",
    "publicationLanguage": "Tswana",
    "institution": "University of Dublin, Trinity College",
    "website": "mlb.com",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "bluehost.com",
    "code": "LTA",
    "github": "Lind-Feeney"
  }, {
    "id": 40,
    "authorOneFname": "Bernadine",
    "authorTwoFname": "Joyce",
    "authorThreeFname": "Waite",
    "authorFourFname": "Marney",
    "authorFiveFname": "Chrysler",
    "journalTitle": "Damanhour University",
    "journalPublisher": "Gabriela",
    "publicationLanguage": "Punjabi",
    "institution": "Universitas Pesantren Darul Ulum Jombang",
    "website": "icq.com",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "cbslocal.com",
    "code": "FAN",
    "github": "Murazik, Lang and Koss"
  }, {
    "id": 41,
    "authorOneFname": "Noel",
    "authorTwoFname": "Wilton",
    "authorThreeFname": "Edy",
    "authorFourFname": "Lisha",
    "authorFiveFname": "Dyanna",
    "journalTitle": "Chodang University",
    "journalPublisher": "Norine",
    "publicationLanguage": "Croatian",
    "institution": "Shaheed Beheshti University",
    "website": "va.gov",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "studiopress.com",
    "code": "BER",
    "github": "Bauch Inc"
  }, {
    "id": 42,
    "authorOneFname": "Dorry",
    "authorTwoFname": "Donnajean",
    "authorThreeFname": "Nicolina",
    "authorFourFname": "Isa",
    "authorFiveFname": "Vida",
    "journalTitle": "Institut Commercial de Nancy",
    "journalPublisher": "Missie",
    "publicationLanguage": "Tetum",
    "institution": "Seijo University",
    "website": "elpais.com",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "scribd.com",
    "code": "SJR",
    "github": "Cormier, Roob and Windler"
  }, {
    "id": 43,
    "authorOneFname": "Coop",
    "authorTwoFname": "Carlee",
    "authorThreeFname": "Leah",
    "authorFourFname": "Lurette",
    "authorFiveFname": "Scott",
    "journalTitle": "Université du Littoral Cote d'Opale",
    "journalPublisher": "Murielle",
    "publicationLanguage": "Finnish",
    "institution": "The Maryland Institute, College of Art",
    "website": "e-recht24.de",
    "state": "Porto",
    "preparedDate": 1998,
    "openPaper": "businessinsider.com",
    "code": "VIU",
    "github": "Fisher, Bartoletti and Kessler"
  }, {
    "id": 44,
    "authorOneFname": "Winnie",
    "authorTwoFname": "Linn",
    "authorThreeFname": "Fields",
    "authorFourFname": "Marabel",
    "authorFiveFname": "Danette",
    "journalTitle": "University of Iceland",
    "journalPublisher": "Lurline",
    "publicationLanguage": "Korean",
    "institution": "American University in the Emirates",
    "website": "mit.edu",
    "state": "Stockholm",
    "preparedDate": 2016,
    "openPaper": "last.fm",
    "code": "CNE",
    "github": "Reilly-Shields"
  }, {
    "id": 45,
    "authorOneFname": "Teodoro",
    "authorTwoFname": "Elka",
    "authorThreeFname": "Norean",
    "authorFourFname": "Floris",
    "authorFiveFname": "Carey",
    "journalTitle": "Pedagogical University of the Polish Association for Adult Education in Warsaw",
    "journalPublisher": "Caria",
    "publicationLanguage": "Kyrgyz",
    "institution": "Fachhochschule Pforzheim, Hochschule für Gestaltung, Technik und Wirtschaft",
    "website": "webeden.co.uk",
    "state": "Québec",
    "preparedDate": 2008,
    "openPaper": "senate.gov",
    "code": "KAA",
    "github": "Gleason-Wolff"
  }, {
    "id": 46,
    "authorOneFname": "Tally",
    "authorTwoFname": "Alica",
    "authorThreeFname": "Rolland",
    "authorFourFname": "Francklin",
    "authorFiveFname": "Artemas",
    "journalTitle": "Université de Montpellier I",
    "journalPublisher": "Maud",
    "publicationLanguage": "Dari",
    "institution": "University of Baltimore",
    "website": "macromedia.com",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "ustream.tv",
    "code": "FKI",
    "github": "Runolfsdottir, Christiansen and Carter"
  }, {
    "id": 47,
    "authorOneFname": "Bea",
    "authorTwoFname": "Ansley",
    "authorThreeFname": "David",
    "authorFourFname": "Patin",
    "authorFiveFname": "Zed",
    "journalTitle": "Semnan University of Medical Sciences",
    "journalPublisher": "Krista",
    "publicationLanguage": "Hungarian",
    "institution": "Ecole Nationale Supérieure d'Ingénieurs en Mécanique et Energétique de Valenciennes",
    "website": "umich.edu",
    "state": null,
    "preparedDate": 2006,
    "openPaper": "360.cn",
    "code": "UYL",
    "github": "Feeney LLC"
  }, {
    "id": 48,
    "authorOneFname": "Tedie",
    "authorTwoFname": "Massimiliano",
    "authorThreeFname": "Kenn",
    "authorFourFname": "Ag",
    "authorFiveFname": "Bobbee",
    "journalTitle": "Syrian International Private University for Science & Technology",
    "journalPublisher": "Cristen",
    "publicationLanguage": "Kannada",
    "institution": "Instituto Politécnico de Bragança",
    "website": "about.me",
    "state": null,
    "preparedDate": 2003,
    "openPaper": "exblog.jp",
    "code": "SID",
    "github": "Moen, Blanda and Altenwerth"
  }, {
    "id": 49,
    "authorOneFname": "Carmine",
    "authorTwoFname": "Salome",
    "authorThreeFname": "Morgana",
    "authorFourFname": "Brigitta",
    "authorFiveFname": "Gertie",
    "journalTitle": "Hertie School of Governance",
    "journalPublisher": "Viviana",
    "publicationLanguage": "Bulgarian",
    "institution": "Ecole Nationale Supérieure des Arts et Industries Textiles",
    "website": "wikipedia.org",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "miibeian.gov.cn",
    "code": "CAC",
    "github": "Wisozk-Ruecker"
  }, {
    "id": 50,
    "authorOneFname": "Sileas",
    "authorTwoFname": "Israel",
    "authorThreeFname": "Fawnia",
    "authorFourFname": "Patti",
    "authorFiveFname": "Den",
    "journalTitle": "University of Houston, Clear Lake",
    "journalPublisher": "Maiga",
    "publicationLanguage": "Guaraní",
    "institution": "Doshisha Women's College of Liberal Arts",
    "website": "forbes.com",
    "state": null,
    "preparedDate": 2008,
    "openPaper": "vimeo.com",
    "code": "IRJ",
    "github": "Bednar and Sons"
  }, {
    "id": 51,
    "authorOneFname": "Alfreda",
    "authorTwoFname": "Delphinia",
    "authorThreeFname": "Vincent",
    "authorFourFname": "Ripley",
    "authorFiveFname": "Marie-jeanne",
    "journalTitle": "Konan University",
    "journalPublisher": "Sher",
    "publicationLanguage": "Dutch",
    "institution": "Johore Bharu Primeir Polytechnic",
    "website": "imdb.com",
    "state": null,
    "preparedDate": 2008,
    "openPaper": "diigo.com",
    "code": "YGW",
    "github": "Koss Group"
  }, {
    "id": 52,
    "authorOneFname": "Zonda",
    "authorTwoFname": "Courtney",
    "authorThreeFname": "Elspeth",
    "authorFourFname": "Cal",
    "authorFiveFname": "Englebert",
    "journalTitle": "Vilnius Gediminas Technical University",
    "journalPublisher": "Annabela",
    "publicationLanguage": "Bulgarian",
    "institution": "University of Central Europe in Skalica",
    "website": "toplist.cz",
    "state": null,
    "preparedDate": 2017,
    "openPaper": "123-reg.co.uk",
    "code": "SQN",
    "github": "Conn Inc"
  }, {
    "id": 53,
    "authorOneFname": "Suki",
    "authorTwoFname": "Daile",
    "authorThreeFname": "Bel",
    "authorFourFname": "Britteny",
    "authorFiveFname": "Rozele",
    "journalTitle": "International University Institute of Luxembourg",
    "journalPublisher": "Celle",
    "publicationLanguage": "Thai",
    "institution": "Odessa State Medical University",
    "website": "craigslist.org",
    "state": null,
    "preparedDate": 2009,
    "openPaper": "yellowpages.com",
    "code": "BUM",
    "github": "Kuphal-Maggio"
  }, {
    "id": 54,
    "authorOneFname": "Horten",
    "authorTwoFname": "Cindra",
    "authorThreeFname": "Nerty",
    "authorFourFname": "Christean",
    "authorFiveFname": "Irwinn",
    "journalTitle": "Fukuoka Institute of Technology",
    "journalPublisher": "Faith",
    "publicationLanguage": "Malagasy",
    "institution": "University Malaysia Pahang",
    "website": "hp.com",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "bloglines.com",
    "code": "YKC",
    "github": "Hand Inc"
  }, {
    "id": 55,
    "authorOneFname": "Kimberley",
    "authorTwoFname": "Sasha",
    "authorThreeFname": "Xerxes",
    "authorFourFname": "Markos",
    "authorFiveFname": "Gisele",
    "journalTitle": "Pontificia Universidad Javeriana",
    "journalPublisher": "Abby",
    "publicationLanguage": "Bengali",
    "institution": "Aalborg University",
    "website": "arizona.edu",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "hubpages.com",
    "code": "LMZ",
    "github": "Hahn-Johns"
  }, {
    "id": 56,
    "authorOneFname": "Spence",
    "authorTwoFname": "Gloria",
    "authorThreeFname": "Annetta",
    "authorFourFname": "Lisa",
    "authorFiveFname": "Donaugh",
    "journalTitle": "Millikin University",
    "journalPublisher": "Alberta",
    "publicationLanguage": "Catalan",
    "institution": "Technical University in Kosice",
    "website": "newyorker.com",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "deviantart.com",
    "code": "BAF",
    "github": "Dach, Schaefer and Hyatt"
  }, {
    "id": 57,
    "authorOneFname": "Lowe",
    "authorTwoFname": "Vidovic",
    "authorThreeFname": "Shara",
    "authorFourFname": "Maddy",
    "authorFiveFname": "Carine",
    "journalTitle": "University of Gdansk",
    "journalPublisher": "Naoma",
    "publicationLanguage": "Nepali",
    "institution": "Universität Bayreuth",
    "website": "dedecms.com",
    "state": null,
    "preparedDate": 2019,
    "openPaper": "wufoo.com",
    "code": "QIQ",
    "github": "D'Amore LLC"
  }, {
    "id": 58,
    "authorOneFname": "Helaina",
    "authorTwoFname": "Dolorita",
    "authorThreeFname": "Rad",
    "authorFourFname": "Westbrook",
    "authorFiveFname": "Lela",
    "journalTitle": "University of Northern Virginia, Prague Campus",
    "journalPublisher": "Mariejeanne",
    "publicationLanguage": "Thai",
    "institution": "Columbia College",
    "website": "wiley.com",
    "state": null,
    "preparedDate": 0,
    "openPaper": "netlog.com",
    "code": "CNZ",
    "github": "Gibson, Jacobs and Hackett"
  }, {
    "id": 59,
    "authorOneFname": "Viviyan",
    "authorTwoFname": "Bessie",
    "authorThreeFname": "Penn",
    "authorFourFname": "Ashley",
    "authorFiveFname": "Rori",
    "journalTitle": "Takamatsu University",
    "journalPublisher": "Romola",
    "publicationLanguage": "Hebrew",
    "institution": "Université Mouloud Mammeri de Tizi Ouzou",
    "website": "cargocollective.com",
    "state": "Viana do Castelo",
    "preparedDate": 2014,
    "openPaper": "cbc.ca",
    "code": "IWA",
    "github": "Bechtelar Group"
  }, {
    "id": 60,
    "authorOneFname": "Phillipe",
    "authorTwoFname": "Ashla",
    "authorThreeFname": "Koo",
    "authorFourFname": "Pietra",
    "authorFiveFname": "Audie",
    "journalTitle": "Baltimore Hebrew University",
    "journalPublisher": "Ariana",
    "publicationLanguage": "Kazakh",
    "institution": "Pennsylvania Institute of Technology",
    "website": "drupal.org",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "tiny.cc",
    "code": "HIP",
    "github": "Cartwright-Schneider"
  }, {
    "id": 61,
    "authorOneFname": "Dulci",
    "authorTwoFname": "Launce",
    "authorThreeFname": "Amie",
    "authorFourFname": "Carmencita",
    "authorFiveFname": "Gaston",
    "journalTitle": "Indian Veterinary Research Institute",
    "journalPublisher": "Sydney",
    "publicationLanguage": "Dzongkha",
    "institution": "Kentucky State University",
    "website": "github.io",
    "state": null,
    "preparedDate": 2019,
    "openPaper": "baidu.com",
    "code": "PPH",
    "github": "Mohr, Willms and Bayer"
  }, {
    "id": 62,
    "authorOneFname": "Leese",
    "authorTwoFname": "Merv",
    "authorThreeFname": "Anna-maria",
    "authorFourFname": "Arman",
    "authorFiveFname": "Josey",
    "journalTitle": "University of Trnava",
    "journalPublisher": "Terri",
    "publicationLanguage": "Quechua",
    "institution": "University of Prince Edward Island",
    "website": "unesco.org",
    "state": null,
    "preparedDate": 2011,
    "openPaper": "google.co.jp",
    "code": "TUT",
    "github": "Leuschke Group"
  }, {
    "id": 63,
    "authorOneFname": "Risa",
    "authorTwoFname": "Lira",
    "authorThreeFname": "Sheeree",
    "authorFourFname": "Beverlee",
    "authorFiveFname": "Dulcine",
    "journalTitle": "Dalarna University College",
    "journalPublisher": "Damita",
    "publicationLanguage": "Assamese",
    "institution": "IMPAC University",
    "website": "biblegateway.com",
    "state": null,
    "preparedDate": 2010,
    "openPaper": "usda.gov",
    "code": "TGZ",
    "github": "Steuber-Nitzsche"
  }, {
    "id": 64,
    "authorOneFname": "Gert",
    "authorTwoFname": "Griffith",
    "authorThreeFname": "Pauly",
    "authorFourFname": "Della",
    "authorFiveFname": "Marji",
    "journalTitle": "Multimedia University",
    "journalPublisher": "Evanne",
    "publicationLanguage": "German",
    "institution": "Pädagogische Hochschule Schwäbisch Gmünd",
    "website": "rediff.com",
    "state": null,
    "preparedDate": 2001,
    "openPaper": "comcast.net",
    "code": "TMS",
    "github": "Labadie-Langosh"
  }, {
    "id": 65,
    "authorOneFname": "Bertrando",
    "authorTwoFname": "Stevena",
    "authorThreeFname": "Robinett",
    "authorFourFname": "Burke",
    "authorFiveFname": "Shanna",
    "journalTitle": "Gakushuin University",
    "journalPublisher": "Hannie",
    "publicationLanguage": "Kurdish",
    "institution": "Barry University",
    "website": "who.int",
    "state": null,
    "preparedDate": 2007,
    "openPaper": "omniture.com",
    "code": "SBG",
    "github": "Klocko, Monahan and Funk"
  }, {
    "id": 66,
    "authorOneFname": "Selestina",
    "authorTwoFname": "Maryjo",
    "authorThreeFname": "Raul",
    "authorFourFname": "Gianina",
    "authorFiveFname": "Skye",
    "journalTitle": "Katholische Fachhochschule Nordrhein-Westfalen",
    "journalPublisher": "Kerstin",
    "publicationLanguage": "Dutch",
    "institution": "Qassim University",
    "website": "timesonline.co.uk",
    "state": null,
    "preparedDate": 2010,
    "openPaper": "imageshack.us",
    "code": "LWM",
    "github": "Cartwright-Marvin"
  }, {
    "id": 67,
    "authorOneFname": "Winthrop",
    "authorTwoFname": "Lyndsey",
    "authorThreeFname": "Ebenezer",
    "authorFourFname": "Quinton",
    "authorFiveFname": "Rodge",
    "journalTitle": "Allianze College of Medical Sciences (ACMS)",
    "journalPublisher": "Yolanda",
    "publicationLanguage": "Croatian",
    "institution": "Tashkent State Technical University",
    "website": "wiley.com",
    "state": null,
    "preparedDate": 2006,
    "openPaper": "cnn.com",
    "code": "HRS",
    "github": "Lebsack-Jacobi"
  }, {
    "id": 68,
    "authorOneFname": "Lynnelle",
    "authorTwoFname": "Addy",
    "authorThreeFname": "Borg",
    "authorFourFname": "Klarika",
    "authorFiveFname": "Gwendolyn",
    "journalTitle": "Nanyang Technological University",
    "journalPublisher": "Sherilyn",
    "publicationLanguage": "Italian",
    "institution": "State University of New York College at Cortland",
    "website": "umn.edu",
    "state": null,
    "preparedDate": 2016,
    "openPaper": "nydailynews.com",
    "code": "JOI",
    "github": "Donnelly-Maggio"
  }, {
    "id": 69,
    "authorOneFname": "Kendell",
    "authorTwoFname": "Valaria",
    "authorThreeFname": "Antonia",
    "authorFourFname": "Tedman",
    "authorFiveFname": "Margalo",
    "journalTitle": "University of Dublin, Trinity College",
    "journalPublisher": "Carine",
    "publicationLanguage": "Māori",
    "institution": "St. Augustine International University",
    "website": "canalblog.com",
    "state": null,
    "preparedDate": 0,
    "openPaper": "abc.net.au",
    "code": "MCJ",
    "github": "Shields Inc"
  }, {
    "id": 70,
    "authorOneFname": "Nichols",
    "authorTwoFname": "Billye",
    "authorThreeFname": "Dela",
    "authorFourFname": "Jacquenette",
    "authorFiveFname": "Derrik",
    "journalTitle": "Hochschule Esslingen",
    "journalPublisher": "Sarita",
    "publicationLanguage": "West Frisian",
    "institution": "Universidad de la Cuenca del Plata",
    "website": "statcounter.com",
    "state": null,
    "preparedDate": 2006,
    "openPaper": "rakuten.co.jp",
    "code": "TJI",
    "github": "Ledner LLC"
  }, {
    "id": 71,
    "authorOneFname": "Abeu",
    "authorTwoFname": "Junia",
    "authorThreeFname": "Drucill",
    "authorFourFname": "Zelma",
    "authorFiveFname": "Redford",
    "journalTitle": "Wuhan Transportation University",
    "journalPublisher": "Penni",
    "publicationLanguage": "Tetum",
    "institution": "Gulf University for Science and Technology",
    "website": "ezinearticles.com",
    "state": null,
    "preparedDate": 1997,
    "openPaper": "umich.edu",
    "code": "LOP",
    "github": "Simonis and Sons"
  }, {
    "id": 72,
    "authorOneFname": "Fannie",
    "authorTwoFname": "Lisetta",
    "authorThreeFname": "Zeke",
    "authorFourFname": "Vinny",
    "authorFiveFname": "Prudy",
    "journalTitle": "Botho University",
    "journalPublisher": "Megan",
    "publicationLanguage": "New Zealand Sign Language",
    "institution": "Instituto Politécnico de Santarém",
    "website": "geocities.com",
    "state": "Midi-Pyrénées",
    "preparedDate": 2014,
    "openPaper": "canalblog.com",
    "code": "CHJ",
    "github": "Kihn, Deckow and Dietrich"
  }, {
    "id": 73,
    "authorOneFname": "Josephina",
    "authorTwoFname": "Warden",
    "authorThreeFname": "Allis",
    "authorFourFname": "Kenna",
    "authorFiveFname": "Marjie",
    "journalTitle": "Stillman College",
    "journalPublisher": "Sofia",
    "publicationLanguage": "Irish Gaelic",
    "institution": "Charles University Prague",
    "website": "ameblo.jp",
    "state": null,
    "preparedDate": 2009,
    "openPaper": "360.cn",
    "code": "MEX",
    "github": "Grimes, Pollich and Heathcote"
  }, {
    "id": 74,
    "authorOneFname": "Amerigo",
    "authorTwoFname": "Jaynell",
    "authorThreeFname": "Gar",
    "authorFourFname": "Lawton",
    "authorFiveFname": "Binky",
    "journalTitle": "Business and Hotel Management School",
    "journalPublisher": "Dionis",
    "publicationLanguage": "Oriya",
    "institution": "University of Piraeus",
    "website": "tamu.edu",
    "state": null,
    "preparedDate": 2019,
    "openPaper": "cnn.com",
    "code": "SKG",
    "github": "Raynor, Schimmel and Parisian"
  }, {
    "id": 75,
    "authorOneFname": "Bondon",
    "authorTwoFname": "Iggie",
    "authorThreeFname": "Christie",
    "authorFourFname": "Kalila",
    "authorFiveFname": "Elnore",
    "journalTitle": "Pedagogical University of Slupsk",
    "journalPublisher": "Josepha",
    "publicationLanguage": "Dari",
    "institution": "Osaka City University",
    "website": "dyndns.org",
    "state": "Santarém",
    "preparedDate": 2010,
    "openPaper": "sun.com",
    "code": "ORF",
    "github": "Borer, Schimmel and Padberg"
  }, {
    "id": 76,
    "authorOneFname": "Sibylla",
    "authorTwoFname": "Rogerio",
    "authorThreeFname": "Maridel",
    "authorFourFname": "Maximilian",
    "authorFiveFname": "Neysa",
    "journalTitle": "International University College of Technology Twintech (IUCTT)",
    "journalPublisher": "Laurie",
    "publicationLanguage": "Romanian",
    "institution": "Niigata Sangyo University",
    "website": "elegantthemes.com",
    "state": null,
    "preparedDate": 2003,
    "openPaper": "hao123.com",
    "code": "TMS",
    "github": "Turner-Beatty"
  }, {
    "id": 77,
    "authorOneFname": "Karlen",
    "authorTwoFname": "Kristin",
    "authorThreeFname": "Ursula",
    "authorFourFname": "Else",
    "authorFiveFname": "Ramona",
    "journalTitle": "Universitas Putera Batam",
    "journalPublisher": "Janot",
    "publicationLanguage": "Irish Gaelic",
    "institution": "Tomsk State University of Control Systems and Radioelectronics",
    "website": "photobucket.com",
    "state": null,
    "preparedDate": 2020,
    "openPaper": "fema.gov",
    "code": "ACD",
    "github": "Brakus and Sons"
  }, {
    "id": 78,
    "authorOneFname": "Diego",
    "authorTwoFname": "Rea",
    "authorThreeFname": "Klarrisa",
    "authorFourFname": "Ebonee",
    "authorFiveFname": "Drugi",
    "journalTitle": "Universität des Saarlandes",
    "journalPublisher": "Kellia",
    "publicationLanguage": "Tamil",
    "institution": "Vidzeme University College",
    "website": "google.com.au",
    "state": null,
    "preparedDate": 2008,
    "openPaper": "naver.com",
    "code": "ERU",
    "github": "Boehm Inc"
  }, {
    "id": 79,
    "authorOneFname": "Tessi",
    "authorTwoFname": "Charlotte",
    "authorThreeFname": "Nonah",
    "authorFourFname": "Margaux",
    "authorFiveFname": "Ethyl",
    "journalTitle": "Technological University (Hinthada)",
    "journalPublisher": "Essie",
    "publicationLanguage": "Spanish",
    "institution": "Bloomfield College",
    "website": "shop-pro.jp",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "jugem.jp",
    "code": "EMD",
    "github": "Kilback, Kutch and Stamm"
  }, {
    "id": 80,
    "authorOneFname": "Blinny",
    "authorTwoFname": "Karyn",
    "authorThreeFname": "Ingeborg",
    "authorFourFname": "Hans",
    "authorFiveFname": "Randell",
    "journalTitle": "Lindsey Wilson College",
    "journalPublisher": "Anya",
    "publicationLanguage": "Kannada",
    "institution": "3rd Military Medical University",
    "website": "cbslocal.com",
    "state": null,
    "preparedDate": 2005,
    "openPaper": "storify.com",
    "code": "YSY",
    "github": "Sanford, Tillman and Nader"
  }, {
    "id": 81,
    "authorOneFname": "Isidora",
    "authorTwoFname": "Faythe",
    "authorThreeFname": "Eyde",
    "authorFourFname": "Frayda",
    "authorFiveFname": "Aldridge",
    "journalTitle": "Andong National University",
    "journalPublisher": "Nelia",
    "publicationLanguage": "Dutch",
    "institution": "Botswana International University of Science & Technology",
    "website": "360.cn",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "java.com",
    "code": "OMM",
    "github": "Borer, Wyman and Bergstrom"
  }, {
    "id": 82,
    "authorOneFname": "Hugh",
    "authorTwoFname": "Hagen",
    "authorThreeFname": "Gussie",
    "authorFourFname": "Eulalie",
    "authorFiveFname": "Ramonda",
    "journalTitle": "Skadron College",
    "journalPublisher": "Adriana",
    "publicationLanguage": "Kyrgyz",
    "institution": "The McGregor School of Antioch University",
    "website": "reverbnation.com",
    "state": null,
    "preparedDate": 2019,
    "openPaper": "histats.com",
    "code": "XSI",
    "github": "Mann Inc"
  }, {
    "id": 83,
    "authorOneFname": "Carmela",
    "authorTwoFname": "Nathalie",
    "authorThreeFname": "Clemence",
    "authorFourFname": "Filippo",
    "authorFiveFname": "Lorne",
    "journalTitle": "Catholic University of the Sacred Heart",
    "journalPublisher": "Viviana",
    "publicationLanguage": "Kyrgyz",
    "institution": "Jerash Private University",
    "website": "mayoclinic.com",
    "state": null,
    "preparedDate": 2006,
    "openPaper": "webs.com",
    "code": "UCN",
    "github": "Emmerich, Kozey and Wyman"
  }, {
    "id": 84,
    "authorOneFname": "Alyse",
    "authorTwoFname": "Scott",
    "authorThreeFname": "Gretta",
    "authorFourFname": "Tris",
    "authorFiveFname": "Mallory",
    "journalTitle": "Universidad de Vigo",
    "journalPublisher": "Rivalee",
    "publicationLanguage": "Greek",
    "institution": "Sam Houston State University",
    "website": "amazon.de",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "photobucket.com",
    "code": "BMX",
    "github": "Muller Group"
  }, {
    "id": 85,
    "authorOneFname": "Rowney",
    "authorTwoFname": "Bud",
    "authorThreeFname": "Terence",
    "authorFourFname": "Lisa",
    "authorFiveFname": "Torey",
    "journalTitle": "Politeknik Negeri Semarang",
    "journalPublisher": "Zora",
    "publicationLanguage": "Bengali",
    "institution": "University of Macerata",
    "website": "bing.com",
    "state": "Viana do Castelo",
    "preparedDate": 2015,
    "openPaper": "ft.com",
    "code": "MGH",
    "github": "Gorczany-Shanahan"
  }, {
    "id": 86,
    "authorOneFname": "Dionisio",
    "authorTwoFname": "Susan",
    "authorThreeFname": "Joelie",
    "authorFourFname": "Vanni",
    "authorFiveFname": "Nefen",
    "journalTitle": "Government College University Faisalabad",
    "journalPublisher": "Krysta",
    "publicationLanguage": "Norwegian",
    "institution": "Universidad José Cecilio del Valle",
    "website": "columbia.edu",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "wp.com",
    "code": "STL",
    "github": "Batz LLC"
  }, {
    "id": 87,
    "authorOneFname": "Miof mela",
    "authorTwoFname": "Sal",
    "authorThreeFname": "Sybyl",
    "authorFourFname": "Karel",
    "authorFiveFname": "Kaiser",
    "journalTitle": "Universidad Autónoma de Guerrero",
    "journalPublisher": "Lillis",
    "publicationLanguage": "French",
    "institution": "Universidad Metropolitana de Ciencias de la Educación",
    "website": "hexun.com",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "discovery.com",
    "code": "ALV",
    "github": "Goodwin-Cruickshank"
  }, {
    "id": 88,
    "authorOneFname": "Broddie",
    "authorTwoFname": "Alysia",
    "authorThreeFname": "Dolly",
    "authorFourFname": "Giffie",
    "authorFiveFname": "Jacky",
    "journalTitle": "Fachhochschule für das öffentliche Bibliothekswesen Bonn",
    "journalPublisher": "Ashia",
    "publicationLanguage": "Irish Gaelic",
    "institution": "Kiev National Economic University Named after Vadym Hetman",
    "website": "com.com",
    "state": null,
    "preparedDate": 2005,
    "openPaper": "usgs.gov",
    "code": "BXA",
    "github": "Wolf, Wilkinson and Rice"
  }, {
    "id": 89,
    "authorOneFname": "Laetitia",
    "authorTwoFname": "Wendel",
    "authorThreeFname": "Patience",
    "authorFourFname": "Laney",
    "authorFiveFname": "Bryant",
    "journalTitle": "Northwood University",
    "journalPublisher": "Sharron",
    "publicationLanguage": "Romanian",
    "institution": "Maryam Institute of Higher Education",
    "website": "princeton.edu",
    "state": null,
    "preparedDate": 2014,
    "openPaper": "nyu.edu",
    "code": "LIF",
    "github": "Rodriguez-Wiegand"
  }, {
    "id": 90,
    "authorOneFname": "Elissa",
    "authorTwoFname": "Clemmie",
    "authorThreeFname": "Melany",
    "authorFourFname": "Garek",
    "authorFiveFname": "Andres",
    "journalTitle": "Instituto Politécnico de Coimbra",
    "journalPublisher": "Daniele",
    "publicationLanguage": "Montenegrin",
    "institution": "Damghan University of Basic Sciences",
    "website": "feedburner.com",
    "state": null,
    "preparedDate": 2003,
    "openPaper": "ameblo.jp",
    "code": "YSH",
    "github": "Dicki-Quigley"
  }, {
    "id": 91,
    "authorOneFname": "Nat",
    "authorTwoFname": "Helena",
    "authorThreeFname": "Yorke",
    "authorFourFname": "Talya",
    "authorFiveFname": "Rocky",
    "journalTitle": "University of Forestry Sofia",
    "journalPublisher": "Kaycee",
    "publicationLanguage": "Quechua",
    "institution": "Tashkent State University of Oriental Studies ",
    "website": "icio.us",
    "state": null,
    "preparedDate": 2021,
    "openPaper": "quantcast.com",
    "code": "PCO",
    "github": "Bechtelar Group"
  }, {
    "id": 92,
    "authorOneFname": "Marge",
    "authorTwoFname": "Heywood",
    "authorThreeFname": "Zorah",
    "authorFourFname": "Neysa",
    "authorFiveFname": "Danica",
    "journalTitle": "Islamic Azad University, Saveh",
    "journalPublisher": "Esme",
    "publicationLanguage": "Nepali",
    "institution": "Al-Yamamah College",
    "website": "webmd.com",
    "state": null,
    "preparedDate": 2004,
    "openPaper": "live.com",
    "code": "PLV",
    "github": "Murray-O'Kon"
  }, {
    "id": 93,
    "authorOneFname": "Tania",
    "authorTwoFname": "Shurlocke",
    "authorThreeFname": "Dahlia",
    "authorFourFname": "Laetitia",
    "authorFiveFname": "Adolph",
    "journalTitle": "Klaipeda University",
    "journalPublisher": "Bernie",
    "publicationLanguage": "Telugu",
    "institution": "Gangdara Institute Of Science & Technology",
    "website": "mashable.com",
    "state": null,
    "preparedDate": 2015,
    "openPaper": "symantec.com",
    "code": "MIG",
    "github": "Schuster, Doyle and Krajcik"
  }, {
    "id": 94,
    "authorOneFname": "Erinna",
    "authorTwoFname": "Dwain",
    "authorThreeFname": "Lenci",
    "authorFourFname": "Graeme",
    "authorFiveFname": "Demetre",
    "journalTitle": "Hokkaido Tokai University",
    "journalPublisher": "Mae",
    "publicationLanguage": "Marathi",
    "institution": "University of Lancaster",
    "website": "tripod.com",
    "state": null,
    "preparedDate": 2013,
    "openPaper": "sogou.com",
    "code": "RVE",
    "github": "Padberg Group"
  }, {
    "id": 95,
    "authorOneFname": "Wendi",
    "authorTwoFname": "Candace",
    "authorThreeFname": "Artair",
    "authorFourFname": "Jamesy",
    "authorFiveFname": "Jordon",
    "journalTitle": "Shikoku University",
    "journalPublisher": "Melony",
    "publicationLanguage": "Quechua",
    "institution": "Anna University of Technology, Tirunelveli",
    "website": "chicagotribune.com",
    "state": null,
    "preparedDate": 2006,
    "openPaper": "wunderground.com",
    "code": "HVD",
    "github": "Quigley-Hayes"
  }, {
    "id": 96,
    "authorOneFname": "Brodie",
    "authorTwoFname": "Kaleb",
    "authorThreeFname": "Fionnula",
    "authorFourFname": "Devin",
    "authorFiveFname": "Kleon",
    "journalTitle": "Health sciences University of Mongolia",
    "journalPublisher": "Gnni",
    "publicationLanguage": "Pashto",
    "institution": "Vilnius Pedagogical University",
    "website": "fda.gov",
    "state": "Örebro",
    "preparedDate": 2017,
    "openPaper": "unblog.fr",
    "code": "FEB",
    "github": "Goodwin LLC"
  }, {
    "id": 97,
    "authorOneFname": "Nonah",
    "authorTwoFname": "Lilah",
    "authorThreeFname": "Ignacius",
    "authorFourFname": "Tallulah",
    "authorFiveFname": "Honor",
    "journalTitle": "National Institute of Technology, Rourkela",
    "journalPublisher": "Madelin",
    "publicationLanguage": "Swedish",
    "institution": "Sultan Qaboos University",
    "website": "unesco.org",
    "state": "Kalmar",
    "preparedDate": 2016,
    "openPaper": "yale.edu",
    "code": "PKT",
    "github": "DuBuque-Hayes"
  }, {
    "id": 98,
    "authorOneFname": "Bibbye",
    "authorTwoFname": "Taddeo",
    "authorThreeFname": "Adler",
    "authorFourFname": "Maxim",
    "authorFiveFname": "Dino",
    "journalTitle": "Jalalabad State University Kyrgyzstan",
    "journalPublisher": "Koressa",
    "publicationLanguage": "Moldovan",
    "institution": "Universidade de Taubaté",
    "website": "shop-pro.jp",
    "state": null,
    "preparedDate": 2005,
    "openPaper": "google.ru",
    "code": "AAH",
    "github": "DuBuque Inc"
  }, {
    "id": 99,
    "authorOneFname": "Kaylil",
    "authorTwoFname": "Lionello",
    "authorThreeFname": "Brenna",
    "authorFourFname": "Lu",
    "authorFiveFname": "Reagen",
    "journalTitle": "Australian Catholic University",
    "journalPublisher": "Deanne",
    "publicationLanguage": "Bosnian",
    "institution": "State Film, Television and Theatre Higher School Leon Schiller in Lodz",
    "website": "gmpg.org",
    "state": null,
    "preparedDate": 2012,
    "openPaper": "fastcompany.com",
    "code": "YLT",
    "github": "Grady, O'Hara and Botsford"
  }, {
    "id": 100,
    "authorOneFname": "Giulio",
    "authorTwoFname": "Rollin",
    "authorThreeFname": "Matthieu",
    "authorFourFname": "Beatrisa",
    "authorFiveFname": "Felipa",
    "journalTitle": "Kutztown University of Pennsylvania",
    "journalPublisher": "Allina",
    "publicationLanguage": "Tetum",
    "institution": "University of Dubrovnik",
    "website": "omniture.com",
    "state": null,
    "preparedDate": 2004,
    "openPaper": "desdev.cn",
    "code": "JXN",
    "github": "Lueilwitz, Fay and Herman"
  }]

  export default page3Data;